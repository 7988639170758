<template>
    <v-dialog v-model="viewDialog" width="600" @keydown.esc="closeDialog" @click:outside="closeDialog" style="z-index: 999;">
    <div class="tw-text-end tw-bg-[#0D111B] tw-rounded-t-3xl tw-px-5 tw-pt-3">
        <!-- <v-icon @click="closeDialog" color="red">mdi-close</v-icon> -->
    </div>
    <v-card class="rounded-b-xl pa-10" color="#0D111B">
      <!-- <v-card-title class="text-h4 d-flex justify-center">{{
          !alert_success ? "FAILED" : "SUCCESS"
        }}</v-card-title>
        <v-card-text> {{ alert_message }} </v-card-text> -->

      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <!-- <v-img max-height="75" max-width="75" :src="image"></v-img> -->
            <v-icon size="120" color="#D24545"
                >mdi-close-circle</v-icon>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <v-card-text>
          <div class="tw-text-2xl tw-font-black">Oops!<br>
           Something went wrong.<br><br>
          </div>
          <div class="tw-text-lg">
            Bet placement is prohibited. <br><br>If you're seeing this error, <br>Please contact the admin for further assistance.
          </div>
          </v-card-text>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="dialog-btn"
              color="warning"
              rounded
              @click="closeDialog"
            >
              I UNDERSTAND
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
    </v-dialog>
</template>
<script>
export default {
  props: ['onholdalertDialog',"betwhere"],
  data(){
    return {
      registrationLink: "",
    }
  },
  computed: {
      viewDialog() {
      return this.onholdalertDialog;
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog", true);
      if(this.betwhere == 'casino'){
        window.location.href = "/casino"
      }
    },
  },
}
</script>