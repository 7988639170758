const actions = {
  async getPartnerList({ commit }, payload) {
    try {
      const response = await this._vm.$gameApi.get(`/partner/list/${payload.id}`);
      const { data } = response;
      commit("GET_PARTNER_LIST", data);
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async cashoutFromPartner({ commit }, payload) {
    try {
      const response = await this._vm.$gameApi.post("/user/cashout/partner", {
        partner_id: payload.partner_id,
        amount: payload.amount,
        type: payload.type,
        user_id: payload.user_id,
      });
      const { data } = response;
      console.log("cashout", data);

      commit("CASHOUT_FROM_PARTNER", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async depositFromPartner({ commit }, payload) {
    try {
      console.log(" papapayload", payload);

      const response = await this._vm.$gameApi.post("/user/deposit/partner", {
        partner_id: payload.partner_id,
        amount: payload.amount,
        type: payload.type,
        user_id: payload.user_id,
        transaction_type: payload.transaction_type,
      });
      const { data } = response;
      console.log("deposit", data);

      commit("DEPOSIT_FROM_PARTNER", data);
      //   commit("DEPOSIT_MESSAGE", data)
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getPartnerDepositTransactions({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get(`/user/deposit/partner/${payload.id}`, {
          params: {
            page: payload.page,
            limit: payload.limit,
          },
        });
        const { data } = response;
        console.log("asdadadaaaaa", data);
        commit("GET_DEPOSIT_PARTNER_TRANSACTIONS", data.data);
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getPartnerCashoutTransactions({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get(`/user/cashout/partner/${payload.id}`, {
          params: {
            page: payload.page,
            limit: payload.limit,
          },
        });
        const { data } = response;

        commit("GET_CASHOUT_PARTNER_TRANSACTIONS", data.data);
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getPartnerSub({ commit }, payload) {
    try {
      const response = await this._vm.$gameApi.get(`/partner/agents/list/${payload.id}`, {
        params: {
          partner_name: payload.search,
          limit: payload.limit,
          page: payload.page,
        },
      });
      const { data } = response;
      commit("SET_ALL_SUBPARTNER", data);
    } catch (error) {
      commit("SET_ERROR", error);
    }
  },
  async getPartnerData({}, payload) {
    try {
      const response = await this._vm.$gameApi.get(`/partner/${payload}`);
      const { data } = response;
      return data;
    } catch (error) {
      console.log("error", error);
    }
  },
  async getPartnerProfileData({ commit }, payload) {
    try {
      const response = await this._vm.$gameApi.get(`/partner/${payload.id}`);
      const { data } = response;
      commit("SET_PARTNER_PROFILE_DATA", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getPartnerDeposit({ commit }, payload) {
    try {
      if (payload) {
        console.log("payload direct partner deposit", payload);
        const response = await this._vm.$gameApi.get(`/partner/deposit/${payload.id}`, {
          params: {
            search: payload.search,
            limit: payload.limit,
            page: payload.page,
            type: "deposit",
            is_partner: payload.is_partner,
            status: payload.status,
            sortColumn: payload.sortColumn,
            sortOrder: payload.sortOrder,
          },
        });
        const { data } = response;
        console.log("response deposit", response);
        commit("SET_PARTNER_DEPOSIT", data);
      }
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },

  async getAgentTransactions({ commit }, payload) {
    try {
      if (payload) {
        console.log("payload direct partner transactions", payload);
        const response = await this._vm.$gameApi.get(`/agent-all-transactions/${payload.id}`, {
          params: {
            search: payload.search,
            limit: payload.limit,
            page: payload.page,
            filterType: payload.filterType
            // is_partner: payload.is_partner,
          },
        });
        const { data } = response;
        console.log("response deposit", response);
        commit("SET_PARTNER_TRANSACTIONS", data);
      }
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },

  async getPartnerCashout({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get(`/partner/cashout/${payload.id}`, {
          params: {
            search: payload.search,
            limit: payload.limit,
            page: payload.page,
            type: "cashout",
            is_partner: payload.is_partner,
            status: payload.status,
            sortColumn: payload.sortColumn,
            sortOrder: payload.sortOrder,
          },
        });
        const { data } = response;
        commit("SET_PARTNER_CASHOUT", data);
      }
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getPartnerDirectCashout({ commit }, payload) {
    console.log("xxxxx payload", payload);
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get(`/partner/buy-sell/${payload.id}`, {
          params: {
            search: payload.search,
            limit: payload.limit,
            page: payload.page,
            type: "cashout",
            // trade_type: "partner-admin"
            status: payload.status,
            sortColumn: payload.sortColumn,
            sortOrder: payload.sortOrder,
          },
        });
        const { data } = response;
        commit("SET_PARTNER_DIRECT_CASHOUT", data);
      }
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getPartnerDirectDeposit({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get(`/partner/buy-sell/${payload.id}`, {
          params: {
            search: payload.search,
            limit: payload.limit,
            page: payload.page,
            type: "deposit",
            // trade_type: "partner-admin"
            status: payload.status,
            sortColumn: payload.sortColumn,
            sortOrder: payload.sortOrder,
          },
        });
        const { data } = response;
        commit("SET_PARTNER_DIRECT_DEPOSIT", data);
      }
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getPartnerSubBuyCredits({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$authApi.get(`/partner/sub-users/${payload.id}`, {
          params: {
            search: payload.search,
            limit: payload.limit,
            page: payload.page,
            type: "deposit",
            // trade_type: "partner-admin"
            is_main: payload.is_main,
            status: payload.status,
            sortColumn: payload.sortColumn,
            sortOrder: payload.sortOrder,
          },
        });
        const { data } = response;
        commit("GET_PARTNER_SUB_BUY_CREDITS", data);
      }
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getPartnerSubSellCredits({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$authApi.get(`/partner/sub-users/${payload.id}`, {
          params: {
            search: payload.search,
            limit: payload.limit,
            page: payload.page,
            type: "cashout",
            // trade_type: "partner-admin"
            is_main: payload.is_main,
            status: payload.status,
            sortColumn: payload.sortColumn,
            sortOrder: payload.sortOrder,
          },
        });
        const { data } = response;
        commit("GET_PARTNER_SUB_SELL_CREDITS", data);
      }
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getAgentTransactiontDeposit({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get(`/partner/deposit/${payload.id}`, {
          params: {
            search: payload.search,
            limit: payload.limit,
            page: payload.page,
            type: "deposit",
            trade_type: "partner-sub",
          },
        });
        const { data } = response;
        commit("SET_AGENT_TRANSACTION_DEPOSIT", data);
      }
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getAgentTransactiontCashout({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get(`/partner/cashout/${payload.id}`, {
          params: {
            search: payload.search,
            limit: payload.limit,
            page: payload.page,
            type: "cashout",
            trade_type: "partner-sub",
          },
        });
        const { data } = response;
        commit("SET_AGENT_TRANSACTION_CASHOUT", data);
      }
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getPartnerPayout({ commit }, payload) {
    // const response = await this._vm.$gameApi.get(
    //   `/partner/payouts/${payload.id}`,({
    //     partner : payload && payload.partner
    //   }),
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get(`/partner/payouts/${payload.id}`, {
          params: {
            search: payload.search,
            limit: payload.limit,
            page: payload.page,
            cookies: payload.cookies,
            sortColumn: payload.sortColumn,
            sortOrder: payload.sortOrder,
          },
        });
        console.log("payloadddddddddddddddddddd", response);
        const { data } = response;
        commit("SET_PARTNER_PAYOUT_HISTORY", data);
      }
    } catch {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async approveRequest({}, payload) {
    try {
      console.log("darky", payload);
      const res = await this._vm.$gameApi.post(`/partner/transactions/approve/${payload.id}`, payload);
      console.log("resss", res);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async approvePartnerSubRequest({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/partner-sub/transactions/approve/${payload.id}`, payload);
      console.log("resss", res);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async rejectRequest({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/partner/transactions/reject/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async rejectPartnerSubRequest({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/partner-sub/transactions/reject/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async buyCredits({ commit }, payload) {
    try {
      console.log("direct dexx", payload);
      const res = await this._vm.$gameApi.post(`/partner/add/transactions`, payload);
      const { data } = res;
      console.log("xxxx", data);
      commit("SET_BUY_CREDITS", data);
      return true;
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
      return false;
    }
  },
  async sellCredits({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/partner/add/transactions`, payload);
      const { data } = res;
      commit("SET_SELL_CREDITS", data);
      return true;
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
      return false;
    }
  },
  async getDashboardTotalByDate({ commit }, payload) {
    try {
      console.log("by date", payload);
      const res = await this._vm.$gameApi.post(`/partner/dashboard/${payload.id}`, {
        date: payload.date,
      });
      const { data } = res;
      console.log("dataaa james", data);
      commit("GET_DASHBOARD_TOTAL_BY_DATE", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getPartnerPendingPayout({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.post(`partner/pending-payout/checker/${payload.id}`, {
        date: payload.date,
      });
      const { data } = res;
      console.log("dataaa cuadfafasdfsdf", data);
      commit("GET_PARTNER_PENDING_PAYOUT", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getDashboardPartnerGraph({ commit }, payload) {
    try {
      console.log("byy win", payload);
      const response = await this._vm.$gameApi.post(`/partner/dashboard/graph/${payload.id}`, {
        date: payload.date,
      });
      console.log("payload mbbb", response);
      const { data } = response;
      commit("GET_DASHBOARD_TOTAL_GRAPH_BY_DATE", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },

  async postAddUser({ commit }, payload) {
    try {
      console.log("add user payload", payload);
      const res = await this._vm.$gameApi.post("/partner/add-user", payload);
      const { data } = res;
      commit("SET_ADD_USER", data);
      return true;
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
      return false;
    }
  },
  async partnerUsers({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.get(`/partner/users/${payload.id}`, {
        params: {
          page: payload.page,
          limit: payload.limit,
          search: payload.search,
          sortColumn: payload.sortColumn,
          sortOrder: payload.sortOrder,
        },
      });
      const { data } = res;
      commit("GET_PARTNER_USER", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getPartnerSubUsers({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.get(`/partner/root-users/${payload.id}`, {
        params: {
          search: !!payload.search ? payload.search : "",
        },
      });
      const { data } = res;
      // console.log("data partner player",data)
      commit("GET_PARTNER_SUB_USER", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async postTransferTransaction({ commit }, payload) {
    console.log("xxxx payload", payload);
    try {
      console.log("transfer payload", payload);

      const res = await this._vm.$gameApi.post("/partner/direct-transaction", payload);
      const { data } = res;
      console.log("dafadfdafasdfdsfads payload", data);
      commit("SET_TRANSFER_TRANSACTION", data);
      return true;
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
      return false;
    }
  },
  async postAgentTransferTransaction({ commit }, payload) {
    try {
      console.log("transfer payload", payload);

      const res = await this._vm.$gameApi.post("/partner/direct-transaction/agent", payload);
      const { data } = res;
      commit("SET_AGENT_TRANSFER_TRANSACTION", data);
      return true;
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
      return false;
    }
  },
  async getPartnerUserData({ commit }, payload) {
    try {
      const response = await this._vm.$gameApi.get(`/partner-user/${payload}`);
      const { data } = response;
      return data;
    } catch (error) {
      console.log("error", error);
    }
  },
  async getPartnerDepositDownloadTransaction({}, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.post(`/partner/partner-transactions/download/${payload.id}`, payload);
        console.log("resadf",response)
        const { data } = response;

        return data;
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getPartnerAgentTree({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/partner/agent/tree/${payload.id}`, payload);
      const { data } = res;
      commit("GET_AGENT_TREE", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async updateCommissionPercentage({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/partner/update/agent/tree/${payload.id}`, payload);
      const { data } = res;
      commit("UPDATE_COMMISSION_PERCENTAGE", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getPartnerAgentInfo({}, payload) {
    try {
      console.log("payload agent info", payload);
      const res = await this._vm.$gameApi.post(`/partner/agent-info/tree/${payload.id}`, payload, {
        params: {
          type: payload.type,
          limit: payload.limit,
          page: payload.page,
          search: payload.search,
        },
      });

      const { data } = res;
      return data;
      // commit("GET_AGENT_INFO", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getPartnerAgentList({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.get(`/partner/agents/list/${payload.id}`, {
        params: {
          page: payload.page,
          limit: payload.limit,
          partner_name: payload.search,
        },
      });
      const { data } = res;
      commit("GET_PARTNER_AGENT_LIST", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async changeAccountPassword({}, payload) {
    try {
      console.log("XXXx", payload);
      const res = await this._vm.$authApi.post(`/partner/account/password/${payload.id}`, payload);
      return res;
    } catch (error) {
      return error;
    }
  },
  async checkUserInfo({}, payload) {
    try {
      const res = await this._vm.$authApi.post("/check-user-info", payload);
      return res;
    } catch (error) {
      return error;
    }
  },
  async getSubAgentBuyCredits({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get(`/get-sub-agent/direct-transactions/${payload.id}`, {
          params: {
            search: payload.search,
            limit: payload.limit,
            page: payload.page,
            type: "deposit",
            sortColumn: payload.sortColumn,
            sortOrder: payload.sortOrder,
            // trade_type: "partner-admin"
            // status : payload.status
          },
        });
        const { data } = response;
        commit("SET_PARTNER_DIRECT_DEPOSIT", data);
      }
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getSubAgentSellCredits({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get(`/get-sub-agent/direct-transactions/${payload.id}`, {
          params: {
            search: payload.search,
            limit: payload.limit,
            page: payload.page,
            type: "cashout",
            sortColumn: payload.sortColumn,
            sortOrder: payload.sortOrder,
            // trade_type: "partner-admin"
            // status : payload.status
          },
        });
        const { data } = response;
        commit("SET_PARTNER_DIRECT_CASHOUT", data);
      }
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getOgcDashboardData({ commit }, payload) {
    try {
      console.log("PAYLOAD", payload);
      const response = await this._vm.$gameApi.post("commission/ogc", payload);
      const { data } = response;
      return data;
    } catch (error) {
      commit("SET_ERROR", error);
    }
  },
  async getPartnerUserInfo({}, payload) {
    try {
      const data = await this._vm.$authApi.get(`/agent/user/account/info/${payload}`);
      return data.data;
    } catch (error) {
      console.log("error", error);
    }
  },
  async getUserBets({ commit }, payload) {
    try {
      console.log("payload???", payload);
      const res = await this._vm.$gameApi.get(`/partner/users/bets/${payload.id}`, {
        params: {
          page: payload.page,
          limit: payload.limit,
          type: payload.type,
          search: payload.search,
        },
      });
      // const { data } = res;
      return res.data;
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR,", error);
    }
  },
  async getUserBetsSports({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.get(`/partner/users/bets/${payload.id}`, {
        params: {
          page: payload.page,
          limit: payload.limit,
          type: payload.type,
        },
      });
      // const { data } = res;
      return res.data;
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR,", error);
    }
  },
  async updatePartnerActiveStatus({}, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.post(`/partner/update/agent/${payload.id}`, payload);

        const { data } = response;

        return data;
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },

  async checkAgentStatus({}, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.post("/partner/check-agent-status", payload);
        const { data } = response;
        return data;
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async socketAction({ commit }, payload){
    try{
      commit("setSocketMutation",payload)
    } catch(error){
      console.log("error",error)
    }
  },
  async getSubUserPartnerList({}, payload){
    try{
      if (payload) {
        const response = await this._vm.$gameApi.get(`/partner/sub-users-list/${payload.id}`, {
          params: {
            page: payload.page,
            limit: payload.limit,
            search: payload.search
          },
        });
        const { data } = response;
        return data;
      }
    }catch(error){
      console.log("error",error)
    }
  },
  async assignSubUserAction({},payload){
    try {
      const res = await this._vm.$gameApi.post(`/partner/sub-users-actions/${payload.id}`, payload);
      const { data } = res;
      return data;
    } catch (error) {
      return error;
    }
  }
};
export default actions;
