<template>
  <v-dialog overlay-color="#000000" overlay-opacity="0.5" v-model="viewDialog" persistent max-width="1200" max-height="100%" class="tw-z-[10000]" @click:outside="closePartnerDialog"  @keydown.esc="closePartnerDialog">
    <v-card class="alert-card pa-10">
      <v-card-title>PARTNER</v-card-title>
      <v-container class="container">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row class="">
            <v-col>
              <!-- <v-col
                :class="$vuetify.breakpoint.lgAndUp && 'text-select'"
                align="left"
                cols="12"
              >
                <h6>Type of Transaction</h6>
              </v-col>
              <v-col :class="$vuetify.breakpoint.lgAndUp && 'select'" cols="12">
                <v-select
                  light
                  v-on:change="selectedtransactionType"
                  :items="transactionTypeList"
                  :rules="transactionTypeRules"
                  v-model="transactionType"
                  label="Deposit/Withdraw"
                  dense
                  solo
                ></v-select>
              </v-col> -->
              <v-col :class="$vuetify.breakpoint.lgAndUp && 'text-select'" align="left" cols="12">
                <h6>Partner/Agent Name:</h6>
              </v-col>
              <v-col :class="$vuetify.breakpoint.lgAndUp && 'select'" cols="12">
                <v-text-field light dense solo disabled v-model="partner_name"></v-text-field>
              </v-col>
              <v-col :class="$vuetify.breakpoint.lgAndUp && 'text-select'" align="left" cols="12">
                <h6>Amount to “{{ transactionType }}”</h6>
              </v-col>
              <v-col :class="$vuetify.breakpoint.lgAndUp && 'select'" cols="12">
                <v-text-field :rules="amountRules" v-model="amount" type="number" required light label="Minimum of 500" dense solo class="amount"></v-text-field>
              </v-col>
            </v-col>
            <v-col :class="$vuetify.breakpoint.mdAndDown && 'px-10'" :cols="
                $vuetify.breakpoint.mdAndDown
                  ? `${showInfo ? '12' : '12'}`
                  : `${showInfo ? '4' : '3'}`
              " :align="$vuetify.breakpoint.mdAndDown && 'center'">
              <div v-if="showInfo" :class="
                  $vuetify.breakpoint.mdAndDown
                    ? 'mobile-information'
                    : 'information'
                ">
                <v-col>
                  <p class="info-header font-weight-bold">
                    Partner/Agent Information:
                  </p>
                </v-col>
                <v-col class="info-transaction" align="left">
                  <p>Transaction Code:</p>
                </v-col>
                <v-col>
                  <p class="info-code font-weight-bold">
                    {{ transactionCode }}
                  </p>
                </v-col>
                <div>
                  <v-col class="info-message">
                    <p>
                      Message the Partner/Agent about your pending
                      Deposit/Cashout:
                    </p>

                    <p class="red--text">
                      {{ message_info }}
                    </p>
                  </v-col>
                  <v-col class="info-fb">
                    <a :href="facebookLink.facebook_link">{{
                      facebookLink.facebook_link
                    }}</a>
                  </v-col>
                </div>
                <v-col class="info-footer">Bet Responsibly!</v-col>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col :align="$vuetify.breakpoint.mdAndDown && 'right'" :class="$vuetify.breakpoint.mdAndDown && 'px-10'">
              <div>
                <v-btn class="deposit-btn" @click="submitRequest" color="warning" rounded dark>
                  {{ transactionType }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <AlertSuccess :dialog="dialog" :alertMessageBody="alertMessageBody" :alertImage="alertImage" :alertMessageHeader="alertMessageHeader" :status="transactionStatus" @closeDialog="closeDialog" />
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";
import AlertSuccess from "../Alert/Success.vue";
import Success from "@/assets/images/alert/success.svg";
import Failed from "@/assets/images/alert/failed.svg";

export default {
  props: ["partnerDialog", "partner"],
  data() {
    return {
      valid: true,
      amount: "",
      transactionType: "Deposit",
      selectUserType: "",
      userTypeList: [],
      transactionCode: "XX-XXXXXXXXXXXX",
      facebookLink: "",
      message_info: "* Copy and Paste the Transaction Code",
      showInfo: false,
      alertMessageBody: "",
      alertMessageHeader: "",
      dialog: false,
      alertImage: "",
      transactionStatus: "",
      transactionTypeList: ["Deposit", "Cashout"],
      amountRules: [
        (amount) => !!amount || "amount is required",
        (amount) => (amount && amount >= 500) || "Minimum of 500",
      ],
      transactionTypeRules: [
        (transactionType) =>
          !!transactionType || "Transaction type is required",
      ],
      selectUserTypeRules: [
        (selectUserType) => !!selectUserType || "User type is required",
      ],
      viewDialog: false,
      partner_name: "",
    };
  },
  components: {
    AlertSuccess,
  },
  computed: {
    ...mapState("partner", ["partnerlist", "cashoutpartner", "depositpartner"]),
    ...mapState("slotegator", ["show_partner_deposit"]),
  },
  methods: {
    ...mapActions("partner", [
      "getPartnerList",
      "cashoutFromPartner",
      "depositFromPartner",
      "getPartnerUserData",
    ]),
    async submitRequest() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.transactionType == "Cashout") {
          await this.cashoutFromPartner({
            partner_id: this.selectUserType.id,
            amount: this.amount,
            type: this.transactionType.toLowerCase(),
            user_id: this.$auth.user.id,
          });
        } else {
          await this.depositFromPartner({
            partner_id: this.partnerlist.success && this.partnerlist.data.id,
            amount: this.amount,
            type: this.transactionType.toLowerCase(),
            user_id: this.$auth.user.id,
            transaction_type: "manual",
          });
        }

        //get information response
        if (this.cashoutpartner) {
          this.transactionStatus = this.cashoutpartner.success;
          if (this.cashoutpartner.success == true) {
            let partnerList = this.partnerlist && this.partnerlist;
            this.$refs.form.reset();
            this.transactionCode = this.cashoutpartner.data.code;
            this.facebookLink = partnerList;
            this.message_info =
              this.cashoutpartner.data.status == "need_verification"
                ? "* Please verify account on your first cashout"
                : "* Please follow up your pending request";
            this.alertMessageHeader =
              "TRANSACTION REQUEST SUCCESSFUL";
            this.alertImage = Success;
            this.dialog = true;
          } else {
            this.alertImage = Failed;
            this.showInfo = false;
            this.alertMessageHeader =
              "TRANSACTION REQUEST FAILED ";
            this.alertMessageBody = "YOU ALREADY HAVE A PENDING REQUEST";
            this.dialog = true;
          }
        } else {
          this.transactionStatus = this.depositpartner.success;
          if (this.depositpartner.success == true) {
            let partnerList = this.partnerlist;
            this.$refs.form.reset();
            this.transactionCode = this.depositpartner.data.code;
            this.facebookLink = partnerList;
            this.message_info =
              this.depositpartner.data.status == "need_verification"
                ? "* Please verify account on your first deposit"
                : "* Please follow up your pending request";
            this.alertMessageHeader =
              "TRANSACTION REQUEST SUCCESSFUL";
            this.alertImage = Success;
            this.dialog = true;
          } else {
            this.showInfo = false;
            this.alertImage = Failed;
            this.alertMessageHeader =
              "TRANSACTION REQUEST FAILED";
            if (this.depositpartner.success == false) {
              this.alertMessageBody = this.depositpartner.message;
            } else {
              this.alertMessageBody = this.cashoutpartner.message;
            }
            this.dialog = true;
          }
        }
      }
    },
    closeDialog() {
      this.dialog = false;
      if (this.cashoutpartner) {
        if (this.cashoutpartner.success == true) {
          this.showInfo = true;
        } else {
          this.showInfo = false;
        }
      } else {
        if (this.depositpartner.success == true) {
          this.showInfo = true;
        } else {
          this.showInfo = false;
        }
      }
      this.closePartnerDialog();
    },
    closePartnerDialog() {
      this.$emit("closePartnerDialog", true);
    },
  },
  async mounted() {
    if (!!this.$auth.user) {
      await this.getPartnerList(this.$auth.user);
    }
    this.viewDialog = this.partnerDialog;
  },
  watch: {
    async show_partner_deposit() {
      if (!!this.$auth.user) {
        await this.getPartnerList(this.$auth.user);
      }
      this.partner_name =
        this.partnerlist.success && this.partnerlist.data.partner_name;
      console.log("sadasdsa", this.partnerlist);
      this.viewDialog = this.show_partner_deposit;
    },
  },
};
</script>
<style scoped>
.alert-card {
  border-radius: 25px !important;
  background-color: #0d111b !important;
}
.message-body {
  font-style: normal;
  font-weight: 750;
  font-size: 18px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;
  text-decoration-line: underline;
}
.message-header {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
}
.dialog-btn {
  width: 200px;
  height: 36px;
}
.container {
  background-color: transparent !important;
}
.select {
  padding-left: 300px;
}
.text-select {
  font-style: normal;
  font-weight: 750;
  font-size: 18px;
  line-height: 2px;
  padding-left: 250px;
}
.information {
  width: 255.82px;
  height: 355px;
  background: #26466c;
  border-radius: 10px;
  position: relative;
}
.information:before {
  content: "";
  position: absolute;
  top: 270px;
  left: -30px;
  z-index: 1;
  border: solid 15px transparent;
  border-right-color: #26466c;
}
.info-header {
  font-style: normal;
  font-weight: 750;
  font-size: 16px;
  line-height: 19px;
}
.info-transaction {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
}
.info-code {
  font-style: normal;
  font-weight: 750;
  font-size: 15px;
  line-height: 14px;
}
.info-message {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
}
.info-fb {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  text-decoration-line: underline;
}
.info-footer {
  font-style: normal;
  font-weight: 750;
  font-size: 12px;
}
.deposit-btn {
  width: 219px;
  height: 45px;
}
.cashout-select {
  padding-left: 66px;
  padding-right: 66px;
}
.text-cashout {
  font-style: normal;
  font-weight: 750;
  font-size: 18px;
  line-height: 2px;
  padding-left: 70px;
}
.cashout-information {
  width: 90%;
  height: 200px;
  background: #26466c;
  border-radius: 10px;
}
.mobile-cashout-information {
  width: 90%;
  min-height: 200px;
  background: #26466c;
  border-radius: 10px;
}
.disclaimer {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.cashout-summary-details {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding-left: 160px;
}
.credit-success {
  font-style: normal;
  font-weight: 700;
  color: #45d28e;
}
.credit-failed {
  font-style: normal;
  font-weight: 700;
  color: #d24545;
}
.cashout-btn {
  width: 45%;
}
.withdraw-btn {
  width: 219px;
  height: 45px;
}
.withdraw-btn.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background: rgba(210, 137, 69, 0.5) !important;
}
.input-cashout.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background: rgba(210, 137, 69, 0.5) !important;
}
.mobile-information {
  width: 255.82px;
  height: 355px;
  background: #26466c;
  border-radius: 10px;
  position: relative;
}
</style>
<style  scoped>
.amount.v-label {
  color: black !important;
}
</style>