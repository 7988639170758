<template>
  <div>
    <v-app-bar
      color="transparent"
      elevation="0"
      v-if="!$vuetify.breakpoint.mobile"
    >
      <div class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-8">
        <v-icon
          class="mr-5"
          v-if="
            !!this.partner &&
            this.partner.status == 'approve' &&
            !!$route.meta.partner
          "
          @click="drawer = !drawer"
          >mdi-menu</v-icon
        >
        <v-icon
          class="mr-5"
          v-if="
            this.$auth.user &&
            !!this.$auth.user.voucher_code &&
            !!$route.meta.affiliate
          "
          @click="drawer = !drawer"
          >mdi-menu</v-icon
        >
        <!-- <v-icon
          class="mr-5"
          v-if="$route.name && $route.name.includes('Casino')"
          @click.stop="casinodrawer = !casinodrawer"
          >mdi-menu</v-icon
        > -->
        <!-- <v-icon
          class="mr-5"
          v-if="!$route.meta.partner && !$route.meta.affiliate"
          @click="bettordrawer = !bettordrawer"
          >mdi-menu</v-icon
        > -->
        <div>
          <a @click="home"
            ><v-img
              width="140"
              :src="require('@/assets/images/betgame-gg.svg')"
            ></v-img
          ></a>
        </div>
      </div>
      <!-- ADMIN -->
      <a
        v-if="
          !!this.$auth.user
            ? this.$auth.user.type == 'admin' ||
              this.$auth.user.type == 'super-admin'
            : this.$auth.user
        "
        href="/admin"
        style="z-index: 1"
        ><div
          :class="`${
            $route.name == 'AdminDashboard'
              ? 'tw-font-bold tw-text-[#44d0d3]'
              : 'tw-text-white tw-font-light'
          } tw-flex tw-items-center tw-mr-8 tw-text-sm`"
        >
          <v-icon
            class="tw-mr-2"
            :color="$route.name == 'AdminDashboard' && '#44d0d3'"
            >mdi-shield-account</v-icon
          >
          ADMIN DASHBOARD
        </div></a
      >

      <!-- SUB USER DASHBOARD HEADER -->
      <a
        v-if="!!is_subuser && !$route.meta.partner && this.$auth.user"
        href="/partner/sub-user/dashboard"
        style="z-index: 1"
      >
        <div
          :class="`${
            $route.name == 'SubUserDashboard'
              ? 'tw-font-bold tw-text-[#44d0d3]'
              : 'tw-text-white tw-font-light'
          } tw-flex tw-items-center tw-mr-8 tw-text-sm`"
        >
          <v-icon
            class="tw-mr-2"
            :color="$route.name == 'SubUserDashboard' && '#44d0d3'"
            >mdi-shield-account</v-icon
          >PARTNER DASHBOARD
        </div></a
      >

      <!-- END SUB USER DASHBOARD -->

      <!-- PARTNER DASHBOARD -->
      <a
        v-if="
          !!this.partner &&
          !$route.meta.partner &&
          this.partner.status == 'approve' &&
          !!this.partner.active
        "
        href="/partner/dashboard"
        style="z-index: 1"
        ><div
          :class="`${
            $route.name == 'PartnerDashboard'
              ? 'tw-font-bold tw-text-[#44d0d3]'
              : 'tw-text-white tw-font-light'
          } tw-flex tw-items-center tw-mr-8 tw-text-sm`"
        >
          <v-icon
            class="tw-mr-2"
            :color="$route.name == 'PartnerDashboard' && '#44d0d3'"
            >mdi-shield-account</v-icon
          >PARTNER DASHBOARD
        </div></a
      >

      <!-- AFFILIATE DASHBOARD -->

      <a
        v-if="
          this.$auth.user &&
          !!this.$auth.user.voucher_code &&
          !$route.meta.affiliate
        "
        @click="affiliateDashboardPage"
        style="z-index: 1"
        ><div
          :class="`${
            $route.name == 'AffiliateDashboard'
              ? 'tw-font-bold tw-text-[#44d0d3]'
              : 'tw-text-white tw-font-light'
          } tw-flex tw-items-center tw-mr-8 tw-text-sm`"
        >
          <v-icon
            class="tw-mr-2"
            :color="$route.name == 'AffiliateDashboard' && '#44d0d3'"
            >mdi-shield-account</v-icon
          >AFFILIATE DASHBOARD
        </div></a
      >

      <a
        href="/profile"
        v-if="
          !!this.$auth.user && !$route.meta.partner && !$route.meta.affiliate
        "
        style="z-index: 1"
        ><div
          :class="`${
            $route.name == 'profile'
              ? 'tw-font-bold tw-text-[#44d0d3]'
              : 'tw-text-white tw-font-light'
          } tw-flex tw-items-center tw-mr-8 tw-text-sm`"
        >
          <v-icon class="tw-mr-2" :color="$route.name == 'profile' && '#44d0d3'"
            >mdi-account</v-icon
          >PROFILE
        </div></a
      >
      <!-- END ADMIN -->
      <!-- PARTNER -->
      <router-link
        v-if="!!this.partner && $route.meta.partner"
        style="z-index: 1"
        to="/?bt-path=%2F"
        ><div
          :class="`${
            ($route.path == '/' && !$route.query['bt-path']) ||
            $route.query['bt-path'] == '/'
              ? 'tw-font-bold tw-text-[#44d0d3]'
              : 'tw-text-white tw-font-light'
          } tw-flex tw-items-center tw-mr-8`"
        >
          <v-icon
            class="tw-mr-2"
            :color="
              ($route.path == '/' && !$route.query['bt-path']) ||
              $route.query['bt-path'] == '/'
                ? '#44d0d3'
                : 'white'
            "
            >mdi-home</v-icon
          ><span class="tw-inline-block tw-align-middle tw-text-sm">HOME</span>
        </div></router-link
      >
      <router-link
        v-if="!!this.partner && $route.meta.partner"
        style="z-index: 1"
        to="/partner/dashboard"
        ><div
          :class="`${
            $route.name == 'PartnerDashboard'
              ? 'tw-font-bold tw-text-[#44d0d3]'
              : 'tw-text-white tw-font-light'
          } tw-flex tw-items-center tw-mr-8 tw-text-sm`"
        >
          <v-icon
            class="tw-mr-2"
            :color="$route.name == 'PartnerDashboard' && '#44d0d3'"
            >mdi-view-dashboard</v-icon
          ><span class="tw-inline-block tw-align-middle">DASHBOARD</span>
        </div></router-link
      >
      <router-link
        to="/partner/profile"
        v-if="!!this.partner && $route.meta.partner"
        style="z-index: 1"
      >
        <div>
          <div
            :class="` tw-flex tw-items-center tw-mr-8 tw-text-sm ${
              $route.name == 'PartnerProfile'
                ? 'tw-font-bold tw-text-[#44d0d3]'
                : 'tw-font-light tw-text-white'
            }`"
          >
            <v-icon
              class="tw-mr-2"
              :color="$route.name == 'PartnerProfile' ? '#44d0d3' : ''"
              >mdi-account</v-icon
            >PARTNER PROFILE
          </div>
        </div>
      </router-link>
      <router-link
        to="/partner/agent-tree"
        v-if="!!this.partner && $route.meta.partner"
        style="z-index: 1"
      >
        <div v-if="$route.name == 'AgentTree'">
          <div
            class="tw-font-bold tw-flex tw-items-center tw-mr-8 tw-text-sm"
            style="color: #44d0d3"
          >
            <v-icon class="tw-mr-2" color="#44d0d3">mdi-file-tree</v-icon>AGENT
            TREE
          </div>
        </div>
        <div v-else>
          <div
            class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-8 tw-text-sm"
          >
            <v-icon class="tw-mr-2">mdi-file-tree</v-icon>AGENT TREE
          </div>
        </div>
      </router-link>
      <router-link
        v-if="!!this.partner && $route.meta.partner"
        style="z-index: 1"
        to="/partner/active-players/"
      >
        <div v-if="$route.name == 'PartnerActivePlayers'">
          <div
            class="tw-font-bold tw-flex tw-items-center tw-mr-8 tw-text-sm"
            style="color: #44d0d3"
          >
            <v-icon class="tw-mr-2" color="#44d0d3">mdi-account-check</v-icon
            >ACTIVE PLAYERS
          </div>
        </div>
        <div v-else>
          <div
            class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-8 tw-text-sm"
          >
            <v-icon class="tw-mr-2">mdi-account-check</v-icon>ACTIVE PLAYERS
          </div>
        </div>
      </router-link>
      <!-- MANAGE SUB-USER CONDITION IF PARTNER TYPE IS PARTNERS ONLY MP CAN SEE -->
      <div v-if="partner_type == 'partners' && partner_subcount">
        <router-link
          v-if="!!this.partner && $route.meta.partner"
          style="z-index: 1"
          to="/partner/manage-players/"
        >
          <div v-if="$route.name == 'PartnerManagePlayer'">
            <div
              class="tw-font-bold tw-flex tw-items-center tw-mr-8 tw-text-sm"
              style="color: #44d0d3"
            >
              <v-icon class="tw-mr-2" color="#44d0d3">mdi-account-badge</v-icon
              >MANAGE SUB-USER
            </div>
          </div>
          <div v-else>
            <div
              class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-8 tw-text-sm"
            >
              <v-icon class="tw-mr-2">mdi-account-badge</v-icon>MANAGE SUB-USER
            </div>
          </div>
        </router-link>
      </div>
      <!-- END MANAGE SUB-USER CONDITION IF PARTNER TYPE IS PARTNERS ONLY MP CAN SEE -->
      <!-- END PARTNER -->
      <!-- SUB USER -->

      <router-link
        v-if="!!is_subuser && $route.meta.partner"
        style="z-index: 1"
        to="/?bt-path=%2F"
        ><div
          :class="`${
            ($route.path == '/' && !$route.query['bt-path']) ||
            $route.query['bt-path'] == '/'
              ? 'tw-font-bold tw-text-[#44d0d3]'
              : 'tw-text-white tw-font-light'
          } tw-flex tw-items-center tw-mr-8`"
        >
          <v-icon
            class="tw-mr-2"
            :color="
              ($route.path == '/' && !$route.query['bt-path']) ||
              $route.query['bt-path'] == '/'
                ? '#44d0d3'
                : 'white'
            "
            >mdi-home</v-icon
          ><span class="tw-inline-block tw-align-middle tw-text-sm">HOME</span>
        </div></router-link
      >
      <router-link
        v-if="!!is_subuser && $route.meta.partner"
        style="z-index: 1"
        to="/partner/sub-user/dashboard"
        ><div
          :class="`${
            $route.name == 'SubUserDashboard'
              ? 'tw-font-bold tw-text-[#44d0d3]'
              : 'tw-text-white tw-font-light'
          } tw-flex tw-items-center tw-mr-8 tw-text-sm`"
        >
          <v-icon
            class="tw-mr-2"
            :color="$route.name == 'SubUserDashboard' && '#44d0d3'"
            >mdi-view-dashboard</v-icon
          ><span class="tw-inline-block tw-align-middle">DASHBOARD</span>
        </div></router-link
      >
      <router-link
        to="/partner/sub-user/profile"
        v-if="!!is_subuser && $route.meta.partner"
        style="z-index: 1"
      >
        <div>
          <div
            :class="` tw-flex tw-items-center tw-mr-8 tw-text-sm ${
              $route.name == 'SubUserProfile'
                ? 'tw-font-bold tw-text-[#44d0d3]'
                : 'tw-font-light tw-text-white'
            }`"
          >
            <v-icon
              class="tw-mr-2"
              :color="$route.name == 'SubUserProfile' ? '#44d0d3' : ''"
              >mdi-account</v-icon
            >SUB-USER PROFILE
          </div>
        </div>
      </router-link>
      <router-link
        to="/partner/sub-user/active-agents/"
        v-if="!!is_subuser && $route.meta.partner"
        style="z-index: 1"
      >
        <div v-if="$route.name == 'SubUserActiveAgent'">
          <div
            class="tw-font-bold tw-flex tw-items-center tw-mr-8 tw-text-sm"
            style="color: #44d0d3"
          >
            <v-icon class="tw-mr-2" color="#44d0d3">mdi-file-tree</v-icon>AGENT
            TREE
          </div>
        </div>
        <div v-else>
          <div
            class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-8 tw-text-sm"
          >
            <v-icon class="tw-mr-2">mdi-file-tree</v-icon>AGENT TREE
          </div>
        </div>
      </router-link>
      <router-link
        v-if="!!is_subuser && $route.meta.partner"
        style="z-index: 1"
        to="/partner/sub-user/active-players/"
      >
        <div v-if="$route.name == 'SubUserActivePlayers'">
          <div
            class="tw-font-bold tw-flex tw-items-center tw-mr-8 tw-text-sm"
            style="color: #44d0d3"
          >
            <v-icon class="tw-mr-2" color="#44d0d3">mdi-account-check</v-icon
            >ACTIVE PLAYERS
          </div>
        </div>
        <div v-else>
          <div
            class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-8 tw-text-sm"
          >
            <v-icon class="tw-mr-2">mdi-account-check</v-icon>ACTIVE PLAYERS
          </div>
        </div>
      </router-link>
      <!-- END SUB USER -->
      <!-- AFFILIATE -->
      <router-link
        v-if="
          this.$auth.user &&
          !!this.$auth.user.voucher_code &&
          $route.meta.affiliate
        "
        style="z-index: 1"
        to="/?bt-path=%2F"
        ><div
          :class="`${
            ($route.path == '/' && !$route.query['bt-path']) ||
            $route.query['bt-path'] == '/'
              ? 'tw-font-bold tw-text-[#44d0d3]'
              : 'tw-text-white tw-font-light'
          } tw-flex tw-items-center tw-mr-8`"
        >
          <v-icon
            class="tw-mr-2"
            :color="
              ($route.path == '/' && !$route.query['bt-path']) ||
              $route.query['bt-path'] == '/'
                ? '#44d0d3'
                : 'white'
            "
            >mdi-home</v-icon
          ><span class="tw-inline-block tw-align-middle tw-text-sm">HOME</span>
        </div></router-link
      >
      <router-link
        v-if="
          this.$auth.user &&
          !!this.$auth.user.voucher_code &&
          $route.meta.affiliate
        "
        style="z-index: 1"
        to="/affiliate"
        ><div
          :class="`${
            $route.name == 'AffiliateDashboard'
              ? 'tw-font-bold tw-text-[#44d0d3]'
              : 'tw-text-white tw-font-light'
          } tw-flex tw-items-center tw-mr-8 tw-text-sm`"
        >
          <v-icon
            class="tw-mr-2"
            :color="$route.name == 'AffiliateDashboard' && '#44d0d3'"
            >mdi-view-dashboard</v-icon
          ><span class="tw-inline-block tw-align-middle">DASHBOARD</span>
        </div></router-link
      >

      <router-link
        v-if="
          this.$auth.user &&
          !!this.$auth.user.voucher_code &&
          $route.meta.affiliate
        "
        style="z-index: 1"
        to="/affiliate/active-players/"
        ><div
          :class="`${
            $route.name == 'AffiliateActivePlayers'
              ? 'tw-font-bold tw-text-[#44d0d3]'
              : 'tw-text-white tw-font-light'
          } tw-flex tw-items-center tw-mr-8 tw-text-sm`"
        >
          <v-icon
            class="tw-mr-2"
            :color="$route.name == 'AffiliateActivePlayers' && '#44d0d3'"
            >mdi-account</v-icon
          ><span class="tw-inline-block tw-align-middle">ACTIVE PLAYERS</span>
        </div></router-link
      >
      <!-- END AFFILIATE -->
      <!-- USER -->
      <!-- <a
        v-if="
          ($route.name != 'Partner' &&
            !!this.$auth.user &&
            $route.name == 'profile') ||
          $route.name == 'CasinoHome' ||
          $route.name == 'CasinoGames' ||
          $route.name == 'CasinoLive' ||
          $route.name == 'CasinoSlots' ||
          $route.name == 'CasinoPlay' ||
          $route.name == 'CasinoPlayTest' ||
          $route.name == 'CasinoAdmin'
        "
        style="z-index: 1; width: 60px"
        href="/?bt-path=%2F"
        ><div
          class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-8"
        >
          <v-icon class="tw-mr-3"></v-icon>Home
        </div></a
      > -->
      <a
        v-if="!$route.meta.partner && !$route.meta.affiliate"
        style="z-index: 1"
        @click="navTo('%2F')"
        ><div
          :class="`${
            $route.name == 'Home' && sportbook_tab == '%2F'
              ? 'tw-font-bold tw-text-[#44d0d3]'
              : 'tw-text-white tw-font-light'
          } tw-flex tw-items-center tw-mr-8`"
        >
          <v-icon
            class="tw-mr-2"
            :color="
              $route.name == 'Home' && sportbook_tab == '%2F'
                ? '#44d0d3'
                : 'white'
            "
            >mdi-home</v-icon
          ><span class="tw-inline-block tw-align-middle tw-text-sm">HOME</span>
        </div></a
      >
      <router-link
        v-if="!$route.meta.partner && !$route.meta.affiliate"
        style="z-index: 1"
        to="/casino"
        ><div
          :class="`${
            $route.name == 'CasinoHome' ||
            $route.name == 'CasinoGames' ||
            $route.name == 'CasinoLive' ||
            $route.name == 'CasinoSlots' ||
            $route.name == 'CasinoPlay' ||
            $route.name == 'CasinoPlayTest' ||
            $route.name == 'CasinoAdmin'
              ? 'tw-font-bold tw-text-[#44d0d3]'
              : 'tw-text-white tw-font-light'
          } tw-flex tw-items-center tw-mr-8`"
        >
          <v-icon
            class="tw-mr-2"
            :color="
              $route.name == 'CasinoHome' ||
              $route.name == 'CasinoGames' ||
              $route.name == 'CasinoLive' ||
              $route.name == 'CasinoSlots' ||
              $route.name == 'CasinoPlay' ||
              $route.name == 'CasinoPlayTest' ||
              $route.name == 'CasinoAdmin'
                ? '#44d0d3'
                : 'white'
            "
            >mdi-slot-machine</v-icon
          ><span class="tw-inline-block tw-align-middle tw-text-sm"
            >CASINO</span
          >
        </div></router-link
      >
      <a
        v-if="!$route.meta.partner && !$route.meta.affiliate"
        style="z-index: 1"
        @click="navTo('%2Flive')"
        ><div
          :class="`${
            $route.name == 'Home' && sportbook_tab == '%2Flive'
              ? 'tw-font-bold tw-text-[#44d0d3]'
              : 'tw-text-white tw-font-light'
          } tw-flex tw-items-center tw-mr-8`"
        >
          <v-icon
            class="tw-mr-2"
            :color="
              $route.name == 'Home' && sportbook_tab == '%2Flive' && '#44d0d3'
            "
            >mdi-broadcast</v-icon
          ><span class="tw-inline-block tw-align-middle tw-text-sm">LIVE</span>
        </div></a
      >
      <a
        v-if="!$route.meta.partner && !$route.meta.affiliate"
        style="z-index: 1"
        @click="navTo('%2Fe_sport')"
        ><div
          :class="`${
            $route.name == 'Home' && sportbook_tab == '%2Fe_sport'
              ? 'tw-font-bold tw-text-[#44d0d3]'
              : 'tw-text-white tw-font-light'
          } tw-flex tw-items-center tw-mr-8`"
        >
          <v-icon
            class="tw-mr-2"
            :color="
              $route.name == 'Home' &&
              sportbook_tab == '%2Fe_sport' &&
              '#44d0d3'
            "
            >mdi-controller-classic</v-icon
          ><span class="tw-inline-block tw-align-middle tw-text-sm"
            >ESPORTS</span
          >
        </div></a
      >

      <v-menu
        class=""
        bottom
        left
        v-if="!$route.meta.partner && !$route.meta.affiliate"
        style="width: 60px"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            class="px-0 tw-flex tw-items-center tw-mr-3"
            v-bind="attrs"
            v-on="on"
          >
            <!-- <v-img
              :src="
                $route.query['bt-path'] ==
                  '/2/2F1669819088232386560%2F1669819088278523904' ||
                $route.query['bt-path'] == '/2' ||
                $route.query['bt-path'] == '/117' ||
                $route.query['bt-path'] == '/1'
                  ? highlighted_sports_image
                  : sports_image
              "
            ></v-img> -->
            <v-icon
              class="tw-mr-2"
              :color="
                $route.name == 'Home' &&
                (sportbook_tab ==
                  '%2Fbasketball%2Fusa%2Fnba-1669819088278523904' ||
                  sportbook_tab == '%2F117' ||
                  sportbook_tab == '%2F1') &&
                '#44d0d3'
              "
              >mdi-run</v-icon
            >

            <span
              :class="`tw-pr-2 ${
                ($route.name == 'Home' &&
                  sportbook_tab ==
                    '%2Fbasketball%2Fusa%2Fnba-1669819088278523904') ||
                sportbook_tab == '%2F117' ||
                sportbook_tab == '%2F1'
                  ? 'tw-font-bold tw-text-[#44d0d3]'
                  : ' tw-text-white tw-font-light'
              }`"
              >Sports</span
            >

            <v-icon
              left
              :color="
                $route.name == 'Home' &&
                (sportbook_tab ==
                  '%2Fbasketball%2Fusa%2Fnba-1669819088278523904' ||
                  sportbook_tab == '%2F117' ||
                  sportbook_tab == '%2F1') &&
                '#44d0d3'
              "
            >
              mdi-menu-down
            </v-icon>
          </v-btn>
        </template>
        <v-list
          dark
          class="tw-w-auto"
          color="#141c2e"
          background-color="#879fe7"
          style="border: 1px solid rgba(255, 255, 255, 0.5)"
        >
          <v-list-item
            v-for="item in sports"
            @click="navTo(item.link)"
            :key="item.link"
          >
            <div class="tw-flex tw-items-center tw-gap-2">
              <!-- <v-icon class="tw-pr-3" v-text="item.icon"></v-icon> -->
              <v-img :src="item.icon"></v-img>
              <div>{{ item.name }}</div>
            </div>
          </v-list-item>
        </v-list>
      </v-menu>
      <a
        v-if="!$route.meta.partner && !$route.meta.affiliate"
        style="z-index: 1"
        @click="navTo('%2Ffavorites')"
        ><div
          :class="`${
            $route.name == 'Home' && sportbook_tab == '%2Ffavorites'
              ? 'tw-font-bold tw-text-[#44d0d3]'
              : 'tw-text-white tw-font-light'
          } tw-flex tw-items-center tw-mr-8`"
        >
          <v-icon
            class="tw-mr-2"
            :color="
              $route.name == 'Home' &&
              sportbook_tab == '%2Ffavorites' &&
              '#44d0d3'
            "
            >mdi-heart-outline</v-icon
          ><span class="tw-inline-block tw-align-middle tw-text-sm"
            >FAVORITES</span
          >
        </div></a
      >
      <a
        v-if="!$route.meta.partner && !$route.meta.affiliate"
        style="z-index: 1"
        @click="navTo('%2Fbets')"
        ><div
          :class="`${
            $route.name == 'Home' && sportbook_tab == '%2Fbets'
              ? 'tw-font-bold tw-text-[#44d0d3]'
              : 'tw-text-white tw-font-light'
          } tw-flex tw-items-center tw-mr-8`"
        >
          <v-icon
            class="tw-mr-2"
            :color="
              $route.name == 'Home' && sportbook_tab == '%2Fbets' && '#44d0d3'
            "
            >mdi-receipt</v-icon
          ><span class="tw-inline-block tw-align-middle tw-text-sm"
            >SPORTSBOOK BETS</span
          >
        </div></a
      >
      <a
        v-if="!$route.meta.partner && !$route.meta.affiliate"
        style="z-index: 1"
        @click="navTo('%2Fsearch')"
        ><div
          :class="`${
            $route.name == 'Home' && sportbook_tab == '%2Fsearch'
              ? 'tw-font-bold tw-text-[#44d0d3]'
              : 'tw-text-white tw-font-light'
          } tw-flex tw-items-center tw-mr-8`"
        >
          <v-icon
            class="tw-mr-2"
            :color="
              $route.name == 'Home' && sportbook_tab == '%2Fsearch' && '#44d0d3'
            "
            >mdi-magnify</v-icon
          ><span class="tw-inline-block tw-align-middle tw-text-sm"
            >SEARCH</span
          >
        </div></a
      >
      <!-- END USER -->
      <v-spacer></v-spacer>
      <div v-if="!$auth.user" class="tw-flex">
        <v-btn
          @click="showLogin"
          small
          color="#4589D2"
          class="px-6"
          style="z-index: 1"
          >login</v-btn
        >
        <v-btn
          @click="showRegister"
          small
          color="#D28945"
          class="px-6 ml-2"
          style="z-index: 1"
          >sign up</v-btn
        >
      </div>
      <div v-else class="d-flex align-center">
        <!-- PARTNER -->
        <v-row
          v-if="!!this.partner && $route.meta.partner"
          dense
          :class="$route.name != 'home' ? 'px-10 pb-0' : 'px-10 pb-2'"
        >
          <!-- PARTNER HEADER CREDITS -->
          <v-col v-if="this.partner" cols="12">
            <v-row class="amounts">
              <v-icon class="ml-10">mdi-wallet</v-icon>
              <v-col class="pl-3 text-caption text-left">
                <div>PARTNER CREDITS</div>
                <div>
                  ₱
                  {{
                    partnerCredits
                      ? Number(partnerCredits).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : ""
                  }}
                </div>
                <!-- <div>₱ {{ this.partner.partner_credits | formatMoney }}</div> -->
              </v-col>
            </v-row>
          </v-col>
          <!-- END PARTNER HEADER CREDITS -->
        </v-row>

        <!-- SUB USER -->
        <v-row
          v-if="!!is_subuser && $route.meta.partner"
          dense
          :class="$route.name != 'home' ? 'px-10 pb-0' : 'px-10 pb-2'"
        >
          <!-- SUB USER HEADER CREDITS -->
          <v-col v-if="is_subuser" cols="12">
            <v-row class="amounts">
              <v-icon class="ml-10">mdi-wallet</v-icon>
              <v-col class="pl-3 text-caption text-left">
                <div>PARTNER CREDITS</div>
                <div>
                  ₱
                  <!-- {{sub_user}} -->
                  {{
                    subUserPartnerCredits
                      ? Number(subUserPartnerCredits).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                          }
                        )
                      : ""
                  }}
                </div>
                <!-- <div>₱ {{ this.partner.partner_credits | formatMoney }}</div> -->
              </v-col>
            </v-row>
          </v-col>
          <!-- END SUB USER HEADER CREDITS -->
        </v-row>
        <!-- END SUB USER -->

        <v-divider
          v-if="!!is_subuser && $route.meta.partner"
          vertical
          class="divider"
        ></v-divider>

        <v-divider
          v-if="!!this.partner && $route.meta.partner"
          vertical
          class="divider"
        ></v-divider>

        <v-col v-if="!!this.partner && $route.meta.partner" class="pa-0">
          <v-tooltip bottom color="#37a1a8">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="openUserDialog"
                ><div class="">
                  <v-icon class="">mdi-account-plus</v-icon>
                </div></v-btn
              >
            </template>
            <span>Create User</span>
          </v-tooltip>
        </v-col>
        <v-col v-if="!!this.partner && $route.meta.partner" class="pa-0">
          <v-tooltip bottom color="#37a1a8">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="openTransferMoneyDialog"
                ><div class="">
                  <v-icon class="">mdi-swap-horizontal</v-icon>
                </div></v-btn
              >
            </template>
            <span>Transfer Money</span>
          </v-tooltip>
        </v-col>
        <v-col class="pa-0" v-if="!!this.partner && $route.meta.partner">
          <v-tooltip bottom color="#37a1a8">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                @click="openRegistrationLinkDialog"
                v-clipboard="clipboardValue"
                ><v-icon class="">mdi-link-plus</v-icon></v-btn
              >
            </template>
            <span>Registration Link</span>
          </v-tooltip>
        </v-col>
        <v-col class="pa-0" v-if="!!this.partner && $route.meta.partner">
          <div class="pa-0">
            <v-tooltip bottom color="#37a1a8">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  stacked
                  @click="openMessage"
                >
                  <div v-if="getUserMessageData == 0">
                    <v-icon>mdi-message</v-icon>
                  </div>
                  <div v-else>
                    <v-badge :content="getUserMessageData" color="error" :class="hasNegativeCreditNotif ? 'glowing-badge' : ''">
                      <v-icon>mdi-message</v-icon>
                    </v-badge>
                  </div>
                </v-btn>
              </template>
              <span>Messages</span>
            </v-tooltip>
          </div>
        </v-col>
        <div
          v-if="!!this.partner && $route.meta.partner"
          :class="$route.name != 'home' ? 'tw-my-2 pb-0' : 'tw-my-2 pb-2'"
        >
          <v-menu
            offset-y
            left
            elevation="0"
            content-class="elevation-0"
            style="z-index: 9999 !important"
          >
            <template v-slot:activator="{ on, attrs }">
              <a v-bind="attrs" v-on="on" class="d-flex align-center"
                ><v-btn color="#45D0D2BF" rounded class="ma-2 white--text"
                  ><v-icon dark left>mdi-account-circle</v-icon
                  >{{ partner.partner_name
                  }}<v-icon right dark>mdi-chevron-down</v-icon></v-btn
                ></a
              >
            </template>
            <div
              class="tw-mt-2 tw-py-3 tw-rounded-md tw-bg-white tw-border tw-border-gray-300 tw-shadow-md dropdown"
              style="background: #141c2e !important"
            >
              <v-list
                light
                class="py-0 my-0"
                style="background: transparent"
                width="200"
              >
                <v-list-item-group>
                  <v-list-item @click="profile">
                    <v-list-item-content>
                      <div class="tw-text-white px-5 text-left">Profile</div>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <v-list-item @click="message">
                    <v-list-item-content>
                      <div class="tw-text-white pl-3 text-left">Messages</div>
                    </v-list-item-content>
                  </v-list-item> -->
                  <v-list-item @click="logoutUser">
                    <v-list-item-content>
                      <div class="tw-text-white px-5 text-left">Logout</div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-menu>
        </div>
        <!-- END PARTNER -->

        <!-- SUB USER TRANSFER MONEY, ADD PLAYER, LOGOUT -->
        <v-col v-if="!!is_subuser && $route.meta.partner" class="pa-0">
          <v-tooltip bottom color="#37a1a8">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="openUserDialog"
                ><div class="">
                  <v-icon class="">mdi-account-plus</v-icon>
                </div></v-btn
              >
            </template>
            <span>Create User</span>
          </v-tooltip>
        </v-col>
        <v-col v-if="!!is_subuser && $route.meta.partner" class="pa-0">
          <v-tooltip bottom color="#37a1a8">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="openSubUserTransferMoneyDialog"
                ><div class="">
                  <v-icon class="">mdi-swap-horizontal</v-icon>
                </div></v-btn
              >
            </template>
            <span>Transfer Money</span>
          </v-tooltip>
        </v-col>
        <v-col class="pa-0" v-if="!!is_subuser && $route.meta.partner">
          <v-tooltip bottom color="#37a1a8">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                @click="openRegistrationLinkDialog"
                v-clipboard="clipboardValue"
                ><v-icon class="">mdi-link-plus</v-icon></v-btn
              >
            </template>
            <span>Registration Link</span>
          </v-tooltip>
        </v-col>
        <!-- <v-col class="pa-0" v-if="!!is_subuser && $route.meta.partner">
          <div class="pa-0">
            <v-tooltip bottom color="#37a1a8">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  stacked
                  @click="openMessage"
                >
                  <div v-if="getUserMessageData == 0">
                    <v-icon>mdi-message</v-icon>
                  </div>
                  <div v-else>
                    <v-badge :content="getUserMessageData" color="error">
                      <v-icon>mdi-message</v-icon>
                    </v-badge>
                  </div>
                </v-btn>
              </template>
              <span>Messages</span>
            </v-tooltip>
          </div>
        </v-col> -->
        <div
          v-if="!!is_subuser && $route.meta.partner"
          :class="$route.name != 'home' ? 'tw-my-2 pb-0' : 'tw-my-2 pb-2'"
        >
          <v-menu
            offset-y
            left
            elevation="0"
            content-class="elevation-0"
            style="z-index: 9999 !important"
          >
            <template v-slot:activator="{ on, attrs }">
              <a v-bind="attrs" v-on="on" class="d-flex align-center"
                ><v-btn color="#45D0D2BF" rounded class="ma-2 white--text"
                  ><v-icon dark left>mdi-account-circle</v-icon
                  >{{ user_info.name
                  }}<v-icon right dark>mdi-chevron-down</v-icon></v-btn
                ></a
              >
            </template>
            <div
              class="tw-mt-2 tw-py-3 tw-rounded-md tw-bg-white tw-border tw-border-gray-300 tw-shadow-md dropdown"
              style="background: #141c2e !important"
            >
              <v-list
                light
                class="py-0 my-0"
                style="background: transparent"
                width="200"
              >
                <v-list-item-group>
                  <v-list-item @click="profile">
                    <v-list-item-content>
                      <div class="tw-text-white px-5 text-left">Profile</div>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <v-list-item @click="message">
                    <v-list-item-content>
                      <div class="tw-text-white pl-3 text-left">Messages</div>
                    </v-list-item-content>
                  </v-list-item> -->
                  <v-list-item @click="logoutUser">
                    <v-list-item-content>
                      <div class="tw-text-white px-5 text-left">Logout</div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-menu>
        </div>
        <!-- END SUB USER TRANSFER MONEY, ADD PLAYER, LOGOUT -->
        <!-- AFFILIATE -->
        <v-row
          v-if="
            this.$auth.user &&
            !!this.$auth.user.voucher_code &&
            $route.meta.affiliate
          "
          dense
          :class="$route.name != 'home' ? 'px-10 pb-0' : 'px-10 pb-2'"
        >
          <!-- PARTNER HEADER CREDITS -->
          <v-col v-if="!this.partner && this.$auth.user.voucher_code" cols="12">
            <v-row class="amounts">
              <v-icon class="ml-10">mdi-wallet</v-icon>
              <v-col class="pl-3 text-caption text-left">
                <div>Affiliate Credits</div>
                <div>
                  ₱
                  {{
                    this.$auth
                      ? Number(this.$auth.user.credits).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                          }
                        )
                      : ""
                  }}
                </div>
                <!-- <div>₱ {{ this.partner.partner_credits | formatMoney }}</div> -->
              </v-col>
            </v-row>
          </v-col>
          <!-- END PARTNER HEADER CREDITS -->
        </v-row>
        <v-divider
          v-if="
            this.$auth.user &&
            !!this.$auth.user.voucher_code &&
            $route.meta.affiliate
          "
          vertical
          class="divider"
        ></v-divider>
        <v-col
          class="pa-0 pl-4"
          v-if="
            this.$auth.user &&
            !!this.$auth.user.voucher_code &&
            $route.meta.affiliate
          "
        >
          <div class="pa-0">
            <v-tooltip bottom color="#37a1a8">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  stacked
                  @click="openMessage"
                >
                  <div v-if="getUserMessageData == 0">
                    <v-icon>mdi-message</v-icon>
                  </div>
                  <div v-else>
                    <v-badge :content="getUserMessageData" color="error" :class="hasNegativeCreditNotif ? 'glowing-badge' : ''">
                      <v-icon>mdi-message</v-icon>
                    </v-badge>
                  </div>
                </v-btn>
              </template>
              <span>Messages</span>
            </v-tooltip>
          </div>
        </v-col>
        <div
          v-if="
            this.$auth.user &&
            !!this.$auth.user.voucher_code &&
            $route.meta.affiliate
          "
          :class="$route.name != 'home' ? 'tw-my-2 pb-0' : 'tw-my-2 pb-2'"
        >
          <v-menu
            offset-y
            left
            elevation="0"
            content-class="elevation-0"
            style="z-index: 9999 !important"
          >
            <template v-slot:activator="{ on, attrs }">
              <a v-bind="attrs" v-on="on" class="d-flex align-center"
                ><v-btn color="#45D0D2BF" rounded class="ma-2 white--text"
                  ><v-icon dark left>mdi-account-circle</v-icon
                  ><span>{{ $auth ? $auth.user.voucher_code : "" }}</span
                  ><v-icon right dark>mdi-chevron-down</v-icon></v-btn
                ></a
              >
            </template>
            <div
              class="tw-mt-2 tw-py-3 tw-rounded-md tw-bg-white tw-border tw-border-gray-300 tw-shadow-md dropdown"
              style="background: #141c2e !important"
            >
              <v-list
                light
                class="py-0 my-0"
                style="background: transparent"
                width="200"
              >
                <v-list-item-group>
                  <v-list-item @click="profile">
                    <v-list-item-content>
                      <div class="tw-text-white px-5 text-left">Profile</div>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <v-list-item @click="message">
                    <v-list-item-content>
                      <div class="tw-text-white pl-3 text-left">Messages</div>
                    </v-list-item-content>
                  </v-list-item> -->
                  <v-list-item @click="logoutUser">
                    <v-list-item-content>
                      <div class="tw-text-white px-5 text-left">Logout</div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-menu>
        </div>
        <!-- END AFFILIATE -->
        <!-- ADMIN -->

        <!-- END ADMIN -->
        <!-- USER -->
        <!-- END USER -->
        <v-row class="mr-1">
          <v-col
            class="pr-0 text-right"
            v-if="
              !!this.$auth.user
                ? this.$auth.user.type == 'super-admin'
                : this.$auth.user
            "
          >
            <!-- <div
              class="pa-0"
              v-if="!!this.$auth.user ? this.$auth.user.type == 'super-admin' : this.$auth.user">
              <v-btn icon @click="openMessage" v-clipboard="clipboardValue"
                ><v-icon class="">mdi-message</v-icon></v-btn>
            </div> -->
            <div class="pa-0">
              <v-btn icon stacked @click="openMessage">
                <div v-if="this.getUserMessageData == 0">
                  <v-icon>mdi-message</v-icon>
                </div>
                <div v-else>
                  <!-- admin -->
                  <v-badge :content="this.getUserMessageData" color="error" :class="hasNegativeCreditNotif ? 'glowing-badge' : ''">
                    <v-icon>mdi-message</v-icon>
                  </v-badge>
                </div>
              </v-btn>
            </div>
          </v-col>
          <v-col class="pr-1">
            <div
              v-if="
                user_info.credits &&
                !$route.meta.partner &&
                !$route.meta.affiliate
              "
              class="font-weight-bold"
              style="font-size: 11px !important"
            >
              WALLET:
              <v-chip
                label
                small
                outlined
                color="rgb(15 239 131)"
                text-color="white"
                v-if="$route.name == 'CasinoPlay'"
              >
                <h6>Playing..</h6>
              </v-chip>
              <v-chip
                label
                small
                outlined
                color="rgb(15 239 131)"
                text-color="white"
                v-if="$route.name != 'CasinoPlay'"
                >₱
                {{
                  userCredits
                    ? Number(userCredits).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : ""
                }}
              </v-chip>
            </div>
          </v-col>
        </v-row>
        <div
          v-if="!$route.meta.partner && !$route.meta.affiliate"
          class="tw-my-2"
        >
          <v-menu
            offset-y
            left
            elevation="0"
            content-class="elevation-0"
            style="z-index: 9999 !important"
          >
            <template v-slot:activator="{ on, attrs }">
              <a v-bind="attrs" v-on="on" class="d-flex align-center"
                ><v-avatar
                  ><v-img
                    :src="
                      $auth.user.avatar == null
                        ? require('../assets/profile.svg')
                        : $auth.user.avatar
                    "
                    class="d-flex align-end rounded-circle"
                  ></v-img></v-avatar
              ></a>
            </template>
            <div
              class="tw-mt-2 tw-py-3 tw-rounded-md tw-bg-white tw-border tw-border-gray-300 tw-shadow-md dropdown"
              style="background: #141c2e !important"
            >
              <v-list
                light
                class="py-0 my-0"
                style="background: transparent"
                width="200"
              >
                <v-list-item-group>
                  <v-list-item @click="profile">
                    <v-list-item-content>
                      <div
                        class="tw-text-white px-5 text-body-1 font-weight-bold text-uppercase"
                      >
                        {{ user_info.name ? user_info.name : "" }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider dark></v-divider>
                  <v-list-item href="/profile">
                    <v-list-item-content>
                      <div class="tw-text-white pl-3 text-left">Profile</div>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <v-list-item @click="message">
                    <v-list-item-content>
                      <div class="tw-text-white pl-3 text-left">Messages</div>
                    </v-list-item-content>
                  </v-list-item> -->
                  <v-list-item @click="logoutUser">
                    <v-list-item-content>
                      <div class="tw-text-white pl-3 text-left">Logout</div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-menu>
        </div>
      </div>
    </v-app-bar>

    <div
      v-else
      class="tw-border-gray-300 tw-flex tw-justify-between tw-items-center tw-p-3"
    >
      <div class="d-flex align-center">
        <!-- <v-icon class="mr-1" @click="drawer = !drawer" v-if="!!this.$auth.user"
          >mdi-menu</v-icon
        > -->
        <v-icon
          class="mr-5"
          v-if="
            !!this.partner &&
            this.partner.status == 'approve' &&
            !!$route.meta.partner
          "
          @click="drawer = !drawer"
          >mdi-menu</v-icon
        >
        <v-icon
          class="mr-5"
          v-if="
            this.$auth.user &&
            !!this.$auth.user.voucher_code &&
            $route.meta.affiliate
          "
          @click="drawer = !drawer"
          >mdi-menu</v-icon
        >
        <!-- Mobile version Sub-user -->
        <v-icon
          class="mr-5"
          v-if="this.$auth.user && !!is_subuser"
          @click="subuserdrawer = !subuserdrawer"
          >mdi-menu</v-icon
        >
        <!-- <v-icon
          class="mr-5"
          v-if="
            this.$auth.user &&
            !this.partner &&
            !this.$auth.user.voucher_code &&
            !is_subuser
          "
          @click="bettordrawer = !bettordrawer"
          >mdi-menu</v-icon
        > -->
        <v-icon
          class="mr-5"
          v-if="
            !$route.meta.partner &&
            !$route.meta.affiliate &&
            !is_subuser &&
            $route.name &&
            !$route.name.includes('Casino')
          "
          @click="bettordrawer = !bettordrawer"
          >mdi-menu</v-icon
        >
        <v-icon
          class="mr-5"
          v-if="$route.name && $route.name.includes('Casino')"
          @click.stop="casinodrawer = !casinodrawer"
          >mdi-menu</v-icon
        >
        <div>
          <a @click="home"
            ><v-img
              width="140"
              :src="require('@/assets/images/betgame-gg.svg')"
            ></v-img
          ></a>
        </div>
      </div>

      <div v-if="!$auth.user" class="tw-flex">
        <v-btn @click="showLogin" x-small color="#4589D2">login</v-btn>
        <v-btn @click="showRegister" x-small color="#D28945" class="ml-2"
          >sign up</v-btn
        >
      </div>
      <div v-else class="tw-pb-3">
        <!-- Mobile Version Partner & Agent -->
        <div
          class="d-flex align-center"
          v-if="!!this.partner && $route.meta.partner"
        >
          <div>
            <v-row>
              <v-col cols="12">
                <v-row class="amounts">
                  <v-icon class="ml-5">mdi-wallet</v-icon>
                  <v-col class="pl-2 text-caption text-left">
                    <div>PARTNER CREDITS</div>
                    <div>
                      ₱ {{ this.partner.partner_credits | formatMoney }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <div>
            <div class="pl-2">
              <v-menu
                offset-y
                left
                elevation="0"
                content-class="elevation-0"
                style="z-index: 9999 !important"
              >
                <template v-slot:activator="{ on, attrs }">
                  <a v-bind="attrs" v-on="on" class="d-flex align-center"
                    ><v-btn icon
                      ><v-avatar color="white" size="25"
                        ><v-icon color="black">mdi-account</v-icon></v-avatar
                      ></v-btn
                    ></a
                  >
                </template>
                <div
                  class="tw-mt-1 tw-py-1 tw-rounded-md tw-bg-white tw-border tw-border-gray-300 tw-shadow-md dropdown"
                  style="background: #141c2e !important"
                >
                  <v-list
                    light
                    class="py-0 my-0"
                    style="background: transparent"
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <div class="tw-text-white px-5">
                          {{ user_info.name }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider style="background: white !important"></v-divider>
                    <v-list-item-group>
                      <v-list-item @click="profile">
                        <v-list-item-content>
                          <div class="tw-text-white px-5">Profile</div>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="logoutUser">
                        <v-list-item-content>
                          <div class="tw-text-white px-5">Logout</div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </div>
              </v-menu>
            </div>
          </div>
        </div>
        <!-- Mobile Version Sub User -->
        <div v-else-if="is_subuser" class="d-flex align-center">
          <div>
            <v-row>
              <v-col cols="12">
                <v-row class="amounts">
                  <v-icon class="ml-5">mdi-wallet</v-icon>
                  <v-col class="pl-2 text-caption text-left">
                    <div>PARTNER CREDITS</div>
                    <div>
                      ₱
                      <!-- {{sub_user}} -->
                      {{
                        subUserPartnerCredits
                          ? Number(subUserPartnerCredits).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                              }
                            )
                          : ""
                      }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <div>
            <div class="pl-2">
              <v-menu
                offset-y
                left
                elevation="0"
                content-class="elevation-0"
                style="z-index: 9999 !important"
              >
                <template v-slot:activator="{ on, attrs }">
                  <a v-bind="attrs" v-on="on" class="d-flex align-center"
                    ><v-btn icon
                      ><v-avatar color="white" size="25"
                        ><v-icon color="black">mdi-account</v-icon></v-avatar
                      ></v-btn
                    ></a
                  >
                </template>
                <div
                  class="tw-mt-1 tw-py-1 tw-rounded-md tw-bg-white tw-border tw-border-gray-300 tw-shadow-md dropdown"
                  style="background: #141c2e !important"
                >
                  <v-list
                    light
                    class="py-0 my-0"
                    style="background: transparent"
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <div class="tw-text-white px-5">
                          {{ user_info.name }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider style="background: white !important"></v-divider>
                    <v-list-item-group>
                      <v-list-item @click="profile">
                        <v-list-item-content>
                          <div class="tw-text-white px-5">Profile</div>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="logoutUser">
                        <v-list-item-content>
                          <div class="tw-text-white px-5">Logout</div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </div>
              </v-menu>
            </div>
          </div>
        </div>
        <div v-else class="d-flex align-center">
          <div
            class="font-weight-bold tw-invisible"
            style="font-size: 8px !important"
          >
            WALLET:
            <v-chip
              label
              small
              outlined
              color="rgb(15 239 131)"
              text-color="white"
              v-if="$route.name == 'CasinoPlay'"
            >
              <h6>Playing..</h6>
            </v-chip>
            <v-chip
              label
              small
              outlined
              color="rgb(15 239 131)"
              text-color="white"
              v-if="$route.name != 'CasinoPlay'"
              >₱
              {{
                userCredits
                  ? Number(userCredits).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  : ""
              }}
            </v-chip>
          </div>
          <div class="tw-my-2">
            <v-menu
              offset-y
              left
              elevation="0"
              content-class="elevation-0"
              style="z-index: 9999 !important"
            >
              <template v-slot:activator="{ on, attrs }">
                <a v-bind="attrs" v-on="on" class="d-flex align-center"
                  ><v-btn icon
                    ><v-avatar color="white" size="25"
                      ><v-icon color="black">mdi-account</v-icon></v-avatar
                    ></v-btn
                  ></a
                >
              </template>
              <div
                class="tw-mt-1 tw-py-1 tw-rounded-md tw-bg-white tw-border tw-border-gray-300 tw-shadow-md dropdown"
                style="background: #141c2e !important"
              >
                <v-list light class="py-0 my-0" style="background: transparent">
                  <v-list-item>
                    <v-list-item-content>
                      <div class="tw-text-white px-5">{{ user_info.name }}</div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider style="background: white !important"></v-divider>
                  <v-list-item align="left">
                    <v-list-item-content>
                      <div class="tw-flex tw-items-center tw-px-5">
                        <v-icon color="white">mdi-wallet</v-icon>
                        <div
                          class="tw-text-white px-5 tw-text-sm tw-text-[#0fef83]"
                        >
                          {{
                            userCredits
                              ? Number(userCredits).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                })
                              : ""
                          }}
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider style="background: white !important"></v-divider>
                  <v-list-item-group align="left">
                    <v-list-item @click="profile">
                      <v-list-item-content>
                        <div class="tw-flex tw-items-center tw-px-5">
                          <v-icon color="white">mdi-account</v-icon>
                          <div class="tw-text-white px-5">Profile</div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="logoutUser">
                      <v-list-item-content>
                        <div class="tw-flex tw-items-center tw-px-5">
                          <v-icon color="white">mdi-logout</v-icon>
                          <div class="tw-text-white px-5">Logout</div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-menu>
          </div>
        </div>
        <!-- <v-navigation-drawer v-model="drawer" fixed temporary dark>
          <v-list>
            <v-list-item-group v-model="selectedItem">
              <v-list-item>
                <v-list-item-content
                  v-if="
                    !!this.$auth.user
                      ? this.$auth.user.type == 'admin'
                      : this.$auth.user
                  "
                  @click="adminPage"
                >
                  <v-list-item-title
                    @click="adminPage"
                    class="tw-flex tw-items-center"
                    ><v-icon class="mr-2">mdi-shield-account</v-icon>Admin
                    Dashboard</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-for="item in items" :key="item.text">
                <v-list-item-content>
                  <v-list-item-title class="tw-flex tw-items-center"
                    ><v-icon class="mr-2">{{ item.icon }}</v-icon
                    >{{ item.text }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer> -->
      </div>
    </div>
    <div style="border: 1px solid gray"></div>

    <!-- ADMIN NAV DRAWER -->
    <v-navigation-drawer
      color="#0D111B"
      overlay-opacity="0.8"
      overlay-color="#000000BF"
      v-model="bettordrawer"
      fixed
      temporary
      dark
      width="275"
      style="z-index: 9999"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <div
              class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-8"
            >
              <v-icon class="mr-5" @click="bettordrawer = !bettordrawer"
                >mdi-menu</v-icon
              >
              <div>
                <a @click="home"
                  ><v-img
                    width="140"
                    :src="require('@/assets/images/betgame-gg.svg')"
                  ></v-img
                ></a>
              </div>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list>
        <v-list-item-group v-model="selectedItem">
          <div class="text-center">
            <v-divider></v-divider>
            <v-list-item
              v-if="
                !!this.$auth.user
                  ? this.$auth.user.type == 'admin' ||
                    this.$auth.user.type == 'super-admin'
                  : this.$auth.user
              "
            >
              <v-list-item-content>
                <v-list-item-title
                  class="tw-flex tw-items-center tw-text-sm"
                  @click="adminPage"
                  ><v-icon class="mr-2">mdi-shield-account</v-icon>ADMIN
                  DASHBOARD</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!!this.partner && !$route.meta.partner">
              <v-list-item-content>
                <v-list-item-title
                  class="tw-flex tw-items-center tw-text-sm"
                  @click="partnerDashboardPage"
                  ><v-icon class="mr-2">mdi-shield-account</v-icon>PARTNER
                  DASHBOARD</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="
                this.$auth.user &&
                !!this.$auth.user.voucher_code &&
                !$route.meta.partner
              "
            >
              <v-list-item-content>
                <v-list-item-title
                  class="tw-flex tw-items-center tw-text-sm"
                  @click="affiliateDashboardPage"
                  ><v-icon class="mr-2">mdi-shield-account</v-icon>AFFILIATE
                  DASHBOARD</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!!this.$auth.user && !$route.meta.partner">
              <v-list-item-content>
                <v-list-item-title
                  class="tw-flex tw-items-center tw-text-sm"
                  @click="profile"
                  ><v-icon class="mr-2">mdi-account</v-icon
                  >PROFILE</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <div v-if="!$route.meta.affiliate && !$route.meta.partner">
              <v-list-item
                v-for="(item, i) in user_items.filter(
                  (item) => item.text !== 'SPORTS'
                )"
                :key="i"
                @click="navToMobile(item.href)"
              >
                <v-list-item-content>
                  <v-list-item-title class="tw-flex tw-items-center"
                    ><v-icon class="mr-4">{{ item.icon }}</v-icon
                    ><span class="tw-text-sm">{{
                      item.text
                    }}</span></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-group
                v-if="hasSubheader"
                :value="nestedListGroupOpen"
                class=""
              >
                <template v-slot:activator>
                  <v-list-item :class="$route.name == 'Home' ? '' : 'px-0'">
                    <v-list-item-content>
                      <v-list-item-title class="tw-flex tw-items-center"
                        ><v-icon class="mr-4">mdi-run</v-icon
                        ><span class="tw-text-sm"
                          >SPORTS</span
                        ></v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </template>

                <!-- Nested list items -->
                <v-list-item
                  v-for="(subItem, subIndex) in user_items.find(
                    (item) => item.text === 'SPORTS'
                  ).subheader"
                  :key="subIndex"
                  @click="navToMobile(subItem.link)"
                >
                  <v-list-item-content>
                    <v-list-item-title class="tw-flex tw-items-center tw-pl-5">
                      <div class="tw-flex tw-items-center tw-gap-3">
                        <v-img :src="subItem.icon"></v-img>

                        <span class="tw-text-sm">{{ subItem.name }}</span>
                      </div>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </div>
            <!-- <v-divider></v-divider>
             <div class="mt-5">
              <span class="pr-4"><v-icon>mdi-twitter</v-icon></span>
              <a href="https://www.facebook.com/betgamegg/"><span class="pr-4"><v-icon>mdi-facebook</v-icon></span></a>
              <span class="pr-4"><v-icon>mdi-instagram</v-icon></span>
              <span><v-icon>mdi-gmail</v-icon></span>
             </div> -->
            <v-list-item
              v-if="
                !!this.$auth.user
                  ? this.$auth.user.type == 'super-admin'
                  : this.$auth.user
              "
            >
              <v-list-item-content>
                <v-list-item-title
                  class="tw-flex tw-items-center"
                  @click="openMessage"
                  ><v-icon class="mr-2">mdi-message</v-icon
                  >MESSAGES</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <div class="tw-py-4 tw-text-sm tw-text-white">
              <v-icon class="mr-2">mdi-facebook</v-icon>
              <a href="https://www.facebook.com/betgamegg" class="facebook-link"
                >www.facebook.com/betgamegg</a
              >
            </div>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- END ADMIN NAV DRAWER -->
    <!-- PARTNER NAV DRAWER -->
    <v-navigation-drawer
      color="#0D111B"
      overlay-opacity="0.8"
      overlay-color="#000000BF"
      v-model="drawer"
      fixed
      temporary
      dark
      style="z-index: 9999"
      v-if="!!this.partner || $route.meta.partner"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <div
              class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-8"
            >
              <v-icon class="mr-5" @click="drawer = !drawer">mdi-menu</v-icon>
              <div>
                <a @click="home"
                  ><v-img
                    width="140"
                    :src="require('@/assets/images/betgame-gg.svg')"
                  ></v-img
                ></a>
              </div>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list>
        <v-list-item-group v-model="selectedItem">
          <v-list-item class="title">
            <v-list-item-content>
              <v-list-item-title
                @click="adminPage"
                class="tw-flex tw-items-center"
                >PARTNER DASHBOARD
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div>
            <v-row class="py-1">
              <v-col
                cols="2"
                class="text-left tw-pl-7 pb-0 pt-0"
                style="align-self: center"
              >
                <v-icon>mdi-wallet</v-icon>
              </v-col>
              <v-col cols="10" class="text-left tw-pl-4 tw-text-sm">
                <div class="">PARTNER CREDITS</div>
                ₱
                {{
                  partnerCredits
                    ? Number(partnerCredits).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : ""
                }}
              </v-col>
            </v-row>
          </div>
          <v-list-item
            v-for="item in partner_items"
            :key="item.text"
            :to="item.link"
          >
            <v-list-item-content>
              <v-list-item-title class="tw-flex tw-items-center"
                ><v-icon class="mr-2">{{ item.icon }}</v-icon
                ><span class="tw-text-sm">{{
                  item.text
                }}</span></v-list-item-title
              >
              <!-- <v-list-item-subtitle class="d-flex justify-left ml-8" v-if="item.show_credits"> ₱
                {{
                  partnerCredits
                    ? Number(partnerCredits).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : ""
                }}
              </v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>
          <!-- Partner Side Navigation -->
          <div
            v-if="
              $vuetify.breakpoint.mdAndDown ||
              !!this.partner ||
              $route.meta.partner
            "
          >
            <v-divider></v-divider>

            <v-list-item to="/partner/agent-tree">
              <v-list-item-title class="tw-flex tw-items-center">
                <v-icon class="mr-2">mdi-file-tree</v-icon
                ><span class="tw-text-sm">AGENT TREE</span></v-list-item-title
              >
            </v-list-item>
            <v-list-item to="/partner/active-players/">
              <v-list-item-title class="tw-flex tw-items-center">
                <v-icon class="mr-2">mdi-account-check</v-icon
                ><span class="tw-text-sm"
                  >ACTIVE PLAYERS</span
                ></v-list-item-title
              >
            </v-list-item>
            <!-- MANAGE SUB-USER NAV ONLY PARTNER TYPE = PARTNERS CAN SEE -->
            <div v-if="partner_type == 'partners' && partner_subcount">
              <v-list-item to="/partner/manage-players">
                <v-list-item-title class="tw-flex tw-items-center">
                  <v-icon class="mr-2">mdi-account-badge</v-icon
                  ><span class="tw-text-sm"
                    >MANAGE SUB-USER</span
                  ></v-list-item-title
                >
              </v-list-item>
            </div>
            <!-- END MANAGE SUB-USER NAV ONLY PARTNER TYPE = PARTNERS CAN SEE -->

            <!-- <v-list-item to="/partner/active-agents/">
               <v-list-item-title class="tw-flex tw-items-center">
                <v-icon class="mr-2">mdi-account</v-icon>Active Agents</v-list-item-title>
            </v-list-item> -->

            <v-divider></v-divider>
            <!-- <v-list-item
            v-show="is_ogc"
            >
              <v-list-item-content>
                 <router-link
                    to="/partner/dashboard-ogc"
                    ><div
                      class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-8"
                    >
                      <v-icon class="tw-mr-2">mdi-tablet-dashboard</v-icon>Dashboard OGC 
                    </div></router-link
                  >
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item-group>
              <v-list-item @click="openUserDialog" inactive>
                <v-list-item-content>
                  <v-list-item-title class="tw-flex tw-items-center"
                    ><v-icon class="mr-2">mdi-account-plus</v-icon
                    ><span class="tw-text-sm">
                      ADD USERS
                    </span></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="openTransferMoneyDialog" inactive>
                <v-list-item-content>
                  <v-list-item-title class="tw-flex tw-items-center"
                    ><v-icon class="mr-2">mdi-swap-horizontal</v-icon
                    ><span class="tw-text-sm"
                      >TRANSFER MONEY</span
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="openRegistrationLinkDialog" inactive>
                <v-list-item-content>
                  <v-list-item-title
                    class="tw-flex tw-items-center"
                    v-clipboard="clipboardValue"
                    ><v-icon class="mr-2">mdi-link-plus</v-icon
                    ><span class="tw-text-sm"
                      >REGISTRATION LINK</span
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item @click="openMessage" inactive>
                <v-list-item-content>
                  <v-list-item-title class="tw-flex tw-items-center"
                    ><v-icon class="mr-2">mdi-message</v-icon
                    ><span class="tw-text-sm">MESSAGES</span></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <!-- <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="tw-flex tw-items-center" @click="openFbChat"><v-icon class="mr-2">mdi-chat</v-icon>Chat with Us</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
            <v-divider></v-divider>
            <div class="mt-5">
              <span class="pr-4"><v-icon>mdi-twitter</v-icon></span>
              <a href="https://www.facebook.com/betgamegg/"
                ><span class="pr-4"><v-icon>mdi-facebook</v-icon></span></a
              >
              <span class="pr-4"><v-icon>mdi-instagram</v-icon></span>
              <span><v-icon>mdi-gmail</v-icon></span>
            </div>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- END PARTNER NAV DRAWER -->

    <!-- AFFILIATE NAV DRAWER -->
    <v-navigation-drawer
      color="#0D111B"
      overlay-opacity="0.8"
      overlay-color="#000000BF"
      v-model="drawer"
      fixed
      temporary
      dark
      style="z-index: 9999"
      v-if="this.$auth.user && !!this.$auth.user.voucher_code"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <div
              class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-8"
            >
              <v-icon class="mr-5" @click="drawer = !drawer">mdi-menu</v-icon>
              <div>
                <a @click="home"
                  ><v-img
                    width="140"
                    :src="require('@/assets/images/betgame-gg.svg')"
                  ></v-img
                ></a>
              </div>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list>
        <v-list-item-group v-model="selectedItem">
          <v-list-item class="title">
            <v-list-item-content>
              <v-list-item-title
                @click="adminPage"
                class="tw-flex tw-items-center"
                >STREAMER DASHBOARD</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="item in affiliate_items"
            :key="item.text"
            :to="item.link"
          >
            <v-list-item-content>
              <v-list-item-title class="tw-flex tw-items-center tw-text-sm"
                ><v-icon class="mr-2">{{ item.icon }}</v-icon
                >{{ item.text }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="openMessage" inactive>
            <v-list-item-content>
              <v-list-item-title class="tw-flex tw-items-center"
                ><v-icon class="mr-2">mdi-message</v-icon
                >Messages</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <div class="mt-5">
            <span class="pr-4"><v-icon>mdi-twitter</v-icon></span>
            <a href="https://www.facebook.com/betgamegg/"
              ><span class="pr-4"><v-icon>mdi-facebook</v-icon></span></a
            >
            <span class="pr-4"><v-icon>mdi-instagram</v-icon></span>
            <span><v-icon>mdi-gmail</v-icon></span>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- END AFFILIATE NAV DRAWER -->
    <!-- SUB USER NAV DRAWER -->
    <v-navigation-drawer
      color="#0D111B"
      overlay-opacity="0.8"
      overlay-color="#000000BF"
      v-model="subuserdrawer"
      fixed
      temporary
      dark
      style="z-index: 9999"
    >
      <!-- v-if="!!this.partner || $route.meta.partner" -->
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <div
              class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-8"
            >
              <v-icon class="mr-5" @click="subuserdrawer = !subuserdrawer"
                >mdi-menu</v-icon
              >
              <div>
                <a @click="home"
                  ><v-img
                    width="140"
                    :src="require('@/assets/images/betgame-gg.svg')"
                  ></v-img
                ></a>
              </div>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list>
        <v-list-item-group v-model="selectedItem">
          <v-list-item class="title">
            <v-list-item-content>
              <v-list-item-title
                @click="adminPage"
                class="tw-flex tw-items-center"
                >PARTNER DASHBOARD
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div>
            <v-row class="py-1">
              <v-col
                cols="2"
                class="text-left tw-pl-7 pb-0 pt-0"
                style="align-self: center"
              >
                <v-icon>mdi-wallet</v-icon>
              </v-col>
              <v-col cols="10" class="text-left tw-pl-4 tw-text-sm">
                <div class="">PARTNER CREDITS</div>
                ₱
                {{
                  subUserPartnerCredits
                    ? Number(subUserPartnerCredits).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : ""
                }}
              </v-col>
            </v-row>
          </div>
          <v-list-item
            v-for="item in subuser_items"
            :key="item.text"
            :to="item.link"
          >
            <v-list-item-content>
              <v-list-item-title class="tw-flex tw-items-center"
                ><v-icon class="mr-2">{{ item.icon }}</v-icon
                ><span class="tw-text-sm">{{
                  item.text
                }}</span></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <!-- Partner Side Navigation -->
          <div
            v-if="
              $vuetify.breakpoint.mdAndDown ||
              !!this.partner ||
              $route.meta.partner
            "
          >
            <v-divider></v-divider>

            <v-list-item to="/partner/sub-user/active-agents/">
              <v-list-item-title class="tw-flex tw-items-center">
                <v-icon class="mr-2">mdi-file-tree</v-icon
                ><span class="tw-text-sm">AGENT TREE</span></v-list-item-title
              >
            </v-list-item>
            <v-list-item to="/partner/sub-user/active-players/">
              <v-list-item-title class="tw-flex tw-items-center">
                <v-icon class="mr-2">mdi-account-check</v-icon
                ><span class="tw-text-sm"
                  >ACTIVE PLAYERS</span
                ></v-list-item-title
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item-group>
              <v-list-item @click="openUserDialog" inactive>
                <v-list-item-content>
                  <v-list-item-title class="tw-flex tw-items-center"
                    ><v-icon class="mr-2">mdi-account-plus</v-icon
                    ><span class="tw-text-sm">
                      ADD USERS
                    </span></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="openTransferMoneyDialog" inactive>
                <v-list-item-content>
                  <v-list-item-title class="tw-flex tw-items-center"
                    ><v-icon class="mr-2">mdi-swap-horizontal</v-icon
                    ><span class="tw-text-sm"
                      >TRANSFER MONEY</span
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </v-list-item-group>
            <v-divider></v-divider>
            <div class="mt-5">
              <span class="pr-4"><v-icon>mdi-twitter</v-icon></span>
              <a href="https://www.facebook.com/betgamegg/"
                ><span class="pr-4"><v-icon>mdi-facebook</v-icon></span></a
              >
              <span class="pr-4"><v-icon>mdi-instagram</v-icon></span>
              <span><v-icon>mdi-gmail</v-icon></span>
            </div>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- CASINO NAV DRAWER -->

    <v-navigation-drawer
      color="#0D111B"
      overlay-opacity="0.8"
      overlay-color="#000000BF"
      v-model="casinodrawer"
      fixed
      temporary
      dark
      width="375"
      style="z-index: 9999"
    >
      <div>
        <div
          class="d-flex justify-space-between align-center tw-pb-4 tw-font-bold tw-text-lg tw-px-8 tw-py-4 tw-uppercase"
        >
          <div class="tw-text-start">Game Categories</div>
          <v-icon v-if="casinodrawer" @click="casinodrawer = !casinodrawer"
            >mdi-chevron-left</v-icon
          >
        </div>
        <div class="tw-border-b-2 tw-border-white"></div>
        <div class="tw-p-8">
          <v-list class="pa-0" color="transparent">
            <v-list-item
              v-for="(item, i) in filteredCasinoNavItem"
              :key="i"
              class="pa-0 tw-text-red tw-group"
              @click="goToCasino(item.link)"
            >
              <v-list-item-content class="tw-font-bold">
                <div
                  :class="`tw-flex tw-gap-6 tw-items-center tw-cursor-pointer group-hover:tw-text-[#E4682D] ${
                    $route.path === item.link && 'tw-text-[#E4682D]'
                  }`"
                >
                  <v-icon
                    :color="$route.path === item.link && '#E4682D'"
                    :class="`tw-text-red-500 group-hover:tw-text-[#E4682D] `"
                  >
                    {{ item.icon }}
                  </v-icon>
                  <div class="">{{ item.name }}</div>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <div class="tw-border-b-2 tw-border-white"></div>
        <div v-if="$route.name != 'CasinoPlay'">
          <div
            class="tw-text-start tw-pb-4 tw-font-bold tw-text-lg tw-px-8 tw-py-4 tw-uppercase"
          >
            Game Provider
          </div>
          <div class="tw-border-b-2 tw-border-white"></div>
          <div>
            <ProviderButtons
              :type="casinoType($route.name)"
              :providers="provider"
              :isHeader="casinodrawer"
            />
          </div>
        </div>
        <div class="tw-p-4" v-else>
          <div class="tw-font-bold tw-text-lg tw-text-start">PROVIDER</div>
          <div
            class="tw-grid tw-grid-cols-1 tw-gap-4"
            style="max-height: 50vh; overflow-y: auto"
          >
            <div v-for="item in provider" class="">
              <div
                class="tw-overflow-hidden tw-rounded-lg tw-bg-[#2C90E3] tw-py-2 tw-px-8"
              >
                <img
                  class="tw-cursor-pointer tw-h-[50px] tw-w-[100%] tw-object-contain tw-text-black tw-font-extrabold tw-uppercase"
                  :src="`../../../provider_img_white/${item.provider}.png`"
                  :alt="item.provider"
                  @click="goToHomeProvider(item.provider)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
    <!-- END CASINO NAV DRAWER -->

    <Login />
    <Signup />

    <TypeofUser
      :typeOfUser="type_of_user_dialog"
      :is_subuser="is_subuser"
      @closeDialog="closeAddUserDialog"
    />
    <TransferMoneyUserType
      :transferMoneyUserType="transfer_money_user_type_dialog"
      @closeDialog="closeTransferMoneyDialog"
    />
    <SubUserTransferMoneyUserType
      :transferMoneyUserType="sub_user_transfer_money_user_type_dialog"
      @closeDialog="closeSubUserTransferMoneyDialog"
    />
    <DirectDialog
      :directDialog="direct_dialog"
      @closeDirectDialog="closeDirectDialog"
    />
    <PartnerDialog
      :partnerDialog="this.partner_dialog"
      @closePartnerDialog="closePartnerDialog"
    />
    <RegistrationLinkDialog
      :clipboardValue="clipboardValue"
      :registrationDialog="registration_link_dialog"
      @closeDialog="closeRegistrationLinkDialog"
    />
    <OnholdAlert
    :onholdalertDialog="onhold_alert_dialog"
    :betwhere="betwhere"
     @closeDialog="closeOnholdAlertDialog" />
  </div>
</template>
<script>
import io from "socket.io-client";
import { mapActions, mapMutations, mapState } from "vuex";
import Login from "./auth/Login.vue";
import Signup from "./auth/Signup.vue";
import TypeofUser from "../components/partner/dashboard/modal/TypeofUser.vue";
import TransferMoneyUserType from "../components/partner/dashboard/modal/TransferMoneyUserType.vue";
import SubUserTransferMoneyUserType from "../components/partner/sub-user/dashboard/TransferMoneyUserType.vue";
import DirectDialog from "../components/profile/DepositWithdrawTab/modal/DirectEvoucher.vue";
import PartnerDialog from "../components/profile/DepositWithdrawTab/modal/PartnerDialog.vue";
import RegistrationLinkDialog from "../components/partner/dashboard/modal/RegistrationLink.vue";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import Sports from "@/assets/images/sports_icon.svg";
import SportsHighlights from "@/assets/images/sports_icon_highlights.svg";
import BasketBall from "@/assets/images/basketball.svg";
import MMA from "@/assets/images/mma.svg";
import Soccer from "@/assets/images/soccer.svg";
import ProviderButtons from "./casino/ProviderButtons.vue";
import { EventBus } from "@/plugins/eventBus";

import OnholdAlert from "../components/alert/OnholdAlert.vue";
export default {
  components: {
    Login,
    Signup,
    TypeofUser,
    TransferMoneyUserType,
    DirectDialog,
    PartnerDialog,
    SubUserTransferMoneyUserType,
    RegistrationLinkDialog,
    ProviderButtons,
    OnholdAlert
  },
  data() {
    return {
      drawer: null,
      bettordrawer: null,
      subuserdrawer: null,
      casinodrawer: null,
      selectedItem: 1,
      subUserSelectedItem: 0,
      // user: this.$session.get("user"),
      partner: "",
      items: [
        { text: "Esports", icon: "mdi-controller-classic" },
        { text: "Esport News", icon: "mdi-newspaper" },
        { text: "Help", icon: "mdi-help-circle-outline" },
        { text: "Partner Dashboard", icon: "mdi-help-circle-outline" },
      ],
      partner_items: [
        { text: "HOME", icon: "mdi-home", link: "/", show_credits: false },

        {
          text: "DASHBOARD",
          icon: "mdi-view-dashboard",
          link: "/partner/dashboard",
          show_credits: false,
        },
        // { text: 'Transactions History', icon: 'mdi-newspaper' },
        // { text: 'Payout', icon: 'mdi-help-circle-outline' },
        {
          text: "PARTNER PROFILE",
          icon: "mdi-account",
          link: "/partner/profile",
          show_credits: false,
        },
        // {
        //   text: "Partner Credits",
        //   icon: "mdi-wallet",
        //   show_credits : true
        // },
      ],
      affiliate_items: [
        { text: "HOME", icon: "mdi-home", link: "/" },

        { text: "DASBOARD", icon: "mdi-view-dashboard", link: "/affiliate" },
        {
          text: "ACTIVE PLAYERS",
          icon: "mdi-account",
          link: "/affiliate/active-players",
        },
        // { text: 'Transactions History', icon: 'mdi-newspaper' },
        // { text: 'Payout', icon: 'mdi-help-circle-outline' },
      ],
      subuser_items: [
        {
          text: "Partner Dashboard",
          icon: "mdi-view-dashboard",
          link: "/partner/sub-user/dashboard",
        },
        {
          text: "Partner Profile",
          icon: "mdi-account",
          link: "/partner/sub-user/profile",
        },
        // { text: 'Transactions History', icon: 'mdi-newspaper' },
        // { text: 'Payout', icon: 'mdi-help-circle-outline' },
      ],
      user_items: [
        {
          text: "HOME",
          icon: "mdi-home",
          href: "%2F",
        },
        {
          text: "CASINO",
          icon: "mdi-slot-machine",
          href: "/casino",
        },
        {
          text: "LIVE",
          icon: "mdi-broadcast",
          href: "%2Flive",
        },
        {
          text: "ESPORTS",
          icon: "mdi-gamepad-variant",
          href: "%2Fe_sport",
        },
        {
          text: "SPORTS",
          icon: "mdi-run",
          href: "%2Fbasketball%2Fusa%2Fnba-1669819088278523904",
          subheader: [
            {
              name: "NBA",
              link: "%2Fbasketball%2Fusa%2Fnba-1669819088278523904",
              bt_path: "/basketball/usa/nba-1669819088278523904",
              icon: BasketBall,
            },
            {
              name: "MMA",
              link: "%2F117",
              bt_path: "/117",
              icon: MMA,
            },
            {
              name: "Soccer",
              link: "%2F1",
              bt_path: "/1",
              icon: Soccer,
            },
          ],
        },
        {
          text: "FAVORITES",
          icon: "mdi-star",
          href: "%2Ffavorites",
        },
        {
          text: "SPORTBOOK BETS",
          icon: "mdi-receipt",
          href: "%2Fbets",
        },
        {
          text: "SEARCH",
          icon: "mdi-magnify",
          href: "%2Fsearch",
        },
      ],
      nestedListGroupOpen: false,
      user_info: {},
      type_of_user_dialog: false,
      transfer_money_user_type_dialog: false,
      sub_user_transfer_money_user_type_dialog: false,
      registration_link_dialog: false,
      clipboardValue: "",
      link: false,
      partner_route: "",
      direct_dialog: false,
      partner_dialog: false,
      partnerQuery: "",
      userCredits: 0,
      partnerCredits: 0,
      loading: true,
      is_subuser: "",
      tab: null,
      sports: [
        {
          name: "NBA",
          link: "%2Fbasketball%2Fusa%2Fnba-1669819088278523904",
          icon: BasketBall,
        },
        {
          name: "MMA",
          link: "%2F117",
          icon: MMA,
        },
        {
          name: "Soccer",
          link: "%2F1",
          icon: Soccer,
        },
      ],
      unreadmessage: 0,
      subUserPartnerCredits: 0,
      sub_user: "",
      // is_ogc: "",
      sports_image: Sports,
      highlighted_sports_image: SportsHighlights,
      sportbook_tab: "",
      subUserAction: "",
      permissions: [],
      partner_type: "",
      partner_subcount: "",
      casino_items: [
        {
          name: "Home",
          icon: "mdi-home",
          link: "/casino",
        },
        {
          name: "Recent Played",
          icon: "mdi-history",
          link: "/casino/recent_played",
          requiresAuth: true,
        },
        {
          name: "Favorites",
          icon: "mdi-heart",
          link: "/casino/favorites",
          requiresAuth: true,
        },
        {
          name: "Slots",
          icon: "mdi-slot-machine",
          link: "/casino/slots",
        },
        // {
        //   name: "Lottery",
        //   icon: "mdi-dice-multiple",
        //   link: "/casino/lottery",
        // },
        {
          name: "Live Casino",
          icon: "mdi-dice-multiple",
          link: "/casino/live",
        },
        {
          name: "Sportsbook",
          icon: "mdi-controller-classic",
          link: "/%2F",
        },
        {
          name: "Administrator",
          icon: "mdi-shield-crown",
          link: "/casino/admin/casino-games",
          requiresAuth: true, // Add a property to identify restricted items
          requiresAdmin: true,
        },
      ],
      providers: [],
      onhold_alert_dialog: false,
      betwhere: "",
      hasNegativeCreditNotif: ""
    };
  },

  methods: {
    ...mapActions("user", [
      "logout",
      "getUserInfo",
      "userValidate",
      "getMessage",
      "getSubUserAction",
    ]),
    ...mapActions("partner", ["getPartnerData"]),
    ...mapActions("user", ["getSubUser", "socketAction"]),
    ...mapMutations("slotegator", [
      "SET_SHOW_LOGIN",
      "SET_SHOW_REGISTER",
      "SET_SHOW_DEPOSIT",
      "SET_SHOW_PARTNER_DEPOSIT",
    ]),
    ...mapMutations("user", ["setSocketMutation"]),
    ...mapMutations("app", ["SET_SHOW_FB_CHAT"]),
    async userInfo() {
      if (this.$auth.user) {
        await this.getUserInfo(this.$auth.user.id);
        this.user_info = this.auth_user;
      }
    },
    async logoutUser() {
      this.$cookies.remove("_token");
      await this.logout();
    },
    profile() {
      console.log("xxxxxxxxx");
      if (this.$route.name != "PartnerDashboard") {
        if (this.$route.path != "/profile") {
          this.$router.push("/profile").catch(() => {});
        }
      } else {
        if (this.$route.path != "/partner/profile") {
          this.$router.push("/partner/profile").catch(() => {});
        }
      }
    },
    message() {
      this.$router.push("/messages").catch(() => {});
    },
    home() {
      if (this.$route.path != "/") {
        this.$router.push("/").catch(() => {});
      }
    },
    adminPage() {
      if (this.$route.path != "/admin") {
        this.$router.push("/admin").catch(() => {});
      }
    },
    partnerDashboardPage() {
      if (this.$route.path != "/partner/dashboard") {
        this.$router.push("/partner/dashboard").catch(() => {});
      }
    },

    affiliateDashboardPage() {
      if (this.$route.path != "/affiliate") {
        this.$router.push("/affiliate").catch(() => {});
      }
    },
    showLogin() {
      console.log("LGOIN");
      this.SET_SHOW_REGISTER(false);
      this.SET_SHOW_LOGIN(true);
    },
    showRegister() {
      this.SET_SHOW_LOGIN(false);
      this.SET_SHOW_REGISTER(true);
    },
    openUserDialog() {
      this.type_of_user_dialog = true;
      this.drawer = false;
    },
    openFbChat() {
      if (this.$route.path != "/profile") {
        this.$router.push("/profile").catch(() => {});
      }
    },
    closeAddUserDialog() {
      this.type_of_user_dialog = false;
    },
    openTransferMoneyDialog() {
      this.transfer_money_user_type_dialog = true;
      this.drawer = false;
    },
    openRegistrationLinkDialog() {
      this.registration_link_dialog = true;
      this.drawer = false;
    },
    closeRegistrationLinkDialog() {
      this.registration_link_dialog = false;
    },
    closeOnholdAlertDialog() {
      this.onhold_alert_dialog = false;
    },
    openSubUserTransferMoneyDialog() {
      this.sub_user_transfer_money_user_type_dialog = true;
      console.log("LOG", this.sub_user_transfer_money_user_type_dialog);
    },
    closeTransferMoneyDialog() {
      this.transfer_money_user_type_dialog = false;
    },
    closeSubUserTransferMoneyDialog() {
      this.sub_user_transfer_money_user_type_dialog = false;
      console.log("LOG", this.sub_user_transfer_money_user_type_dialog);
    },
    copyLink() {
      console.log("linkkkked", process.env.VUE_APP_REGISTRATION_LINK);
      this.$toast.success("Registration Link copied");
    },
    openMessage() {
      if (this.$route.path != "/messages") {
        this.$router.push("/messages").catch(() => {});
      }
    },
    async getPartnerRoute(value = null) {
      if (value) {
        this.SET_SHOW_REGISTER(true);
      }
    },
    async showVoucherReg(value = null) {
      if (value) {
        this.SET_SHOW_REGISTER(true);
      }
    },
    openDirectDialog() {
      console.log("asdad");
      this.direct_dialog = true;
      this.SET_SHOW_DEPOSIT(true);
    },
    closeDirectDialog() {
      this.direct_dialog = false;
      this.SET_SHOW_DEPOSIT(false);
    },
    openPartnerDialog() {
      this.partner_dialog = true;
      this.SET_SHOW_PARTNER_DEPOSIT(true);
    },
    closePartnerDialog() {
      this.partner_dialog = false;
      this.SET_SHOW_PARTNER_DEPOSIT(false);
    },
    FrequentlyAskedQuestions() {
      if (this.$route.path != "/faqs") {
        this.$router.push("/faqs").catch(() => {});
      }
    },
    ResponsibleGamblingPolicy() {
      if (this.$route.path != "/responsible-gambling-policy") {
        this.$router.push("/responsible-gambling-policy").catch(() => {});
      }
    },
    navTo(tab) {
      this.sportbook_tab = tab;

      console.log("this.bt_reader", this.$route.query["bt-path"]);
      if (this.bt_reader && this.$route.name == "Home") {
        this.bt_reader.updateOptions({ url: tab });
      } else {
        this.goTo(tab);
      }
    },
    navToMobile(tab) {
      this.bettordrawer = false;
      this.sportbook_tab = tab;
      console.log("navToMobile", tab);
      if (tab == "/casino") {
        this.$router.push({ path: "/casino" });
        return;
      }
      console.log("this.bt_reader", this.$route.query["bt-path"]);
      if (this.bt_reader && this.$route.name == "Home") {
        this.bt_reader.updateOptions({ url: tab });
      } else {
        this.goTo(tab);
      }
    },
    watchNav() {
      console.log("%2Fe_sport", this.$route.query);
      switch (this.$route.query["bt-path"] || this.route.name == "Home") {
        case "/":
          this.tab = "home";
          this.sportbook_tab = "%2F";
          break;
        case "/live":
          this.tab = "live";
          this.sportbook_tab = "%2Flive";
          break;
        case "/e_sport":
          this.tab = "e_sport";
          this.sportbook_tab = "%2Fe_sport";
          break;
        case "/basketball/usa/nba-1669819088278523904":
          this.sportbook_tab = "%2Fbasketball%2Fusa%2Fnba-1669819088278523904";

          break;
        case "/117":
          this.sportbook_tab = "%2F117";

          break;
        case "/1":
          this.sportbook_tab = "%2F1";

          break;
        case "/favorites":
          this.tab = "favorites";
          this.sportbook_tab = "%2Ffavorites";
          break;
        case "/bets":
          this.tab = "bets";
          this.sportbook_tab = "%2Fbets";
          break;
        case "/search":
          this.tab = "search";
          this.sportbook_tab = "%2Fsearch";
          break;
        default:
          this.tab = "home";
          this.sportbook_tab = "%2F";
      }
    },
    goTo(link) {
      this.$router.push(`/?bt-path=${link}`);
    },
    async getMessageNotif() {
      if (!!this.$auth.user) {
        const response = await this.getMessage({
          page: 1,
          limit: 10,
          search: "",
          id: this.$auth.user.id,
        });

        const negativeCredit = response.rows && response.rows.map(msg => {
          if (msg.subject === "User Credit Report – Negative Balance Alert") {
            return {
              ...msg,
              isUnread: msg.is_read,
            };
          }
          return null; // Return null for messages that don't match
        })
        .filter(msg => msg !== null && msg.is_read);
        if(negativeCredit.length > 0){
          this.hasNegativeCreditNotif = 1;
        }else{
          this.hasNegativeCreditNotif = 0;
        }
        
        this.unreadmessage = response.unread;
      }
    },
    goToCasino(link) {
      this.$router.push(link);
    },
    casinoType(name) {
      switch (name) {
        case "CasinoHome":
          return "home";
        case "CasinoSlots":
          return "slots";
        case "CasinoLive":
          return "live";
        case "CasinoFavorites":
          return "favorites";
        case "CasinoRecentPlayed":
          return "recent_played";
        default:
          return "home";

          break;
      }
    },
    async playGame(game) {
      if (!this.$auth.user) {
        return this.SET_SHOW_LOGIN(true);
      }
      await this.$router.push(
        `/casino/${game.game_type}/${game.id}/${this.$route.params.type}`
      );
    },
    async goToHomeProvider(provider) {
      await this.$router.push(`/casino`);
      EventBus.$emit("selectedProvider", [provider]);
    },
  },
  computed: {
    ...mapState("user", ["auth_user", "socket"]),
    ...mapState("slotegator", ["bt_reader"]),
    ...mapState("app", ["showFbChat"]),
    ...mapState("casino", ["provider"]),
    currentRouteName() {
      return this.$route.name;
    },
    total() {
      let credits = this.$auth.user && parseInt(this.$auth.user.credits);
      let partner_credits =
        this.partner && parseInt(this.partner.partner_credits);
      let sum = credits + partner_credits;
      return sum;
    },
    isMobile() {
      return useBreakpoints(breakpointsTailwind).smallerOrEqual("sm");
    },
    getUserMessageData() {
      return this.unreadmessage ? this.unreadmessage : 0;
    },
    hasSubheader() {
      return this.user_items.some(
        (item) => item.text === "SPORTS" && item.subheader
      );
    },
    filteredCasinoNavItem() {
      return this.casino_items.filter(
        (item) =>
          (!item.requiresAuth || (item.requiresAuth && this.$auth.user)) &&
          (!item.requiresAdmin ||
            (item.requiresAdmin &&
              (this.$auth.user.type === "admin" ||
                this.$auth.user.type === "super-admin")))
      );
    },
  },
  async mounted() {
    window.addEventListener("popstate", (event) => {
      this.watchNav();
    });
    if (this.socket) {
      const that = this;
      this.socket.on("receiveMail", (data) => {
        console.log("DATA OF RECEIVE EMAIL", data);
        if (data.id) {
          that.unreadmessage += 1;
        }
      });
    }
    await this.userValidate();
    this.is_subuser = this.$cookies.get("is_subuser");
    this.userCredits = this.$auth.user && this.$auth.user.credits;
    // this.is_ogc = this.partner.id == process.env.VUE_APP_OGC_ID ? true : false;
    // console.log("OGCCC",this.is_ogc)
    this.partner_type = this.$cookies.get("partner_type");

    let partner_id = this.$cookies.get("partner_id");

    let hasSubUser = this.$cookies.get("has_sub_users");
    this.partner_subcount = hasSubUser;

    if (this.is_subuser) {
      const res = await this.getSubUser(this.$auth.user.id);
      this.sub_user = res;
      this.subUserPartnerCredits = res && res.partner_credits;
      console.log("RESSSS", this.subUserPartnerCredits);
      const response = await this.$authApi.post(
        `/partner/sub-user/getlink/${res.user_id}`
      );
      if (response && response.data) {
        this.clipboardValue = response.data.link;
      }
    }
    // console.log("HEADER RESPONSE",response)
    // this.clipboardValue = `${process.env.VUE_APP_SOCKET_IO_PORT}?partner=${partner_id}`;
    if (parseInt(partner_id)) {
      const response = await this.$authApi.post("/partner/getlink");
      console.log("HEADER RESPONSE", response);
      this.clipboardValue = response.data.link;
    }
    this.partnerCredits = this.partner && this.partner.partner_credits;

    const that = this;
    if (this.$auth.user && this.socket) {
      this.socket.on("updatedCredits", (data) => {
        that.userCredits = data;
        that.loading = false;
        console.log("hey hey hery updated", data);
      });

      this.socket.on("returnBlockBetMessage", (data) => {
        console.log("block user bet", data);
        this.onhold_alert_dialog = true;
        console.log("block user sportsbooks bet:", data);
      });

      this.socket.on("returnBlockCasinoBetMessage", (data) => {
        this.betwhere = "casino"
        this.onhold_alert_dialog = true;
        console.log("block user casino bet: ", data);
      });
      if (!!partner_id) {
        this.socket.on("updatedPartnerCredits", (data) => {
          that.partnerCredits = data;
          that.loading = false;
          console.log("hey hey hery updated partner credits", data);
        });
      }
    }
    this.watchNav();
  },
  async created() {
    if (this.$auth.user) {
      let socket = io(`${process.env.VUE_APP_SOCKET_IO_PORT}`, {
        reconnectionDelayMax: 10000,
        query: {
          userID: this.$auth.user.id,
        },
      });
      // console.log("set stocket",socket)
      // this.SET_SOCKET(socket);
      this.socketAction(socket);
      this.userCredits = this.$auth.user && this.$auth.user.credits;

      // console.log("socket?", this.socket);
      // this.user = this.$auth.user != null ? true : false;
      this.partner = await this.getPartnerData(this.$auth.user.id);
      this.partnerCredits = this.partner && this.partner.partner_credits;
    }
    this.userInfo();
    const unwatch = this.$watch(
      () => this.$route,
      (route, prevRoute) => {
        this.partnerQuery = route.query.partner;
        this.getPartnerRoute(this.partnerQuery);
        let voucherCode = route.query.voucher;
        this.showVoucherReg(voucherCode);
        unwatch();
      }
    );
    await this.getMessageNotif();
  },
  watch: {
    // "$route.query": function () {
    //   this.watchNav();
    // },
    "$route.query": async function (newVal, oldVal) {
      await this.watchNav();
      console.log(`bt-path changed from "${oldVal}" to "${newVal}"`);
      this.btPath = newVal; // Update the component data
    },
  },
};
</script>
<style scoped>
.loading {
  width: 20px;
  height: 20px;
  background: transparent;
}
.divider {
  border-color: hsl(0deg 0% 100%) !important;
  transform: rotateX(45deg);
  height: 68px;
}
.amounts {
  width: 141%;
}
.title {
  border-bottom: 4px solid #ffffff;
}
.dropdown {
  background: linear-gradient(140.61deg, #0b1638 0%, #39351a 100%) !important;
}
.facebook-link {
  color: white;
  /* Optionally, you can add additional styles like text-decoration: none; to remove underline */
  text-decoration: underline;
}
.glowing-badge >>> .v-badge__badge{
  content: "";
  animation: glow 0.2s infinite alternate;
}
@keyframes glow {
  0% {
    box-shadow: 0 0 50px rgba(255, 0, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 25px rgba(255, 0, 0, 1);
  }
}

</style>
