const state = {
    error: null,
    casinogames: null,
    admincasinogames: null,
    admincasinogamedetails: null,
    admincasinojiligames: null,
    admincasinopragmaticgames: null,
    admincasinooneapigames: null,
    favoritecasinogames: null,
    recentplayedcasinogames: null,
    provider: null,
    similarcasinogames: null,
    topcasinogames: null,
    selectedprovider: null
}
export default state;