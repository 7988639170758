<template>
	<v-container class="cashout">
			<v-form ref="form" v-model="valid" lazy-validation>
				<v-row>
					<v-col class="pl-8">
						<v-col class="px-0">
							<v-card class="transfer-cashout" dark>
								<v-card-title class="transfer-cashout-text d-flex justify-center"
								>My Balance</v-card-title
								>
								<v-card-text class="transfer-cashout-text"
								>₱{{
									partnerprofile && partnerprofile.partner_credits | formatMoney
								}}</v-card-text
								>
							</v-card>
						</v-col>
						<v-col class="px-0">
							<v-card class="transfer-cashout" dark>
									<v-card-title class="transfer-cashout-text d-flex justify-center"
								>My Total-Available</v-card-title
								>
								<v-card-text class="transfer-cashout-text"
								>₱{{ totalBalance | formatMoney }}</v-card-text
								>
							</v-card>
						</v-col>
					</v-col>
					<v-col class="pr-8">
						<v-col class="px-0">
							<v-card class="transfer-cashout" dark>
								<v-card-title class="transfer-cashout-text d-flex justify-center"
								>User Balance</v-card-title
								>
								<v-card-text class="transfer-cashout-text">&#8369;{{
									formatNumber(userBalance)
								}}</v-card-text>
							</v-card>
						</v-col>
					</v-col>
				</v-row>
				<v-row class="px-5">
					<v-col>
						<v-autocomplete
						v-model="selectPartnerUser"
						clearable
						outlined
						:items="agentList"
						label="Agents"
						item-value="id"
						:item-text="getFieldText"
						:rules="partnerUserRules"
						required
						rounded
						hide-details
						class="transfer-cashout-input"
						@keyup="searchPartner"
						:no-filter="true"
						 @click:clear="clearSearch"
						>
							<template v-slot:item="data">
								<template v-if="data.item.success">
									<v-row>
									<v-col cols="2" class="px-0" style="align-self: center">
										<v-chip
										x-small
										:color="getColor(data.item.type)"
										class="tw-text-xs"
										>{{ getInitial(data.item.type) }}</v-chip
										>
									</v-col>
									<v-col cols="10" class="text-start">
										<span class="tw-text-xs">
										{{ data.item.name }}
										</span>
										<br />
										<span class="tw-text-xs">
										{{ data.item.email }}
										</span>
									</v-col>
									</v-row>
								</template>
								<template v-else>
									<v-list-item disabled>
									<v-row
										class="justify-center align-center"
										style="color: white"
									>
										No data available
									</v-row>
									</v-list-item>
								</template>
							</template>
						</v-autocomplete>
					</v-col>
					<v-col>
						 <v-text-field
							:rules="amountRules"
							label="Amount"
							v-model="amount"
							outlined
							required
							rounded
							class="text-field-amount"
						></v-text-field>
					</v-col>
					<v-col class="pt-0" cols="12">
						<v-textarea
						v-model="comment"
						outlined
						name="input-7-4"
						label="Comment"
						class="transfer-cashout-input"
						hide-details
						></v-textarea>
					</v-col>
				</v-row>
				<v-card-actions class="transfer-cashout-text d-flex justify-center py-10">
					<v-btn color="white" text @click="closeDialog"> CANCEL </v-btn>
					<v-btn
						color="#45D0D2"
						:disabled="isDisabled"
						rounded
						@click="openConfirmation"
						class="px-5"
						>
						TRANSFER
					</v-btn>
				</v-card-actions>
				<v-dialog
				v-model="confirmDialog"
				@click:outside="closeDialog"
				@keydown.esc="closeDialog"
				height="400"
				width="400"
				>
					<v-card class="card">
						<v-col cols="12" class="pt-10 d-flex justify-center">
							<v-img max-height="75" max-width="75" :src="image"></v-img>
						</v-col>
						<v-col cols="12" class="d-flex justify-center pb-0">
							<v-card-title class="message-header font-weight-bold">
								ARE YOU SURE?
							</v-card-title>
						</v-col>
						<v-col cols="12" class="d-flex justify-center pt-0">
							<v-card-title class="message-header text-caption pt-0">
								<span class="text-wrap" style="width: 100%">Are you sure to</span>
								<span class="text-wrap" style="width: 100%"
								>proceed the Transaction?</span
								>
							</v-card-title>
						</v-col>
						<v-col cols="12" class="d-flex justify-center">
							<v-card-actions class="pb-10">
								<v-spacer></v-spacer>
								<v-btn
								class="dialog-btn"
								color="success"
								rounded
								@click="transferCashout"
								>
								YES
								</v-btn>
								<v-btn
								class="dialog-btn"
								color="error"
								rounded
								@click="closeconfirmDialog"
								>
								NO
								</v-btn>
							</v-card-actions>
						</v-col>
					</v-card>
				</v-dialog>
			</v-form>
			<Alert
			:alert_dialog="alert_dialog"
			:alert_success="alert_success"
			:alert_message="alert_message"
			@closeAlertDialog="closeAlertDialog"
			/>
	</v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Alert from "../../../dashboard/modal/Alert.vue";
import numeral from "numeral" 
import Question from "@/assets/images/alert/question.svg";
import { useDebounceFn } from "@vueuse/core";
export default {
	props:["viewDialog","transferMoneyAgentDialog"],
	data() {
		return {
			items: ["Foo", "Bar", "Fizz", "Buzz"],
			selectPartnerUser: "",
			selectedUser: "",
			agentDetails: [],
			amount: "",
			alert_dialog: false,
			alert_success: "",
			alert_message: "",
			valid: true,
			comment: "",
			amountRules: [
			(amount) => !!amount || "Amount is required",
			(amount) => (amount && amount >= 500) || "Minimum of 500",
			],
			partnerUserRules: [
			(selectPartnerUser) =>
			!!selectPartnerUser || "Transaction type is required",
			],
			commentRules: [(comment) => !!comment || "Transaction type is required"],
			confirmDialog: false,
			image: Question,
			fetchDebounce: useDebounceFn(async () => {
				const res = await this.getSubUser(this.$auth.user.id)
				await this.getPartnerSubPartners({
				search: this.selectPartner || "",
				id: res.id,
				});
			}, 500),
      isDisabled: false
		};
		
	},
	components: {
		Alert,
	},
	computed: {
		...mapState("partner", [
		"partneruser",
		"partnerprofile",
		"agenttransfertransaction",
		]),
		...mapState("user", ["auth_user"]),
		...mapState("admin", ["adminpartnersubpartners"]),
		agentList() {
			let subagent = this.adminpartnersubpartners && this.adminpartnersubpartners.map(data => {
				return {
					success: true,
					id : data && data.id,
					name: data && data.partner_name.charAt(0).toUpperCase() + data.partner_name.slice(1),
					credits: data && data.partner_credits,
					type: data && data.type,
					email: data && data.email
				}
			});
			return subagent;

			// console.log("PARTNER USER",this.partnerprofile)
			// if (this.partnerprofile.type != 'partners' && this.partnerprofile.type != 'city-manager' && this.partnerprofile.type != 'area-manager') {
			// 	console.log("TEST DATA")
			// 	let subagent = this.adminpartnersubpartners && this.adminpartnersubpartners.currentSubPartners.map(data => {
			// 		let res = this.adminpartnersubpartners.subPartners.find(subs => subs.id == data.id)
			// 		return {
			// 			id : res && res.id,
			// 			name: res && res.partner_name.charAt(0).toUpperCase() + res.partner_name.slice(1),
			// 			credits: res && res.partner_credits,
			// 			type: res && res.type
			// 		}
			// 	});
			// 	return subagent;
			// }else{
			// 	let subagent = this.adminpartnersubpartners && this.adminpartnersubpartners.map(data => {
			// 		return {
			// 			success: true,
			// 			id : data && data.id,
			// 			name: data && data.partner_name.charAt(0).toUpperCase() + data.partner_name.slice(1),
			// 			credits: data && data.partner_credits,
			// 			type: data && data.type,
			// 			email: data && data.email
			// 		}
			// 	});
			// 	return subagent;
			// }
		},
		totalBalance() {
			let partner_credits =
			this.partnerprofile && this.partnerprofile.partner_credits;
			let total = partner_credits;
			return total;
		},
		userBalance() {
			let balance = this.selectedUser
			? this.selectedUser.credits | this.formatMoney
			: "-";
			return balance;
		},
	},
	methods: {
		...mapActions("partner", [
		"partnerUsers",
		"getPartnerProfileData",
		"postAgentTransferTransaction",
		]),
		...mapActions("user", ["getUserInfo"]),
		...mapActions("admin", ["getPartnerSubPartners"]),
        ...mapActions("user", ["getSubUser"]),
		closeDialog() {
			this.$emit("closeDialog", true);
		},
		closeconfirmDialog(){
      this.isDisabled = false;
			this.confirmDialog = false;
		},
		async transferCashout() {
			this.$refs.form.validate();
			if (this.$refs.form.validate()) {
				const res = await this.getSubUser(this.$auth.user.id)
				await this.postAgentTransferTransaction({
					agent_id: this.selectPartnerUser,
					type: "cashout",
					amount: this.amount,
					partner_id: res.id,
					status: "completed",
					transaction_type: "direct",
					partner_comment : this.comment
				});
				if (this.agenttransfertransaction.updated) {
					this.closeDialog();
					const res = await this.getSubUser(this.$auth.user.id)
					await this.getPartnerProfileData({
						id: res.user_id,
					});
					await this.getUserInfo(res.user_id);
					await this.getPartnerSubPartners({id : res.id});
					this.$refs.form.reset();
				}
				this.alert_dialog = true;
				this.alert_success = this.agenttransfertransaction.updated;
				this.alert_message = !this.alert_success ? this.agenttransfertransaction.message : "CREDITS TRANSFERRED SUCCESSFULLY";
			}
		},
		closeAlertDialog() {
      this.isDisabled = false;
      this.alert_dialog = false;
      this.selectPartner = "";
		},
		formatNumber(value){
			return numeral(value).format('0,0.00')
		},
		 getFieldText(item) {
			return `${item.name} - ${item.email}`;
		},
		async searchPartner(e) {
			this.selectPartner = e.target.value;
			this.fetchDebounce();
		},
		async clearSearch() {
		const res = await this.getSubUser(this.$auth.user.id);
		await this.getPartnerSubPartners({
			id: res.id,
			type: "default",
			search: "",
		});
		},
		getInitial(type){
			if(type == 'partners')
			return 'MP';
			else if(type == 'area-manager')
			return 'AM';
			else if (type == "city-manager")
			return 'CM';
			else{
				return 'A';
			}
		},
		getColor(type){
			if(type == 'partners')
			return 'success';
			else if(type == 'area-manager')
			return 'warning';
			else if(type == "city-manager")
			return 'primary';
			else{
				return 'error';
			}
		},
		openConfirmation(){
			this.$refs.form.validate();
				if (this.$refs.form.validate()) {
				this.isDisabled = true;
				this.confirmDialog = true
			}
		},
		async cashoutData(){
			const res = await this.getSubUser(this.$auth.user.id)
			await this.getPartnerSubPartners({
				id : res.id,  
				type: "default",
				search: "",
			});
			await this.getPartnerProfileData({ id: res.user_id });
			await this.getUserInfo(res.user_id);
			this.partnerprofile = res
		}
	},
	async mounted() {
        // const res = await this.getSubUser(this.$auth.user.id)
        // await this.getPartnerSubPartners({id : res.id});
        // await this.getPartnerProfileData({ id: res.user_id });
        // await this.getUserInfo(res.user_id);
		if (this.viewDialog === "cashout") {
			console.log("Mounted with cashout tab active.");
			this.cashoutData();
		}
	},
	watch: {
		async selectPartnerUser(value) {
		let user =
			this.agentList && this.agentList.find((data) => data.id == value);
			this.selectedUser = user;
		},
		viewDialog(newVal) {
		console.log("newValnewValnewVal", newVal);
		if (this.transferMoneyAgentDialog && newVal === "cashout") {
			console.log("cashout tab active, fetching data.");
			this.cashoutData();
		}
		},
		transferMoneyAgentDialog(newVal) {
		console.log("transferMoneyAgentDialog changed:", newVal);
		if (newVal) {
			console.log("cashoutt Dialog is open.");
			if (this.viewDialog === "cashout") {
			console.log("Fetching cashout data...");
			this.cashoutData();
			}
		} else {
			console.log("cashout Dialog is closed, skipping data fetch.");
		}
		},
	},
	async created(){
		this.partnerprofile = await this.getSubUser(this.$auth.user.id);
	}
};
</script>
<style>
.text-field-amount .v-input__control .v-input__slot {
  background: #222f3a;
}
.cashout {
	background: #0d111b !important;
}
.transfer-cashout-input {
	background: #222f3a !important;
}
.transfer-cashout {
	background: #222f3a !important;
	border-radius: 10px !important;
}
.transfer-cashout-text {
	font-family: "Inter", sans-serif !important;
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 14px !important;
	line-height: 17px !important;
	/* identical to box height */
	
	text-align: center !important;
	
	color: #ffffff !important;
}
</style>
<style scoped>
.card {
	border-radius: 25px !important;
	background-color: #0d111b !important;
}
.dialog-btn {
	width: 100px;
	height: 36px;
}
.v-select-list { 
  margin-top: 5px;
  background: #141c2e !important;
  border: 1px solid #d1d5db !important;
  border-radius: 6px !important;
}
</style>