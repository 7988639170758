const actions = {
  async getUsers({ commit }, payload) {
    try {
      const limit = payload && (payload.limit || 10);
      const search = payload && (payload.search || "");
      const page = payload && (payload.page || 1);
      const sort = payload && (payload.sort || false);
      const sortType = payload && (payload.sortType || "id");
      const userType = payload && (payload.userType || "user");
      const type = payload && (payload.type || 'admin');
      const res = await this._vm.$gameApi.get("/admin/users", {
        params: {
          search,
          limit,
          page,
          sort,
          sortType,
          userType,
          type
        },
      });
      commit("GET_USERS", res.data);
    } catch (error) {
      return error;
    }
  },
  async getUserInfo({}, payload) {
    try {
      const res = await this._vm.$gameApi.get(`/user/account/info/${payload}`, payload);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async banUser({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/users/ban/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async unbanUser({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/users/unban/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async setVoucherCode({}, payload) {
    try {
      return await this._vm.$gameApi.post(`/admin/users/voucher-code/${payload.id}`, payload);
    } catch (err) {}
  },
  async updateVoucherCode({}, payload) {
    try {
      return await this._vm.$gameApi.post(`/admin/affiliate/update/${payload.id}`, payload);
    } catch (err) {}
  },
  async getUserTransactions({}, payload) {
    try {
      const limit = payload && (payload.limit || 10);
      const search = payload && (payload.search || "");
      const page = payload && (payload.page || 1);
      const res = await this._vm.$gameApi.get(`/admin/audit/users/${payload.type}/${payload.id}`, {
        params: {
          search,
          limit,
          page,
        },
      });
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async getUserBets({}, payload) {
    console.log("commmmmmmmmm", payload);
    try {
      const res = await this._vm.$gameApi.get(`/admin/audit/users/bets/${payload.id}`, {
        params: {
          page: payload.page,
          limit: payload.limit,
          search: payload.search,
          type: payload.type,
        },
      });
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async addGiftCode({}, payload) {
    try {
      const res = await this._vm.$gameApi.post("/admin/add/gift-code", payload);

      return res.data;
    } catch (error) {
      return false;
    }
  },
  async getAdminPartnerDepositTransaction({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get("admin/partners/transactions", {
          params: {
            page: payload.page,
            limit: payload.limit,
            search: payload.search,
            id: payload.id,
            type: "deposit",
            status: payload.status,
            sort: payload.sort,
            sortType: payload && (payload.sortType || "id"),
          },
        });
        const { data } = response;

        commit("GET_ADMIN_PARTNER_DEPOSIT_TRANSACTIONS", data);
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getAdminPartnerCashoutTransaction({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get("admin/partners/transactions", {
          params: {
            page: payload.page,
            limit: payload.limit,
            search: payload.search,
            id: payload.id,
            type: "cashout",
            status: payload.status,
            sort: payload.sort,
            sortType: payload && (payload.sortType || "id"),
          },
        });
        const { data } = response;
        commit("GET_ADMIN_PARTNER_CASHOUT_TRANSACTIONS", data);
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getAdminUsersDepositTransaction({ commit }, payload) {
    try {
      console.log("deposit search", payload);
      if (payload) {
        const response = await this._vm.$gameApi.get("admin/users/transactions", {
          params: {
            page: payload.page,
            limit: payload.limit,
            search: payload.search,
            id: payload.id,
            type: "deposit",
            status: payload.status,
            sort: payload.sort,
            sortType: payload && (payload.sortType || "id"),
          },
        });
        const { data } = response;

        commit("GET_ADMIN_USERS_DEPOSIT_TRANSACTIONS", data);
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getAdminUsersCashoutTransaction({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get("admin/users/transactions", {
          params: {
            page: payload.page,
            limit: payload.limit,
            search: payload.search,
            id: payload.id,
            type: "cashout",
            status: payload.status,
            sort: payload.sort,
            sortType: payload && (payload.sortType || "id"),
          },
        });
        const { data } = response;
        commit("GET_ADMIN_USERS_CASHOUT_TRANSACTIONS", data);
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async approveRequest({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/transactions/approve/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return false;
    }
  },
  async getAllGiftCodes({}, payload) {
    try {
      const limit = payload && (payload.limit || 10);
      const search = payload && (payload.search || "");
      const page = payload && (payload.page || 1);
      const status = payload && (payload.status || "");
      const sort = payload && (payload.sort || false);
      const sortType = payload && (payload.sortType || "id");
      const res = await this._vm.$gameApi.get("/admin/gift-code", {
        params: {
          search,
          limit,
          page,
          status,
          sortType,
          sort,
        },
      });
      if (!!search) {
        return {
          count: res.data.length,
          rows: res.data,
        };
      } else {
        return res.data;
      }
    } catch (error) {
      return error;
    }
  },
  async deleteGiftCode({}, payload) {
    try {
      const res = await this._vm.$gameApi.post("/admin/delete/gift-code", payload);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async rejectRequest({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/transactions/reject/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return false;
    }
  },
  async getTransactionDiscrepancies({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get(`/admin/users/transaction-discrepancies/${payload.id}`, {
          params: {
            page: payload.page,
            limit: payload.limit,
            search: payload.search,
            id: payload.id,
          },
        });
        const { data } = response;
        commit("GET_TRANSACTION_DISCREPANCIES", data);
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async approveDiscrepancy({}, payload) {
    try {
      console.log("axasdcsac", payload);
      const res = await this._vm.$gameApi.post(`/admin/transactions/discrepancy/${payload.id}`, {
        amount: payload.amount,
        mop: JSON.stringify(payload.mop),
        message: payload.message,
      });
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async updateDiscrepancy({}, payload) {
    try {
      console.log("axasdcsac", payload);
      const res = await this._vm.$gameApi.post(`/admin/transactions/update-discrepancy/${payload.id}`, {
        amount: payload.amount,
        mop: JSON.stringify(payload.mop),
        message: payload.message,
      });
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async addSiteSettings({}, payload) {
    try {
      const res = await this._vm.$gameApi.post("/admin/add/site-settings", payload);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async updateSiteSettings({}, payload) {
    try {
      const res = await this._vm.$gameApi.post("/admin/update/site-settings", payload);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async updateSiteSettingsById({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/update/site-settings/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async getSiteSettings({ commit }, payload) {
    try {
      const limit = payload && (payload.limit || 10);
      const search = payload && (payload.search || "");
      const page = payload && (payload.page || 1);
      const status = payload.status;
      const res = await this._vm.$gameApi.get("/user/banks/site-settings", {
        params: {
          search,
          limit,
          page,
          status,
        },
      });
      commit("SET_SITE_SETTINGS", res.data);
    } catch (error) {
      return error;
    }
  },
  async assignAsPartner({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/assign/partner/${payload.user_id}`, payload);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async assignAsAdmin({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/users/${payload.id}`, payload);
      return res.data;
    } catch (err) {}
  },
  async deactiveAsPartner({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/update/partner/${payload.id}`, {
        active: payload.status,
      });
      return res.data;
    } catch (err) {}
  },
  async activeAsPartner({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/update/partner/${payload.id}`, {
        active: payload.status,
        partner_name: payload.partner_name,
      });
      return res.data;
    } catch (err) {}
  },
  async createConfig({}, payload) {
    try {
      const res = await this._vm.$gameApi.post("/admin/config", payload);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async getConfig({}, payload) {
    try {
      const search = payload && (payload.search || "");
      const res = await this._vm.$gameApi.get("/admin/config", {
        params: {
          search,
        },
      });
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async updateConfig({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/config/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async getAllBadges({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.get("/admin/badges/", {
        params: {
          page: payload.page,
          limit: payload.limit,
          sort: payload.sort,
          sortType: payload && (payload.sortType || "id"),
        },
      });
      commit("GET_BADGES", res.data);
    } catch (error) {
      return error;
    }
  },
  async createBadge({}, payload) {
    try {
      const res = await this._vm.$gameApi.post("/admin/badges/", payload);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async updateBadge({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/update/badge/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async addUserBadge({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/insert/user-badges/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async updateUser({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/users/${payload.id}`, payload);
      return res.data;
    } catch (err) {}
  },
  async rejectPartnerRequest({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/partner-transactions/reject/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return false;
    }
  },
  async approvePartnerRequest({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/partner-transactions/approve/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return false;
    }
  },
  async adminPartnerList({ commit }, payload) {
    console.log("sabrina", payload);
    try {
      if (payload) {
        const sort = payload && (payload.sort || false);
        const sortType = payload && (payload.sortType || "id");
        const response = await this._vm.$gameApi.post(
          `/admin/partners?page=${payload.page}&limit=${payload.limit}&search=${payload.search}&status=${payload.status}&type=${payload.type}&searchType=${payload.searchType}&sort=${sort}&sortType=${sortType}`,
          {
            date: payload.date,
          }
        );
        const { data } = response;
        commit("GET_ADMIN_PARTNER", data);
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getPartnerAffiliates({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get(`/admin/partners/affiliates/${payload.id}`);
        const { data } = response;
        commit("GET_PARTNER_AFFILIATES", data);
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getPartnerSubusers({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get(`/admin/partners/sub-users/${payload.id}`);
        const { data } = response;
        commit("GET_PARTNER_SUBUSERS", data);
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },

  async getPartnerSubPartners({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get(`/admin/partners/sub-partners/${payload.id}`, {
          params: {
            type: "default",
            search: !!payload.search ? payload.search : "",
          },
        });
        const { data } = response;
        commit("GET_PARTNER_SUBPARTNERS", data);
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async postPartnerAffiliates({}, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.post(`/admin/partners/add-affiliates/${payload.id}`, {
          ids: payload.ids,
        });
        console.log("error", response);

        const { data } = response;
        return data;
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async postPartnerSubusers({}, payload) {
    try {
      console.log("error", payload);

      if (payload) {
        const response = await this._vm.$gameApi.post(`/admin/partners/add-subusers/${payload.id}`, {
          ids: payload.ids,
          type: payload.type,
        });

        const { data } = response;
        return data;
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async postPartnerSubPartners({}, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.post(`/admin/partners/add-subagents/${payload.id}`, {
          ids: payload.ids,
        });
        console.log("error", response);

        const { data } = response;
        return data;
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getAdminPartnerAdminDepositTransaction({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get("admin/partner-transactions", {
          params: {
            page: payload.page,
            limit: payload.limit,
            search: payload.search,
            type: "deposit",
            status: payload.status,
            sort: payload.sort,
            sortType: payload && (payload.sortType || "id"),
          },
        });
        const { data } = response;

        commit("GET_ADMIN_PARTNER_ADMIN_DEPOSIT_TRANSACTIONS", data);
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getAdminPartnerAdminCashoutTransaction({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get("admin/partner-transactions", {
          params: {
            page: payload.page,
            limit: payload.limit,
            search: payload.search,
            type: "cashout",
            status: payload.status,
            sort: payload.sort,
            sortType: payload && (payload.sortType || "id"),
          },
        });
        const { data } = response;

        commit("GET_ADMIN_PARTNER_ADMIN_CASHOUT_TRANSACTIONS", data);
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async postDeactivePartner({}, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.post(`/admin/update/partner/${payload.id}`, payload);

        const { data } = response;

        return data;
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async postVisiblePartner({}, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.post(`/admin/update/partner/${payload.id}`, payload);

        const { data } = response;

        return data;
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async postAllowedUserPrevious({}, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.post(`/admin/update/partner/${payload.id}`, payload);

        const { data } = response;

        return data;
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getDownloadTransaction({}, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.post(`/admin/partner-transactions/download/${payload.id}`, payload);

        const { data } = response;

        return data;
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getAuditUser({}, payload) {
    try {
      const response = await this._vm.$gameApi.post(`/admin/audit/users/formula/${payload.id}`);
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  },
  async getAllVoucherCodes({}, payload) {
    try {
      console.log("payloadzxcz", payload.status);
      const limit = payload && (payload.limit || 10);
      const search = payload && (payload.search || "");
      const page = payload && (payload.page || 1);
      const status = payload && payload.status;
      const sort = payload && (payload.sort || false);
      const sortType = payload && (payload.sortType || "id");
      const res = await this._vm.$gameApi.get("/admin/e-voucher-code", {
        params: {
          search,
          limit,
          page,
          status,
          sort,
          sortType,
        },
      });
      if (!!search) {
        return {
          count: res.data.length,
          rows: res.data,
        };
      } else {
        return res.data;
      }
    } catch (error) {
      return error;
    }
  },
  async addVoucherCode({}, payload) {
    try {
      console.log("payload voucher", payload);
      const res = await this._vm.$gameApi.post("/admin/add/e-voucher-code", payload);
      return res.data;
    } catch (error) {
      return false;
    }
  },
  async deleteVoucherCode({}, payload) {
    try {
      const res = await this._vm.$gameApi.post("/admin/delete/e-voucher-code", payload);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async updateToPaidEVoucher({}, payload = {}) {
    try {
      const res = await this._vm.$gameApi.post("/admin/update-to-paid/e-voucher-code", {
        id: payload.id,
      });
      if (res.data.success) {
        return res.data;
      }
    } catch (error) {
      return {
        success: false,
        message: error,
      };
    }
    return {
      success: false,
    };
  },
  async getEvoucherTransaction({}, payload) {
    try {
      const limit = payload && (payload.limit || 10);
      const search = payload && (payload.search || "");
      const page = payload && (payload.page || 1);
      const sort = payload.sort;
      const sortType = payload && (payload.sortType || "id");
      const res = await this._vm.$gameApi.get("/admin/users/e-voucher-code", {
        params: {
          search,
          limit,
          page,
          sort,
          sortType,
        },
      });
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async getEvoucherAuditTransaction({}, payload) {
    try {
      const limit = payload && (payload.limit || 10);
      const search = payload && (payload.search || "");
      const page = payload && (payload.page || 1);
      const res = await this._vm.$gameApi.get(`/user/voucher-code/deposit/${payload.id}`, {
        params: {
          search,
          limit,
          page,
        },
      });
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async getPartnerPayout({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/partners/payout-data/${payload.id}`, {
        date: payload.date,
      });
      const { data } = res;
      commit("GET_PARTNER_PAYOUT_ADMIN", data);
    } catch (error) {
      console.log("error", error);
      return error;
    }
  },
  async generatePartnerPayout({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/partner/add/payout`, {
        payload,
      });
      const { data } = res;
      commit("SET_GENERATE_PARTNER_PAYOUT", data);
    } catch (error) {
      console.log("error", error);
      return error;
    }
  },
  async adminAffiliateList({ commit }, payload) {
    try {
      console.log("payloaddddd", payload);
      const sort = payload && (payload.sort || false);
      const sortType = payload && (payload.sortType || "id");
      const res = await this._vm.$gameApi.post(
        `/admin/affiliates?page=${payload.page}&limit=${payload.limit}&search=${payload.search}&sort=${sort}&sortType=${sortType}`,
        {
          date: payload.date,
        }
      );
      const { data } = res;
      commit("GET_ADMIN_AFFILIATE", data);
    } catch (error) {
      console.log("error", error);
      return error;
    }
  },
  async getAffiliatePayoutData({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/affiliates/payout-data/${payload.id}`, {
        date: payload.date,
      });
      const { data } = res;
      commit("GET_AFFILIATE_PAYOUT_DATA", data);
    } catch (error) {
      console.log("error", error);
      return error;
    }
  },
  async generateAffiliatePayout({ commit }, payload) {
    try {
      console.log("PAYLOAD", payload);
      const res = await this._vm.$gameApi.post(`/admin/affiliate/add/payout`, payload);
      const { data } = res;
      commit("SET_GENERATE_AFFILIATE_PAYOUT", data);
    } catch (error) {
      console.log("error", error);
      return error;
    }
  },
  async getAllPartnerPayout({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.get("admin/partners/payouts", {
        params: {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          type: payload.type,
          sort: payload.sort,
          sortType: payload && (payload.sortType || "id"),
          startDate: payload && (payload.date.startDate || ""),
          endDate: payload && (payload.date.endDate || ""),
        },
      });
      const { data } = res;
      commit("SET_ALL_PARTNER_PAYOUT", data);
    } catch (error) {
      console.log("error", error);
      return error;
    }
  },
  async getPartnersReportList({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`admin/partners/sub-partners-tree/${payload.id}`, payload);
      return res.data.data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  },
  async getPartnersReportListV2({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(
        `admin/partners/sub-partners-tree-v2/${payload.id}?offset=${payload.offset}&limit=${payload.limit}`,
        payload
      );
      return res.data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  },
  async generatePartnerPayoutBulk({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/check/payouts-bulk?payout=false&type=payout`, {
        date: payload.date,
      });
      const { data } = res;
      console.log("xxxxxxxxxxx dataaaaaaaa", data);
      commit("GET_GENEREATE_PAYOUT_BULK_ADMIN", data);
    } catch (error) {
      console.log("error", error);
      return error;
    }
  },
  async getPartnerPayoutBulk({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/create/payouts-bulk`, {
        date: payload.date,
        data: payload.data,
      });
      const { data } = res;
      commit("GET_PARTNER_PAYOUT_BULK_ADMIN", data);
    } catch (error) {
      console.log("error", error);
      return error;
    }
  },
  async getDownloadExcelPayoutBulk({ commit }, payload) {
    try {
      console.log("payloadddd jamesssssssssssssss", payload);
      const res = await this._vm.$gameApi.post(`/admin/generate/excel-payouts?type=${payload.type}`, {
        date: payload.date,
      });
      console.log("reymonddddddddddddddddddddddddddddddddddddd", res);
      const { data } = res;
      return data;
      // commit("GET_DOWNLOAD_EXCEL_PAYOUT_BULK_ADMIN", data)
    } catch (error) {
      console.log("error", error);
      return error;
    }
  },
  async getAffiliatePayout({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.get("admin/affiliates/payouts", {
        params: {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          sort: payload.sort,
          sortType: payload && (payload.sortType || "id"),
          startDate: payload && (payload.date.startDate || ""),
          endDate: payload && (payload.date.endDate || ""),
        },
      });
      const { data } = res;
      commit("GET_AFFILIATE_PAYOUT", data);
    } catch (error) {
      console.log("error", error);
      return error;
    }
  },
  async getMarketingUsers({ commit }, payload) {
    console.log("payyyyy", payload);
    try {
      const res = await this._vm.$gameApi.get("admin/marketing-users", {
        params: {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          sort: payload.sort,
          sortType: payload && (payload.sortType || "id"),
        },
      });

      const { data } = res;
      commit("GET_MARKETING_USERS", data);
    } catch (error) {
      console.log("error", error);
      return error;
    }
  },
  async removeMarketingUsers({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/remove/marketing-user/${payload}`);
      const { data } = res;
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  },
  async checkPartnerPayout({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/partner/payout/checker/${payload.partner_id}`, {
        date: payload.date,
        user_id: payload.user_id,
      });
      const { data } = res;
      return data;
    } catch (error) {
      return error;
    }
  },
  async getPartnerSheets({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.post(
        `/admin/partnersheet?search=${payload.search}&page=${payload.page}&limit=${payload.limit}`
      );
      const { data } = res;

      commit("GET_GOOGLE_SHEETS", data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  },
  async createPartnerSheet({}, payload) {
    try {
      const res = await this._vm.$gameApi.post("/admin/partnersheet/add-partnersheet", payload);
      const { data } = res;
      return data;
      // const res = await this._vm.$gameApi.post('/admin/add/e-voucher-code', payload);
      // return res.data;
    } catch (error) {
      return error;
    }
  },
  async createPartnerVoucher({}, payload) {
    try {
      const res = await this._vm.$gameApi.post("/admin/partnersheet/add-partner-voucher", payload);
      const { data } = res;
      return data;
    } catch (error) {
      return error;
    }
  },
  async adminAffiliateCommissionList({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.post("/admin/affiliates/commissions", {
        date: payload.date,
      });
      const { data } = res;

      commit("GET_AFFILIATE_COMMISSIONS_LIST", data);
    } catch (error) {
      return error;
    }
  },
  async findMarketingCredits({}, payload) {
    try {
      const res = await this._vm.$gameApi.post("/admin/marketing-users/find-marketing-credits", {
        marketing_credits: payload,
      });
      const { data } = res;
      return data;
    } catch (error) {
      return error;
    }
  },
  async checkMarketingCreditsClaimStatus({}, payload) {
    try {
      const res = await this._vm.$gameApi.post("/admin/marketing-users/check-claim-status", {
        user_id: payload,
      });
      console.log("FIND MC CODE", res);
      const { data } = res;
      return data;
    } catch (error) {
      return error;
    }
  },
  async setUserCashoutStatus({}, payload) {
    try {
      const res = await this._vm.$gameApi.post("/admin/marketing-users/set-cashout", {
        user_id: payload.user_id,
      });
      console.log("CASHOU STATUS RESPONSE", res);
      const { data } = res;
      return data;
    } catch (error) {
      return error;
    }
  },
  async getManualAdjustment({}, payload) {
    try {
      const res = await this._vm.$gameApi.post("/generate/manual-adjustments", payload);
      const { data } = res;
      return data;
    } catch (error) {
      return error;
    }
  },
  async getAllAdminActions({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.get(
        `/admin/admin-action?page=${payload.page}&limit=${payload.limit}&search=${payload.search}`,
        payload
      );
      const { data } = res.data;
      commit("GET_ALL_ADMIN_ACTIONS_LIST", data);
    } catch (error) {
      return error;
    }
  },
  async getAllAdminAndSuperAdmin({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.get(
        `/admin/admin-action/admin-list?offset=${payload.offset}&limit=${payload.limit}&search=${payload.search}`,
        payload
      );
      const { data } = res;
      commit("GET_ALL_ADMIN_SUPER_ADMIN_LIST", data);
    } catch (error) {
      return error;
    }
  },
  async getMoreAdminAndSuperAdmin({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.get(
        `/admin/admin-action/admin-list?offset=${payload.offset}&limit=${payload.limit}&search=${payload.search}`,
        payload
      );
      const { data } = res.data;
      return data;
    } catch (error) {
      return error;
    }
  },
  async assignAdminAction({}, payload) {
    try {
      const res = await this._vm.$gameApi.post("/admin/assign-admin-action", payload);
      const { data } = res;
      return data;
    } catch (error) {
      return error;
    }
  },
  async updateAdminAction({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/update-admin-action/${payload.id}`, payload);
      const { data } = res;
      return data;
    } catch (error) {
      return error;
    }
  },
  async deleteAdminAction({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/delete-admin-action/${payload.id}`, payload);
      const { data } = res;
      return data;
    } catch (error) {
      return error;
    }
  },
  async getPartnerApprovalList({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.get(
        `/admin/partners-approval-list?page=${payload.page}&limit=${payload.limit}&search=${payload.search}&filter=${payload.filter}`,
        payload
      );
      const { data } = res;
      commit("GET_PARTNER_APPROVAL_LIST", data);
    } catch (error) {
      return error;
    }
  },
  async postNewPartnerApproval({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/new-agent-approval/${payload.id}`, payload);
      const { data } = res;
      return data;
    } catch (error) {
      return false;
    }
  },
  async getUserTransactionsFromPartner({}, payload) {
    try {
      const limit = payload && (payload.limit || 10);
      const search = payload && (payload.search || "");
      const page = payload && (payload.page || 1);
      const res = await this._vm.$gameApi.get(`/admin/audit/partner-users/${payload.type}/${payload.id}`, {
        params: {
          search,
          limit,
          page,
        },
      });
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async getPartnerPayoutReport({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.post(
        `/admin/partner-payout-report?page=${payload.page}&limit=${payload.limit}&search=${payload.search}`,
        payload
      );
      const { data } = res;

      commit("GET_PARTNER_PAYOUT_LIST", data);
    } catch (error) {
      return error;
    }
  },
  async getPartnerNameList({}, payload) {
    try {
      const res = await this._vm.$gameApi.get("/admin/partners-list", payload);
      const { data } = res;

      return data;
    } catch (error) {
      return error;
    }
  },

  async generateReport({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`/admin/generate/excel-payouts?type=${payload.type}`, payload);
      const { data } = res;

      return data;
    } catch (error) {
      return error;
    }
  },

  async getTournamentBets({ commit }, payload = {}) {
    try {
      const { page = 1, limit = 10, search = "", date: { startDate = "", endDate = "" } = {} } = payload;

      const params = new URLSearchParams({ page, limit, search, startDate, endDate }).toString();

      const res = await this._vm.$gameApi.get(`/admin/tournament-bets?${params}`);
      const { data } = res;

      commit("GET_TOURNAMENT_BETS", data);
    } catch (error) {
      console.error("Error fetching tournament bets:", error);
      throw error;
    }
  },

  async setMarketingUser({}, payload = {}) {
    try {
      console.log("payloaaad?", payload);
      const res = await this._vm.$gameApi.post(`/admin/marketing-users/set-user/${payload.id}`);

      return res.data;
    } catch (error) {
      console.error("Error setting marketing user:", error);
      throw error;
    }
  },

  async setBlockCasinoBet({}, payload = {}) {
    try {
      console.log("payloaaad?", payload);
      const res = await this._vm.$gameApi.post(`/admin/marketing-users/block-casino-bet/${payload.id}`, payload);

      return res.data;
    } catch (error) {
      console.error("Error setting block casino bet user:", error);
      throw error;
    }
  },
  async getUserSportsbookBets({}, payload) {
    console.log("commmmmmmmmm", payload);
    try {
      const queryString = `page=${payload.page}&limit=${payload.limit}&search=${encodeURIComponent(
        payload.search
      )}&filter=[${payload.type.join(",")}]
      &string_params=[${payload.string_params.map(encodeURIComponent).join(",")}]`;

      const res = await this._vm.$gameApi.get(`admin/audit/users/bets/new/${payload.id}?${queryString}`);
      return res.data;
    } catch (error) {
      return error;
    }
  },

  async getUserSportsbookComboBets({}, payload) {
    try {
      const res = await this._vm.$gameApi.get(`admin/tournament-bets/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return error;
    }
  },

  async getUserCreditLog({ commit }, payload) {
    console.log("payloadadsfdsaf", payload);
    try {
      const res = await this._vm.$gameApi.post(
        `admin/user-credit-logs/${payload.id}?limit=${payload.limit}&search=${payload.search}&search_value=${payload.searchValue}`,
        {
          date: payload.date,
          filters: payload.filters,
        }
      );
      const { data } = res;
      console.log("data yoyoyooy", data);
      commit("GET_USERCREDIT_LOG", data);
      // return res.data;
    } catch (error) {
      return error;
    }
  },
  async assignDirectUsertoAgent({}, payload) {
    try {
      console.log(payload);
      const res = await this._vm.$gameApi.post("/admin/add-agent", payload);
      const { data } = res;
      return data;
    } catch (error) {
      return error;
    }
  },
  async getDirectAgents({ commit }, payload) {
    try {
      const res = await this._vm.$gameApi.post(
        `/admin/direct-agents?page=${payload.page}&limit=${payload.limit}&search=${payload.search}&status=${payload.status}&searchType=${payload.searchType}&sort=${payload.sort}&sortType=${payload.sortType}`,
        { date: payload.date }
      );
      const { data } = res;

      commit("GET_DIRECT_AGENT_LIST", data);
    } catch (error) {
      return error;
    }
  },
  async getAdminDirectAgentCashoutTransaction({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get("admin/direct-agent/transactions", {
          params: {
            page: payload.page,
            limit: payload.limit,
            search: payload.search,
            type: "cashout",
            status: payload.status,
            sort: payload.sort,
            sortType: payload && (payload.sortType || "id"),
          },
        });
        const { data } = response;

        commit("GET_ADMIN_DIRECT_AGENT_CASHOUT_TRANSACTIONS", data);
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getAdminDirectAgentDepositTransaction({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$gameApi.get("admin/direct-agent/transactions", {
          params: {
            page: payload.page,
            limit: payload.limit,
            search: payload.search,
            type: "deposit",
            status: payload.status,
            sort: payload.sort,
            sortType: payload && (payload.sortType || "id"),
          },
        });
        const { data } = response;

        commit("GET_ADMIN_DIRECT_AGENT_DEPOSIT_TRANSACTIONS", data);
      }
    } catch (er) {
      console.log("error", er);
      commit("SET_ERROR", er);
    }
  },
  async getAuditSportsbookByBetslip({}, payload) {
    try {
      const res = await this._vm.$gameApi.post(`admin/tournament-bets-ids/${payload.id}`, {
        ids: payload.ids,
      });
      return res.data;
    } catch (error) {
      return error;
    }
  },
  async postAllowedUserCashoutAndBet({}, payload){
    try{
      const res = await this._vm.$gameApi.post(`admin/hold-user-actions/${payload.id}`,{
        type: payload.type
      })
      return res.data;
    } catch(error){
      return error;
    }
  }, 
  
  async getAllNegativeCreditReport({commit}, payload){
    console.log("payladfadsf",payload)
    try{
      const response  = await this._vm.$gameApi.get("/admin/risk-users",
        {
          params: {
            page: payload.page,
            limit: payload.limit,
            search: payload.search,
            type: payload.type
          },
        }
      )
      const { data } = response;
      commit("GET_USER_ALL_NEGATIVE_CREDIT_REPORTS",data)
    } catch(error){
      return error;
    }
  },

  async getNegativeCreditReport({commit},payload){
    try{
      const response = await this._vm.$gameApi.get(`/admin/risk/user/${payload.id}`,{
        params: {
          page: payload.page,
          search: payload.search,
          limit: payload.limit
        }
      })
      const { data } = response;
      commit("GET_USER_DATA_NEGATIVE_REPORTS",data)
    }catch(error){
      return error;
    }
  }
};
export default actions;
