const actions = {
    async getSiteSettings({ commit }) {
      try {
          const response = await this._vm.$gameApi.get("/user/banks/site-settings",{
            params:{
              status : 1,
              search : "",
              limit: "",
              page: "",
            }
          });
          const { data } = response;
          commit("GET_SITE_SETTINGS", data);
      } catch (er) {
        console.log("error", er);
        commit("SET_ERROR", er);
      }
    },
    async directCashout({ commit }, payload) {
      try {
        console.log('direct cashout',payload);
        const response = await this._vm.$gameApi.post("/user/cashout",{
          amount: payload.amount,
          type: payload.type,
          data: JSON.stringify(payload.data),
          user_id: payload.user_id,
        });
        const { data } = response;
        console.log("cashout", data);
  
        commit("DIRECT_CASHOUT", data);
        return true;
      } catch (error) {
        console.log("error", error);
        commit("SET_ERROR", error);
        return false;
      }
    },
    async directDeposit({ commit }, payload) {
      try {
        const response = await this._vm.$gameApi.post("/user/deposit",{
          amount: payload.amount,
          code: payload.code,
          user_id: payload.user_id,
        });
        console.log('direct deposit response',response);
        const { data } = response;
        console.log("deposit", data);
  
        commit("DIRECT_DEPOSIT", data);
        return true;
      } catch (error) {
        console.log("error", error);
        commit("SET_ERROR", error);
        return false;
      }
    },
    async getDirectDepositTransactions({ commit }, payload) {
      try {

        if (payload) {
          const response = await this._vm.$gameApi.get(
            `/user/deposit/${payload.id}`,{
              params:{
                page: payload.page,
                limit: payload.limit,
                search: payload.search,
              }
            }
          );

          const { data } = response;

          commit("GET_DEPOSIT_DIRECT_TRANSACTIONS", data);
        }
      } catch (er) {
        console.log("error", er);
        commit("SET_ERROR", er);
      }
    },
    async getDirectCashoutTransactions({ commit }, payload) {
      try {
        if (payload) {
          const response = await this._vm.$gameApi.get(
            `/user/cashout/${payload.id}`,{
              params:{
                page: payload.page,
                limit: payload.limit,
                search: payload.search,
              }
            }
          );
          const { data } = response;
          commit("GET_CASHOUT_DIRECT_TRANSACTIONS", data); 
        }
      } catch (er) {
        console.log("error", er);
        commit("SET_ERROR", er);
      }
    },

    async getVoucherDepositTransactions({commit}, payload){
      try{
        if(payload){
          const response = await this._vm.$gameApi.get(
            `/user/voucher-code/deposit/${payload.id}`,{
              params:{
                page: payload.page,
                limit: payload.limit,
                search: payload.search
              }
            });
            const { data } = response;
            commit("GET_VOUCHER_DEPOSIT_TRANSACTIONS", data);
        }
      } catch (error) {
        console.log("error",error)
        commit("SET_ERROR", error)
      }
    }
  };
  export default actions;
  