<template>
  <v-dialog
    v-model="signup_dialog"
    width="450"
    overlay-color="rgb(0, 0, 0)"
    overlay-opacity=".85"
    transition="slide-x-reverse-transition"
    style="z-index: 4000"
  >
    <div>
      <v-card
        style="background: linear-gradient(140.61deg, #0b1638 0%, #39351a 100%)"
        class="rounded-lg overflow-x-hidden overflow-y-hidden py-5"
      >
        <v-row class="mt-5">
          <v-col class="d-flex justify-center">
            <div>
              <v-img
                width="300"
                :src="require('@/assets/images/betgame-gg.svg')"
              ></v-img>
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-7">
          <v-col cols="5" class="d-flex justify-center">
            <div
              class="font-weight-bold px-2"
              style="border-bottom: 2px solid #d28945; color: #d28945"
            >
              Sign Up
            </div>
          </v-col>
          <v-col cols="5" class="d-flex justify-center">
            <a @click="login">
              <div class="font-weight-bold" style="color: gray">Login</div>
            </a>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center my-5">
          <v-col cols="10" class="pb-0">
            <div
              v-if="error"
              class="text-caption text-start mb-1"
              style="color: red"
            >
              {{ error_message }}
            </div>
            <form @submit.prevent="signup">
              <v-text-field
                v-model="name"
                hide-details
                solo
                background-color="rgba(69, 137, 210, 0.75)"
                class="mb-2 rounded-lg"
                placeholder="Enter Name"
              ></v-text-field>
              <v-text-field
                v-model="email"
                hide-details
                solo
                background-color="rgba(69, 137, 210, 0.75)"
                class="mb-2 rounded-lg"
                placeholder="Enter Email"
              ></v-text-field>
              <v-text-field
                type="password"
                v-model="password"
                hide-details
                solo
                background-color="rgba(69, 137, 210, 0.75)"
                class="mb-2 rounded-lg"
                placeholder="Enter Password"
              ></v-text-field>
              <v-text-field
                type="password"
                v-model="password_confirmation"
                hide-details
                solo
                background-color="rgba(69, 137, 210, 0.75)"
                class="mb-2 rounded-lg"
                placeholder="Confirm Password"
              ></v-text-field>
              <v-text-field
                v-if="!partner_link"
                :readonly="!!$route.query.voucher"
                v-model="voucher_code"
                hide-details
                solo
                background-color="rgba(69, 137, 210, 0.75)"
                class="mb-2 rounded-lg"
                placeholder="Voucher Code"
              ></v-text-field>
              <v-text-field
                v-if="partner_link"
                disable
                readonly
                v-model="registered_partner"
                hide-details
                solo
                background-color="rgba(69, 137, 210, 0.75)"
                class="mb-2 rounded-lg"
                placeholder="Voucher Code"
              ></v-text-field>
              <div class="tw-flex tw-justify-start tw-pl-2">
                <span class="tw-text-red-600 tw-text-[13px]">
                  {{ affiliate_validation_message }}
                </span>
              </div>
              <v-btn
                :disabled="isDisable"
                type="submit"
                block
                rounded
                large
                color="#D28945"
                class="mt-5"
                >Sign Up</v-btn
              >
            </form>
          </v-col>
        </v-row>
        <!-- <v-row class="d-flex justify-center pt-1">
          <v-col cols="10">
            <v-row class="d-flex justify-center align-center">
              <v-col cols="5" class="pr-0">
                <div style="border: 1px solid gray"></div>
              </v-col>
              <v-col cols="2" class="pa-0">
                <div class="font-weight-bold">OR</div>
              </v-col>
              <v-col cols="5" class="pl-0">
                <div style="border: 1px solid gray"></div>
              </v-col>
            </v-row>
          </v-col>
        </v-row> -->
        <!-- <v-row class="d-flex justify-center mb-5">
          <v-col cols="10" class="pt-1">
             <v-facebook-login v-if="fbAppId" v-model="facebook.model" class="fbBtn" :app-id="fbAppId" :login-options="fbScope" :options="fbOptions" @connect="handleConnect" @sdk-init="handleSdkInit" @click="handleClick" @login="loggedInFb" @logout="handleLogout">
              <span slot="login">Signup with facebook</span>
            </v-facebook-login>
          </v-col>
        </v-row> -->
      </v-card>
      <Alert
        :alert_dialog="alert_dialog"
        :alert_success="alert_success"
        :alert_message="alert_message"
        @closeAlertDialog="closeAlertDialog"
      />
    </div>
  </v-dialog>
</template>
<script>
// import VFacebookLogin from "vue-facebook-login-component";
import Alert from "../auth/modal/Alert.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { publicIpv4 } from "public-ip";
import { useDebounceFn } from "@vueuse/core";
export default {
  data() {
    return {
      signup_dialog: false,
      error: false,
      error_message: "",
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      voucher_code: "",
      fbAppId: process.env.VUE_APP_FACEBOOK_APP_ID,
      facebook: {
        model: {},
        auth: {},
        connected: false,
      },
      fbScope: {
        scope: "email, public_profile",
      },
      fbOptions: {
        status: true,
      },
      FB: {},
      model: {},
      scope: {},
      fbLoading: false,
      partner_link: "",
      registered_partner: "",
      alert_dialog: false,
      alert_success: "",
      alert_message: "",
      ip_address: "",
      affiliate_validation_message: "",
      isDisable: false,
      debouncedName: useDebounceFn(async (voucher_code) => {
        if (voucher_code) {
          const check_affiliate_voucher = await this.affiliateVoucherValidation(
            voucher_code
          );
          if (check_affiliate_voucher.success) {
            this.affiliate_validation_message = "";
            this.isDisable = false;
          } else {
            this.isDisable = true;
            this.affiliate_validation_message = check_affiliate_voucher.message;
          }
        }
      }, 1000),
    };
  },
  computed: {
    ...mapState("slotegator", ["show_register"]),
  },
  watch: {
    async show_register() {
      this.signup_dialog = this.show_register;
      console.log("routess", this.$route.query);
      if (this.$route.query.voucher != "") {
        this.voucher_code = this.$route.query.voucher;
        if (this.$route.query.voucher) {
          const check_affiliate_voucher = await this.affiliateVoucherValidation(
            this.$route.query.voucher
          );
          if (check_affiliate_voucher.success) {
            this.affiliate_validation_message = "";
          } else {
            this.isDisable = true;
            this.affiliate_validation_message = check_affiliate_voucher.message;
          }
          console.log("RESPONSERESPONSERESPONSE", check_affiliate_voucher);
        }
      }

      if (this.partnerQuery != "") {
        this.partner_link = this.$route.query.partner;

        this.registered_partner = this.partner_link;
      }
    },
    signup_dialog() {
      this.SET_SHOW_REGISTER(this.signup_dialog);
    },
    async voucher_code(value) {
      await this.debouncedName(value);
    },
  },
  components: {
    Alert,
    // VFacebookLogin
  },
  async mounted() {
    //http://localhost:8080/?voucher=voucher_name
    // console.log("this.partner_linksss", this.partnerQuery);
    if (this.$route.query.voucher != "") {
      this.voucher_code = this.$route.query.voucher;
    }

    if (this.partnerQuery != "") {
      this.partner_link = this.$route.query.partner;

      this.registered_partner = this.partner_link;
    }
    let ip_result = await publicIpv4({
      fallbackUrls: ["https://ifconfig.co/ip"],
    });
    this.ip_address = ip_result;
  },
  methods: {
    ...mapActions("user", [
      "register",
      "fbLogin",
      "getUserIpAddress",
      "checkAffiliateVoucher",
    ]),
    ...mapActions("partner", ["getPartnerData"]),
    ...mapMutations("slotegator", ["SET_SHOW_LOGIN", "SET_SHOW_REGISTER"]),
    login() {
      this.SET_SHOW_REGISTER(false);
      this.SET_SHOW_LOGIN(true);
    },
    async signup() {
      const voucher_code = this.$route.query.voucher
        ? this.$route.query.voucher
        : this.voucher_code;
      let data = {
        name: this.name,
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation,
        voucher_code: voucher_code || null,
        partner_link: this.partner_link ? this.partner_link : null,
        ip_address: this.ip_address,
      };
      console.log("voucher_code", this.voucher_code);
      if (voucher_code) {
        const check_affiliate_voucher = await this.affiliateVoucherValidation(
          voucher_code
        );
        if (check_affiliate_voucher.success) {
          const res = await this.register(data);
          if (res.data.success) {
            this.error = false;
            this.error_message = "";
            this.name = "";
            this.email = "";
            this.password = "";
            (this.password_confirmation = ""),
              (this.$route.query.voucher = ""),
              (this.voucher_code = ""),
              this.$emit("signupSuccess", true);
            this.alert_dialog = true;
            this.alert_success = res.data.success;
            this.alert_message = !res.data.success
              ? res.data.success
              : "Successfully Registered";
          } else {
            this.error = true;
            this.error_message = res.data.message;
          }
          this.affiliate_validation_message = "";
        } else {
          this.affiliate_validation_message = check_affiliate_voucher.message;
        }
      } else {
        const res = await this.register(data);
        if (res.data.success) {
          this.error = false;
          this.error_message = "";
          this.name = "";
          this.email = "";
          this.password = "";
          (this.password_confirmation = ""), this.$emit("signupSuccess", true);
          this.alert_dialog = true;
          this.alert_success = res.data.success;
          this.alert_message = !res.data.success
            ? res.data.success
            : "Successfully Registered";
        } else {
          this.error = true;
          this.error_message = res.data.message;
        }
      }
    },
    closeAlertDialog() {
      this.alert_dialog = false;
    },
    handleClick() {
      this.fbLoading = true;
    },
    handleSdkInit({ FB, scope }) {
      this.FB = FB;
      this.scope = scope;
      // this.facebook.FB.logout = this.facebook.FB.login;
    },
    async handleConnect() {
      this.facebook.auth = {
        ...this.facebook.auth,
        voucher_code: this.voucher_code || null,
        partner_link: this.partner_link || null,
      };
      if (this.facebook.auth.status == "connected") {
        let res = await this.fbLogin(this.facebook.auth);
        if (res.success) {
          this.error = false;
          this.error_message = "";
          this.$emit("signupSuccess", true);
          // this.$router.push('/').catch(()=>{})
          this.handleLogout();
          window.location.href = "/";
        } else {
          this.error = true;
          if (res.message == "User is banned") {
            this.error_message = res.message + " until " + res.bannedUntil;
          } else {
            this.error_message = `Voucher code not found`;
          }
        }
      } else {
        this.error = true;
        this.error_message = "";
      }
    },
    handleLogout: function (payload) {
      this.scope.logout();
      this.facebook.auth = payload;
    },
    loggedInFb(payload) {
      this.facebook.auth = payload;
    },
    async affiliateVoucherValidation(voucher_code) {
      return await this.checkAffiliateVoucher({ voucher_code });
    },
  },
};
</script>

<style>
.fbBtn {
  width: 100%;
  border-radius: 20px !important;
  cursor: pointer !important;
}
</style>
