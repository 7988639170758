var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.$vuetify.breakpoint.mobile)?_c('div',[(_vm.isHeader)?_c('div',{},[(
        _vm.$auth.user &&
        ['admin', 'super-admin'].includes(_vm.$auth.user.type) &&
        _vm.$route.name != 'CasinoAdmin'
      )?_c('draggable',{staticClass:"tw-grid tw-grid-cols-2 tw-gap-4 tw-p-4",attrs:{"ghost-class":"ghost","move":_vm.checkMove},on:{"start":_vm.onDragStart,"end":_vm.onDragEnd},model:{value:(_vm.providerHolder),callback:function ($$v) {_vm.providerHolder=$$v},expression:"providerHolder"}},_vm._l((_vm.providerHolder),function(item,i){return _c('div',{key:i,class:("tw-border tw-border-transparent tw-rounded-lg  tw-min-w-[120px] tw-p-2 tw-px-8 " + (_vm.selectedProvider.includes(item.provider)
            ? 'tw-bg-[#E4682D]'
            : 'tw-bg-[#2C90E3]') + " tw-cursor-pointer"),on:{"click":function($event){return _vm.selectProvider(item.provider)}}},[(item.provider != 'All')?_c('img',{staticClass:"tw-h-[35px] tw-w-[100%] tw-object-contain tw-text-black tw-font-extrabold tw-uppercase",attrs:{"src":("../provider_img_white/" + (item.provider) + ".png"),"alt":item.provider}}):_c('div',{staticClass:"tw-h-[20px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"},[_vm._v(" "+_vm._s(item.provider)+" ")])])}),0):_c('div',{staticClass:"tw-grid tw-grid-cols-2 tw-gap-4 tw-p-4"},_vm._l((_vm.providerHolder),function(item,i){return _c('div',{key:i,class:("tw-border tw-border-transparent tw-rounded-lg  tw-min-w-[120px]  tw-p-2 tw-px-8 " + (_vm.selectedProvider.includes(item.provider)
            ? 'tw-bg-[#E4682D]'
            : 'tw-bg-[#2C90E3]') + " tw-cursor-pointer"),on:{"click":function($event){return _vm.selectProvider(item.provider)}}},[(item.provider != 'All')?_c('img',{staticClass:"tw-h-[35px] tw-w-[100%] tw-object-contain tw-text-black tw-font-extrabold tw-uppercase",attrs:{"src":_vm.$route.name != 'CasinoAdmin'
              ? ("../provider_img_white/" + (item.provider) + ".png")
              : ("../../provider_img_white/" + (item.provider) + ".png"),"alt":item.provider}}):_c('div',{staticClass:"tw-h-[20px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"},[_vm._v(" "+_vm._s(item.provider)+" ")])])}),0)],1):_c('div',{ref:"targetProvider"},[_c('v-card',{class:("tw-rounded-lg tw-p-4 tw-py-4 " + (_vm.isFixed &&
        'tw-fixed tw-z-10 tw-top-0 tw-ml-[-80px] tw-w-[-webkit-fill-available]')),attrs:{"rounded":!_vm.isFixed && 'lg',"color":"#161C2C","elevation":_vm.isFixed && 0}},[_c('div',{class:("tw-flex " + (_vm.provider_exapanded ? 'tw-items-start' : 'tw-items-center') + " tw-gap-2")},[(
            _vm.$auth.user &&
            ['admin', 'super-admin'].includes(_vm.$auth.user.type) &&
            _vm.$route.name != 'CasinoAdmin'
          )?_c('draggable',{staticClass:"tw-flex-auto tw-grid tw-grid-cols-7 tw-items-center tw-gap-6",attrs:{"ghost-class":"ghost","move":_vm.checkMove},on:{"start":_vm.onDragStart,"end":_vm.onDragEnd},model:{value:(_vm.providerHolder),callback:function ($$v) {_vm.providerHolder=$$v},expression:"providerHolder"}},_vm._l((_vm.providerHolder),function(provider,i){return _c('div',{key:i,class:("tw-border tw-border-transparent tw-rounded-lg tw-w-[100%] tw-p-2 tw-px-8 " + (_vm.selectedProvider.includes(provider.provider)
                ? 'tw-bg-[#E4682D]'
                : 'tw-bg-[#2C90E3]') + " tw-cursor-pointer"),on:{"click":function($event){return _vm.selectProvider(provider.provider)}}},[(provider.provider != 'All')?_c('img',{staticClass:"tw-h-[35px] tw-w-[100%] tw-object-scale-down tw-text-black tw-font-extrabold tw-uppercase tw-text-sm",attrs:{"src":("../provider_img_white/" + (provider.provider) + ".png"),"alt":provider.provider}}):_c('div',{staticClass:"tw-h-[35px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"},[_vm._v(" "+_vm._s(provider.provider)+" ")])])}),0):_c('div',{staticClass:"tw-flex-auto tw-grid tw-grid-cols-7 tw-items-center tw-gap-6"},_vm._l((_vm.providerHolder),function(provider,i){return _c('div',{key:i,class:("tw-border tw-border-transparent tw-rounded-lg tw-p-2 tw-w-[100%] tw-px-8 " + (_vm.selectedProvider.includes(provider.provider)
                ? 'tw-bg-[#E4682D]'
                : 'tw-bg-[#2C90E3]') + " tw-cursor-pointer"),on:{"click":function($event){return _vm.selectProvider(provider.provider)}}},[(provider.provider != 'All')?_c('img',{staticClass:"tw-h-[35px] tw-w-[100%] tw-object-contain tw-text-black tw-font-extrabold tw-uppercase",attrs:{"src":_vm.$route.name != 'CasinoAdmin'
                  ? ("../provider_img_white/" + (provider.provider) + ".png")
                  : ("../../provider_img_white/" + (provider.provider) + ".png"),"alt":provider.provider}}):_c('div',{staticClass:"tw-h-[35px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"},[_vm._v(" "+_vm._s(provider.provider)+" ")])])}),0),(_vm.providers.length > 7)?_c('div',{staticClass:"tw-flex-none tw-py-2"},[_c('v-icon',{on:{"click":_vm.expandProviderList}},[_vm._v(_vm._s(_vm.provider_exapanded ? "mdi-arrow-up" : "mdi-arrow-down"))])],1):_vm._e()],1)])],1)]):_c('div',{staticClass:"tw-px-4"},[(_vm.isHeader)?_c('div',{},[(
        _vm.$auth.user &&
        ['admin', 'super-admin'].includes(_vm.$auth.user.type) &&
        _vm.$route.name != 'CasinoAdmin'
      )?_c('draggable',{staticClass:"tw-grid tw-grid-cols-2 tw-gap-4 tw-p-4",attrs:{"ghost-class":"ghost","move":_vm.checkMove},on:{"start":_vm.onDragStart,"end":_vm.onDragEnd},model:{value:(_vm.providerHolder),callback:function ($$v) {_vm.providerHolder=$$v},expression:"providerHolder"}},_vm._l((_vm.providerHolder),function(item,i){return _c('div',{key:i,class:("tw-border tw-border-transparent tw-rounded-lg  tw-min-w-[120px] tw-p-2 tw-px-8 " + (_vm.selectedProvider.includes(item.provider)
            ? 'tw-bg-[#E4682D]'
            : 'tw-bg-[#2C90E3]') + " tw-cursor-pointer"),on:{"click":function($event){return _vm.selectProvider(item.provider)}}},[(item.provider != 'All')?_c('img',{staticClass:"tw-h-[35px] tw-w-[100%] tw-object-contain tw-text-black tw-font-extrabold tw-uppercase",attrs:{"src":("../provider_img_white/" + (item.provider) + ".png"),"alt":item.provider}}):_c('div',{staticClass:"tw-h-[20px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"},[_vm._v(" "+_vm._s(item.provider)+" ")])])}),0):_c('div',{staticClass:"tw-grid tw-grid-cols-2 tw-gap-4 tw-p-4"},_vm._l((_vm.providerHolder),function(item,i){return _c('div',{key:i,class:("tw-border tw-border-transparent tw-rounded-lg  tw-min-w-[120px] tw-p-2 tw-px-8 " + (_vm.selectedProvider.includes(item.provider)
            ? 'tw-bg-[#E4682D]'
            : 'tw-bg-[#2C90E3]') + " tw-cursor-pointer"),on:{"click":function($event){return _vm.selectProvider(item.provider)}}},[(item.provider != 'All')?_c('img',{staticClass:"tw-h-[35px] tw-w-[100%] tw-object-contain tw-text-black tw-font-extrabold tw-uppercase",attrs:{"src":_vm.$route.name != 'CasinoAdmin'
              ? ("../provider_img_white/" + (item.provider) + ".png")
              : ("../../provider_img_white/" + (item.provider) + ".png"),"alt":item.provider}}):_c('div',{staticClass:"tw-h-[20px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"},[_vm._v(" "+_vm._s(item.provider)+" ")])])}),0)],1):_c('div',[_c('div',{},[(
          _vm.$auth.user &&
          ['admin', 'super-admin'].includes(_vm.$auth.user.type) &&
          _vm.$route.name != 'CasinoAdmin'
        )?_c('draggable',{staticClass:"tw-flex tw-gap-4",attrs:{"ghost-class":"ghost","move":_vm.checkMove},on:{"start":_vm.onDragStart,"end":_vm.onDragEnd},model:{value:(_vm.providerHolder),callback:function ($$v) {_vm.providerHolder=$$v},expression:"providerHolder"}},[_vm._l((_vm.providerHolder),function(item,i){return _c('div',{key:i,class:("tw-border tw-border-transparent tw-rounded-lg tw-py-2  tw-w-[100%] tw-flex  tw-items-center tw-justify-center " + (_vm.selectedProvider.includes(item.provider)
              ? 'tw-bg-[#E4682D]'
              : 'tw-bg-[#2C90E3]') + " tw-cursor-pointer "),on:{"click":function($event){return _vm.selectProvider(item.provider)}}},[(item.provider != 'All')?_c('img',{staticClass:"tw-h-[25px] tw-w-[92px] tw-object-contain",attrs:{"src":("../provider_img_white/" + (item.provider) + ".png"),"alt":item.provider}}):_c('div',{staticClass:"tw-h-[20px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"},[_vm._v(" "+_vm._s(item.provider)+" ")])])}),_c('div',{staticClass:"tw-p-2 tw-border tw-border-transparent tw-rounded-lg tw-py-2 tw-max-w-[100%] tw-flex tw-items-center tw-justify-center tw-bg-[#2C90E3]",on:{"click":_vm.expandProviderList}},[_c('span',{staticClass:"tw-block tw-text-center tw-text-sm tw-font-medium"},[_vm._v(" "+_vm._s(_vm.provider_count)+"+"),_c('br'),_vm._v(" MORE ")])])],2):_c('div',{staticClass:"tw-grid tw-grid-cols-4 sm:tw-flex tw-gap-4"},[_vm._l((_vm.providerHolder),function(item,i){return _c('div',{key:i,class:("tw-border tw-border-transparent tw-rounded-lg tw-w-full  tw-py-2 tw-flex-1 tw-items-center tw-justify-center " + (_vm.selectedProvider.includes(item.provider)
              ? 'tw-bg-[#E4682D]'
              : 'tw-bg-[#2C90E3]') + " tw-cursor-pointer  "),on:{"click":function($event){return _vm.selectProvider(item.provider)}}},[(item.provider != 'All')?_c('img',{staticClass:"tw-h-[25px] tw-w-[92px] tw-object-contain",attrs:{"src":_vm.$route.name != 'CasinoAdmin'
                ? ("../provider_img_white/" + (item.provider) + ".png")
                : ("../../provider_img_white/" + (item.provider) + ".png"),"alt":item.provider}}):_c('div',{staticClass:"tw-h-[20px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"},[_vm._v(" "+_vm._s(item.provider)+" ")])])}),_c('div',{staticClass:"tw-p-2 tw-border tw-border-transparent tw-rounded-lg tw-py-2 tw-max-w-[100%] tw-flex tw-items-center tw-justify-center tw-bg-[#2C90E3] tw-shrink",on:{"click":_vm.expandProviderList}},[_c('span',{staticClass:"tw-block tw-text-center tw-text-xs tw-font-medium"},[_vm._v(" "+_vm._s(_vm.provider_count)+"+"),_c('br'),_vm._v(" MORE ")])])],2)],1),_c('v-dialog',{model:{value:(_vm.isOpenProviderModal),callback:function ($$v) {_vm.isOpenProviderModal=$$v},expression:"isOpenProviderModal"}},[_c('div',{staticClass:"tw-bg-[#0F1525] tw-border tw-border-[#FFFFFF] tw-rounded-xl"},[_c('div',{staticClass:"tw-flex tw-justify-end"},[_c('v-icon',{staticClass:"tw-pt-4 tw-px-4",attrs:{"color":"red"},on:{"click":function($event){_vm.isOpenProviderModal = false}}},[_vm._v(" mdi-close")])],1),_c('div',{staticClass:"tw-px-8 tw-py-4"},[(
              _vm.$auth.user &&
              ['admin', 'super-admin'].includes(_vm.$auth.user.type) &&
              _vm.$route.name != 'CasinoAdmin'
            )?_c('draggable',{staticClass:"tw-grid tw-grid-cols-2 tw-gap-4 tw-p-4",attrs:{"ghost-class":"ghost","move":_vm.checkMove},on:{"start":_vm.onDragStart,"end":_vm.onDragEnd},model:{value:(_vm.providerHolder),callback:function ($$v) {_vm.providerHolder=$$v},expression:"providerHolder"}},_vm._l((_vm.providerHolder),function(item,i){return _c('div',{key:i,class:("tw-border tw-border-transparent tw-rounded-lg  tw-min-w-[120px] tw-p-2 tw-px-8 " + (_vm.selectedProvider.includes(item.provider)
                  ? 'tw-bg-[#E4682D]'
                  : 'tw-bg-[#2C90E3]') + " tw-cursor-pointer"),on:{"click":function($event){return _vm.selectProvider(item.provider)}}},[(item.provider != 'All')?_c('img',{staticClass:"tw-h-[35px] tw-w-[100%] tw-object-contain tw-text-black tw-font-extrabold tw-uppercase",attrs:{"src":_vm.$route.name != 'CasinoAdmin'
                    ? ("../provider_img_white/" + (item.provider) + ".png")
                    : ("../../provider_img_white/" + (item.provider) + ".png"),"alt":item.provider}}):_c('div',{staticClass:"tw-h-[20px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"},[_vm._v(" "+_vm._s(item.provider)+" ")])])}),0):_c('div',{staticClass:"tw-grid tw-grid-cols-2 tw-gap-4 tw-p-4"},_vm._l((_vm.providerHolder),function(item,i){return _c('div',{key:i,class:("tw-border tw-border-transparent tw-rounded-lg  tw-min-w-[120px]  tw-p-2 tw-px-8 " + (_vm.selectedProvider.includes(item.provider)
                  ? 'tw-bg-[#E4682D]'
                  : 'tw-bg-[#2C90E3]') + " tw-cursor-pointer"),on:{"click":function($event){return _vm.selectProvider(item.provider)}}},[(item.provider != 'All')?_c('img',{staticClass:"tw-h-[35px] tw-w-[100%] tw-object-contain tw-text-black tw-font-extrabold tw-uppercase",attrs:{"src":_vm.$route.name != 'CasinoAdmin'
                    ? ("../provider_img_white/" + (item.provider) + ".png")
                    : ("../../provider_img_white/" + (item.provider) + ".png"),"alt":item.provider}}):_c('div',{staticClass:"tw-h-[20px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"},[_vm._v(" "+_vm._s(item.provider)+" ")])])}),0)],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }