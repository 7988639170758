<template>
  <v-dialog
    v-model="alertDialog"
    @click:outside="closeAlertDialog"
    @keydown.esc="closeAlertDialog"
    overlay-color="#000000"
    overlay-opacity="0.5"
    width="600"
  >
    <v-card class="alert-card pa-10">
      <!-- <v-card-title class="text-h4 d-flex justify-center">{{
        !alert_success ? "FAILED" : "SUCCESS"
      }}</v-card-title>
      <v-card-text> {{ alert_message }} </v-card-text> -->

      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-img max-height="75" max-width="75" :src="image"></v-img>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <v-card-title
            style="word-break: break-word; width: 90%"
            class="message-header justify-center"
          >
            {{ alert_message }}
          </v-card-title>
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-center"
          v-if="alert_contact_admin"
        >
          <div class="tw-underline tw-text-lg tw-font-bold tw-py-5">
            <a href="https://www.facebook.com/betgamegg" style="color: inherit"
              >PLEASE CONTACT THE ADMIN</a
            >
          </div>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="dialog-btn"
              color="warning"
              rounded
              @click="closeAlertDialog"
            >
              {{ btn_message }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import Success from "@/assets/images/alert/success.svg";
import Failed from "@/assets/images/alert/failed.svg";
export default {
  props: [
    "alert_dialog",
    "alert_success",
    "alert_message",
    "alert_contact_admin",
  ],
  data() {
    return {
      image: "",
      btn_message: "",
    };
  },
  computed: {
    alertDialog() {
      this.image = !this.alert_success ? Failed : Success;
      this.btn_message = this.alert_success ? "DONE" : "OK";

      return this.alert_dialog;
    },
  },
  methods: {
    closeAlertDialog() {
      this.$emit("closeAlertDialog", true);
    },
  },
};
</script>
<style>
.alert-card {
  border-radius: 25px !important;
  background-color: #0d111b !important;
}
.message-body {
  font-style: normal;
  font-weight: 750;
  font-size: 18px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;
  text-decoration-line: underline;
}
.dialog-btn {
  width: 200px;
  height: 36px;
}
</style>
