<template>
  <div v-if="!$vuetify.breakpoint.mobile">
    <div class="" v-if="isHeader">
      <draggable
        v-if="
          $auth.user &&
          ['admin', 'super-admin'].includes($auth.user.type) &&
          $route.name != 'CasinoAdmin'
        "
        v-model="providerHolder"
        ghost-class="ghost"
        :move="checkMove"
        @start="onDragStart"
        @end="onDragEnd"
        class="tw-grid tw-grid-cols-2 tw-gap-4 tw-p-4"
      >
        <div
          v-for="(item, i) in providerHolder"
          :key="i"
          @click="selectProvider(item.provider)"
          :class="`tw-border tw-border-transparent tw-rounded-lg  tw-min-w-[120px] tw-p-2 tw-px-8 ${
            selectedProvider.includes(item.provider)
              ? 'tw-bg-[#E4682D]'
              : 'tw-bg-[#2C90E3]'
          } tw-cursor-pointer`"
        >
          <img
            v-if="item.provider != 'All'"
            class="tw-h-[35px] tw-w-[100%] tw-object-contain tw-text-black tw-font-extrabold tw-uppercase"
            :src="`../provider_img_white/${item.provider}.png`"
            :alt="item.provider"
          />
          <div
            v-else
            class="tw-h-[20px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"
          >
            {{ item.provider }}
          </div>
        </div>
      </draggable>
      <div class="tw-grid tw-grid-cols-2 tw-gap-4 tw-p-4" v-else>
        <div
          v-for="(item, i) in providerHolder"
          :key="i"
          @click="selectProvider(item.provider)"
          :class="`tw-border tw-border-transparent tw-rounded-lg  tw-min-w-[120px]  tw-p-2 tw-px-8 ${
            selectedProvider.includes(item.provider)
              ? 'tw-bg-[#E4682D]'
              : 'tw-bg-[#2C90E3]'
          } tw-cursor-pointer`"
        >
          <img
            v-if="item.provider != 'All'"
            class="tw-h-[35px] tw-w-[100%] tw-object-contain tw-text-black tw-font-extrabold tw-uppercase"
            :src="
              $route.name != 'CasinoAdmin'
                ? `../provider_img_white/${item.provider}.png`
                : `../../provider_img_white/${item.provider}.png`
            "
            :alt="item.provider"
          />
          <div
            v-else
            class="tw-h-[20px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"
          >
            {{ item.provider }}
          </div>
        </div>
      </div>
    </div>
    <div ref="targetProvider" v-else>
      <v-card
        :rounded="!isFixed && 'lg'"
        :class="`tw-rounded-lg tw-p-4 tw-py-4 ${
          isFixed &&
          'tw-fixed tw-z-10 tw-top-0 tw-ml-[-80px] tw-w-[-webkit-fill-available]'
        }`"
        color="#161C2C"
        :elevation="isFixed && 0"
      >
        <div
          :class="`tw-flex ${
            provider_exapanded ? 'tw-items-start' : 'tw-items-center'
          } tw-gap-2`"
        >
          <draggable
            v-if="
              $auth.user &&
              ['admin', 'super-admin'].includes($auth.user.type) &&
              $route.name != 'CasinoAdmin'
            "
            v-model="providerHolder"
            ghost-class="ghost"
            :move="checkMove"
            @start="onDragStart"
            @end="onDragEnd"
            class="tw-flex-auto tw-grid tw-grid-cols-7 tw-items-center tw-gap-6"
          >
            <div
              v-for="(provider, i) in providerHolder"
              :key="i"
              @click="selectProvider(provider.provider)"
              :class="`tw-border tw-border-transparent tw-rounded-lg tw-w-[100%] tw-p-2 tw-px-8 ${
                selectedProvider.includes(provider.provider)
                  ? 'tw-bg-[#E4682D]'
                  : 'tw-bg-[#2C90E3]'
              } tw-cursor-pointer`"
            >
              <!-- <v-img
           :src="`../provider_img_white/${provider.provider}.png`"
           max-width="150"
           max-height="150"
         ></v-img> -->
              <img
                v-if="provider.provider != 'All'"
                class="tw-h-[35px] tw-w-[100%] tw-object-scale-down tw-text-black tw-font-extrabold tw-uppercase tw-text-sm"
                :src="`../provider_img_white/${provider.provider}.png`"
                :alt="provider.provider"
              />
              <div
                v-else
                class="tw-h-[35px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"
              >
                {{ provider.provider }}
              </div>
              <!-- <div v-else class="tw-text-black tw-font-extrabold tw-uppercase">
         {{ provider.provider }}
       </div> -->
            </div>
          </draggable>
          <div
            v-else
            class="tw-flex-auto tw-grid tw-grid-cols-7 tw-items-center tw-gap-6"
          >
            <div
              v-for="(provider, i) in providerHolder"
              :key="i"
              @click="selectProvider(provider.provider)"
              :class="`tw-border tw-border-transparent tw-rounded-lg tw-p-2 tw-w-[100%] tw-px-8 ${
                selectedProvider.includes(provider.provider)
                  ? 'tw-bg-[#E4682D]'
                  : 'tw-bg-[#2C90E3]'
              } tw-cursor-pointer`"
            >
              <!-- <v-img
           :src="`../provider_img_white/${provider.provider}.png`"
           max-width="150"
           max-height="150"
         ></v-img> -->
              <img
                v-if="provider.provider != 'All'"
                class="tw-h-[35px] tw-w-[100%] tw-object-contain tw-text-black tw-font-extrabold tw-uppercase"
                :src="
                  $route.name != 'CasinoAdmin'
                    ? `../provider_img_white/${provider.provider}.png`
                    : `../../provider_img_white/${provider.provider}.png`
                "
                :alt="provider.provider"
              />
              <div
                v-else
                class="tw-h-[35px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"
              >
                {{ provider.provider }}
              </div>
              <!-- <div v-else class="tw-text-black tw-font-extrabold tw-uppercase">
         {{ provider.provider }}
       </div> -->
            </div>
          </div>
          <div class="tw-flex-none tw-py-2" v-if="providers.length > 7">
            <v-icon @click="expandProviderList">{{
              provider_exapanded ? "mdi-arrow-up" : "mdi-arrow-down"
            }}</v-icon>
          </div>
        </div>
      </v-card>
    </div>
  </div>

  <div class="tw-px-4" v-else>
    <div class="" v-if="isHeader">
      <draggable
        v-if="
          $auth.user &&
          ['admin', 'super-admin'].includes($auth.user.type) &&
          $route.name != 'CasinoAdmin'
        "
        v-model="providerHolder"
        ghost-class="ghost"
        :move="checkMove"
        @start="onDragStart"
        @end="onDragEnd"
        class="tw-grid tw-grid-cols-2 tw-gap-4 tw-p-4"
      >
        <div
          v-for="(item, i) in providerHolder"
          :key="i"
          @click="selectProvider(item.provider)"
          :class="`tw-border tw-border-transparent tw-rounded-lg  tw-min-w-[120px] tw-p-2 tw-px-8 ${
            selectedProvider.includes(item.provider)
              ? 'tw-bg-[#E4682D]'
              : 'tw-bg-[#2C90E3]'
          } tw-cursor-pointer`"
        >
          <img
            v-if="item.provider != 'All'"
            class="tw-h-[35px] tw-w-[100%] tw-object-contain tw-text-black tw-font-extrabold tw-uppercase"
            :src="`../provider_img_white/${item.provider}.png`"
            :alt="item.provider"
          />
          <div
            v-else
            class="tw-h-[20px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"
          >
            {{ item.provider }}
          </div>
        </div>
      </draggable>
      <div class="tw-grid tw-grid-cols-2 tw-gap-4 tw-p-4" v-else>
        <div
          v-for="(item, i) in providerHolder"
          :key="i"
          @click="selectProvider(item.provider)"
          :class="`tw-border tw-border-transparent tw-rounded-lg  tw-min-w-[120px] tw-p-2 tw-px-8 ${
            selectedProvider.includes(item.provider)
              ? 'tw-bg-[#E4682D]'
              : 'tw-bg-[#2C90E3]'
          } tw-cursor-pointer`"
        >
          <img
            v-if="item.provider != 'All'"
            class="tw-h-[35px] tw-w-[100%] tw-object-contain tw-text-black tw-font-extrabold tw-uppercase"
            :src="
              $route.name != 'CasinoAdmin'
                ? `../provider_img_white/${item.provider}.png`
                : `../../provider_img_white/${item.provider}.png`
            "
            :alt="item.provider"
          />
          <div
            v-else
            class="tw-h-[20px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"
          >
            {{ item.provider }}
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="">
        <draggable
          v-if="
            $auth.user &&
            ['admin', 'super-admin'].includes($auth.user.type) &&
            $route.name != 'CasinoAdmin'
          "
          v-model="providerHolder"
          ghost-class="ghost"
          :move="checkMove"
          @start="onDragStart"
          @end="onDragEnd"
          class="tw-flex tw-gap-4"
        >
          <div
            v-for="(item, i) in providerHolder"
            :key="i"
            @click="selectProvider(item.provider)"
            :class="`tw-border tw-border-transparent tw-rounded-lg tw-py-2  tw-w-[100%] tw-flex  tw-items-center tw-justify-center ${
              selectedProvider.includes(item.provider)
                ? 'tw-bg-[#E4682D]'
                : 'tw-bg-[#2C90E3]'
            } tw-cursor-pointer `"
          >
            <img
              v-if="item.provider != 'All'"
              class="tw-h-[25px] tw-w-[92px] tw-object-contain"
              :src="`../provider_img_white/${item.provider}.png`"
              :alt="item.provider"
            />
            <div
              v-else
              class="tw-h-[20px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"
            >
              {{ item.provider }}
            </div>
          </div>
          <div
            class="tw-p-2 tw-border tw-border-transparent tw-rounded-lg tw-py-2 tw-max-w-[100%] tw-flex tw-items-center tw-justify-center tw-bg-[#2C90E3]"
            @click="expandProviderList"
          >
            <span class="tw-block tw-text-center tw-text-sm tw-font-medium">
              {{ provider_count }}+<br />
              MORE
            </span>
          </div>
        </draggable>
        <div class="tw-grid tw-grid-cols-4 sm:tw-flex tw-gap-4" v-else>
          <div
            v-for="(item, i) in providerHolder"
            :key="i"
            @click="selectProvider(item.provider)"
            :class="`tw-border tw-border-transparent tw-rounded-lg tw-w-full  tw-py-2 tw-flex-1 tw-items-center tw-justify-center ${
              selectedProvider.includes(item.provider)
                ? 'tw-bg-[#E4682D]'
                : 'tw-bg-[#2C90E3]'
            } tw-cursor-pointer  `"
          >
            <img
              v-if="item.provider != 'All'"
              class="tw-h-[25px] tw-w-[92px] tw-object-contain"
              :src="
                $route.name != 'CasinoAdmin'
                  ? `../provider_img_white/${item.provider}.png`
                  : `../../provider_img_white/${item.provider}.png`
              "
              :alt="item.provider"
            />
            <div
              v-else
              class="tw-h-[20px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"
            >
              {{ item.provider }}
            </div>
          </div>
          <div
            class="tw-p-2 tw-border tw-border-transparent tw-rounded-lg tw-py-2 tw-max-w-[100%] tw-flex tw-items-center tw-justify-center tw-bg-[#2C90E3] tw-shrink"
            @click="expandProviderList"
          >
            <span class="tw-block tw-text-center tw-text-xs tw-font-medium">
              {{ provider_count }}+<br />
              MORE
            </span>
          </div>
        </div>
      </div>
      <v-dialog v-model="isOpenProviderModal">
        <div
          class="tw-bg-[#0F1525] tw-border tw-border-[#FFFFFF] tw-rounded-xl"
        >
          <div class="tw-flex tw-justify-end">
            <v-icon
              class="tw-pt-4 tw-px-4"
              @click="isOpenProviderModal = false"
              color="red"
            >
              mdi-close</v-icon
            >
          </div>

          <div class="tw-px-8 tw-py-4">
            <draggable
              v-if="
                $auth.user &&
                ['admin', 'super-admin'].includes($auth.user.type) &&
                $route.name != 'CasinoAdmin'
              "
              v-model="providerHolder"
              ghost-class="ghost"
              :move="checkMove"
              @start="onDragStart"
              @end="onDragEnd"
              class="tw-grid tw-grid-cols-2 tw-gap-4 tw-p-4"
            >
              <div
                v-for="(item, i) in providerHolder"
                :key="i"
                @click="selectProvider(item.provider)"
                :class="`tw-border tw-border-transparent tw-rounded-lg  tw-min-w-[120px] tw-p-2 tw-px-8 ${
                  selectedProvider.includes(item.provider)
                    ? 'tw-bg-[#E4682D]'
                    : 'tw-bg-[#2C90E3]'
                } tw-cursor-pointer`"
              >
                <img
                  v-if="item.provider != 'All'"
                  class="tw-h-[35px] tw-w-[100%] tw-object-contain tw-text-black tw-font-extrabold tw-uppercase"
                  :src="
                    $route.name != 'CasinoAdmin'
                      ? `../provider_img_white/${item.provider}.png`
                      : `../../provider_img_white/${item.provider}.png`
                  "
                  :alt="item.provider"
                />
                <div
                  v-else
                  class="tw-h-[20px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"
                >
                  {{ item.provider }}
                </div>
              </div>
            </draggable>
            <div class="tw-grid tw-grid-cols-2 tw-gap-4 tw-p-4" v-else>
              <div
                v-for="(item, i) in providerHolder"
                :key="i"
                @click="selectProvider(item.provider)"
                :class="`tw-border tw-border-transparent tw-rounded-lg  tw-min-w-[120px]  tw-p-2 tw-px-8 ${
                  selectedProvider.includes(item.provider)
                    ? 'tw-bg-[#E4682D]'
                    : 'tw-bg-[#2C90E3]'
                } tw-cursor-pointer`"
              >
                <img
                  v-if="item.provider != 'All'"
                  class="tw-h-[35px] tw-w-[100%] tw-object-contain tw-text-black tw-font-extrabold tw-uppercase"
                  :src="
                    $route.name != 'CasinoAdmin'
                      ? `../provider_img_white/${item.provider}.png`
                      : `../../provider_img_white/${item.provider}.png`
                  "
                  :alt="item.provider"
                />
                <div
                  v-else
                  class="tw-h-[20px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"
                >
                  {{ item.provider }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { EventBus } from "@/plugins/eventBus";

export default {
  props: ["providers", "type", "isHeader"],
  data: () => ({
    selectedProvider: [],
    providerHolder: [],
    provider_exapanded: false,
    dragging: false,
    provider_count: 0,
    isOpenProviderModal: false,
    isFixed: false,
  }),
  components: {
    draggable,
  },
  watch: {
    providers() {
      if (this.$vuetify.breakpoint.mobile) {
        if (this.isHeader) {
          this.providerHolder = this.providers;
        } else {
          this.provider_count = this.providers.length - 3;

          this.providerHolder = this.providers.slice(0, 3);
        }
      } else {
        if (this.isHeader) {
          this.providerHolder = this.providers;
        } else {
          this.providerHolder = this.provider_exapanded
            ? this.providers
            : this.providers.slice(0, 7);
        }
      }
    },
    selectedProvider() {
      EventBus.$emit("selectedProvider", this.selectedProvider);
    },
    isHeader(value) {
      this.providerHolder = this.providers;
    },
    isOpenProviderModal(value) {
      this.providerHolder = value ? this.providers : this.providers.slice(0, 3);
    },
  },
  mounted() {
    if (this.$vuetify.breakpoint.mobile) {
      if (this.isHeader) {
        this.providerHolder = this.providers;
      } else {
        this.provider_count = this.providers.length - 3;
        this.providerHolder = this.providers.slice(0, 3);
      }
    } else {
      if (this.isHeader) {
        this.providerHolder = this.providers;
      } else {
        this.providerHolder = this.provider_exapanded
          ? this.providers
          : this.providers.slice(0, 7);
      }
    }
    this.selectedProvider = [];

    window.addEventListener("scroll", this.checkVisibility);
    this.$nextTick(() => {
      this.checkVisibility(); // Initial check
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checkVisibility);
  },
  methods: {
    // async providerMove(index, newIndex) {
    //   let temp = this.providerHolder[index];
    //   this.providerHolder.splice(index, 1);
    //   this.providerHolder.splice(newIndex, 0, temp);

    //   this.$gameApi.post("/admin/casino/provider/order", {
    //     providers: this.providerHolder.map((e) => e.provider),
    //     game_type: this.type,
    //     newIndex,
    //     oldIndex: index,
    //   });
    // },
    expandProviderList() {
      this.provider_exapanded = !this.provider_exapanded;
      if (this.$vuetify.breakpoint.mobile) {
        if (this.isHeader) {
          this.providerHolder = this.providers;
        } else {
          this.isOpenProviderModal = true;

          this.provider_count = this.providers.length - 3;

          this.providerHolder = this.isOpenProviderModal
            ? this.providers
            : this.providers.slice(0, 3);
        }
      } else {
        if (this.isHeader) {
          this.providerHolder = this.providers;
        } else {
          this.providerHolder = this.provider_exapanded
            ? this.providers
            : this.providers.slice(0, 7);
        }
      }
    },
    selectProvider(item) {
      if (this.selectedProvider.includes(item)) {
        const index = this.selectedProvider.indexOf(item);
        if (index > -1) {
          this.selectedProvider.splice(index, 1);
        }
      } else {
        this.selectedProvider.push(item);
      }
      // this.selectedProvider = item;
    },
    onDragStart: function (evt) {
      console.log("start: ", evt);
    },
    onDragEnd: function (evt) {
      console.log("temp: ", evt);

      // this.providerHolder.splice(evt.oldIndex, 1);
      // this.providerHolder.splice(evt.newIndex, 0, temp);
      this.$gameApi.post("/admin/casino/provider/order", {
        providers: this.providerHolder.map((e) => e.provider),
        game_type: this.type,
        newIndex: evt.newIndex + 1,
        oldIndex: evt.oldIndex + 1,
      });
    },

    checkMove: function (evt) {
      console.log("env", evt.draggedContext.element);
      return (
        evt.draggedContext.element !== "All" || !evt.draggedContext.element
      ); // Prevent dragging "All"
    },
    checkVisibility() {
      const element = this.$refs.targetProvider;

      if (!element || typeof element.getBoundingClientRect !== "function")
        return;

      const rect = element.getBoundingClientRect();
      console.log(rect);

      this.isFixed = rect.top < 0 || rect.top > window.innerHeight;
    },
  },
  created() {
    EventBus.$on("selectedProvider", (provider) => {
      this.selectedProvider = provider;
    });
  },
};
</script>
<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
